/* Animations */
/* Chrome, Safari, Opera */
@import url("//fonts.googleapis.com/css?family=Crimson+Text:400,600,700|Roboto:400,500,700");
@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Standard syntax */
@keyframes fade-in {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Chrome, Safari, Opera */
@-webkit-keyframes fade-in-moveup {
  0% {
    opacity: 0;
    margin-top: 30px; }
  50% {
    opacity: 0;
    margin-top: 30px; }
  100% {
    opacity: 1;
    margin-top: 0px; } }

/* Standard syntax */
@keyframes fade-in-moveup {
  0% {
    opacity: 0;
    margin-top: 30px; }
  50% {
    opacity: 0;
    margin-top: 30px; }
  100% {
    opacity: 1;
    margin-top: 0px; } }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #276069;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #122d31;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .templemans-content-block_h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3, .templemans-content-block_h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline, .portal-footer .social-media ul, .credit #menu-footer-menu, .site-footer .social-media ul {
  padding-left: 0;
  list-style: none; }

.list-inline-item, .portal-footer .social-media li, .credit #menu-footer-menu li, .site-footer .social-media li {
  display: inline-block; }
  .list-inline-item:not(:last-child), .portal-footer .social-media li:not(:last-child), .credit #menu-footer-menu li:not(:last-child), .site-footer .social-media li:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid, header img, .content-container img {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 25px;
  padding-left: 25px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-ssm, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-ssm, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-ssm, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-ssm, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -25px;
  margin-left: -25px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .slider-menu-bar .circa-slide-overlay .menu-wrapper, .col,
.col-auto, .col-ssm-1, .col-ssm-2, .col-ssm-3, .col-ssm-4, .col-ssm-5, .col-ssm-6, .col-ssm-7, .col-ssm-8, .col-ssm-9, .col-ssm-10, .col-ssm-11, .col-ssm-12, .col-ssm,
.col-ssm-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto, .col-xxxl-1, .col-xxxl-2, .col-xxxl-3, .col-xxxl-4, .col-xxxl-5, .col-xxxl-6, .col-xxxl-7, .col-xxxl-8, .col-xxxl-9, .col-xxxl-10, .col-xxxl-11, .col-xxxl-12, .col-xxxl,
.col-xxxl-auto, .col-xxxxl-1, .col-xxxxl-2, .col-xxxxl-3, .col-xxxxl-4, .col-xxxxl-5, .col-xxxxl-6, .col-xxxxl-7, .col-xxxxl-8, .col-xxxxl-9, .col-xxxxl-10, .col-xxxxl-11, .col-xxxxl-12, .col-xxxxl,
.col-xxxxl-auto {
  position: relative;
  width: 100%;
  padding-right: 25px;
  padding-left: 25px; }

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12, .slider-menu-bar .circa-slide-overlay .menu-wrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1; }

.order-last {
  -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
          order: 13; }

.order-0 {
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
          order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
          order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 359px) {
  .col-ssm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-ssm-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-ssm-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-ssm-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-ssm-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-ssm-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-ssm-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-ssm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-ssm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-ssm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-ssm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-ssm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-ssm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-ssm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-ssm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-ssm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-ssm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-ssm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-ssm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-ssm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-ssm-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-ssm-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-ssm-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-ssm-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-ssm-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-ssm-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-ssm-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-ssm-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-ssm-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-ssm-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-ssm-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-ssm-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-ssm-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-ssm-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-ssm-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-ssm-0 {
    margin-left: 0; }
  .offset-ssm-1 {
    margin-left: 8.33333%; }
  .offset-ssm-2 {
    margin-left: 16.66667%; }
  .offset-ssm-3 {
    margin-left: 25%; }
  .offset-ssm-4 {
    margin-left: 33.33333%; }
  .offset-ssm-5 {
    margin-left: 41.66667%; }
  .offset-ssm-6 {
    margin-left: 50%; }
  .offset-ssm-7 {
    margin-left: 58.33333%; }
  .offset-ssm-8 {
    margin-left: 66.66667%; }
  .offset-ssm-9 {
    margin-left: 75%; }
  .offset-ssm-10 {
    margin-left: 83.33333%; }
  .offset-ssm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1400px) {
  .col-xxl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-xxl-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-xxl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-xxl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-xxl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-xxl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-xxl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-xxl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-xxl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-xxl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-xxl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-xxl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-xxl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-xxl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-xxl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1600px) {
  .col-xxxl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxxl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxxl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxxl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxxl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxxl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxxl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxxl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxxl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxxl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxxl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xxxl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxxl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxxl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xxxl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxxl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxxl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xxxl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxxl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxxl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xxxl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-xxxl-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-xxxl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-xxxl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-xxxl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-xxxl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-xxxl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-xxxl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-xxxl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-xxxl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-xxxl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-xxxl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-xxxl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-xxxl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-xxxl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-xxxl-0 {
    margin-left: 0; }
  .offset-xxxl-1 {
    margin-left: 8.33333%; }
  .offset-xxxl-2 {
    margin-left: 16.66667%; }
  .offset-xxxl-3 {
    margin-left: 25%; }
  .offset-xxxl-4 {
    margin-left: 33.33333%; }
  .offset-xxxl-5 {
    margin-left: 41.66667%; }
  .offset-xxxl-6 {
    margin-left: 50%; }
  .offset-xxxl-7 {
    margin-left: 58.33333%; }
  .offset-xxxl-8 {
    margin-left: 66.66667%; }
  .offset-xxxl-9 {
    margin-left: 75%; }
  .offset-xxxl-10 {
    margin-left: 83.33333%; }
  .offset-xxxl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 2500px) {
  .col-xxxxl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxxxl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxxxl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxxxl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxxxl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxxxl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxxxl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxxxl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxxxl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxxxl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxxxl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xxxxl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxxxl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxxxl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xxxxl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxxxl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxxxl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xxxxl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxxxl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxxxl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xxxxl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-xxxxl-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-xxxxl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-xxxxl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-xxxxl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-xxxxl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-xxxxl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-xxxxl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-xxxxl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-xxxxl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-xxxxl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-xxxxl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-xxxxl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-xxxxl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-xxxxl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-xxxxl-0 {
    margin-left: 0; }
  .offset-xxxxl-1 {
    margin-left: 8.33333%; }
  .offset-xxxxl-2 {
    margin-left: 16.66667%; }
  .offset-xxxxl-3 {
    margin-left: 25%; }
  .offset-xxxxl-4 {
    margin-left: 33.33333%; }
  .offset-xxxxl-5 {
    margin-left: 41.66667%; }
  .offset-xxxxl-6 {
    margin-left: 50%; }
  .offset-xxxxl-7 {
    margin-left: 58.33333%; }
  .offset-xxxxl-8 {
    margin-left: 66.66667%; }
  .offset-xxxxl-9 {
    margin-left: 75%; }
  .offset-xxxxl-10 {
    margin-left: 83.33333%; }
  .offset-xxxxl-11 {
    margin-left: 91.66667%; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #276069 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #193e44 !important; }

.bg-secondary {
  background-color: #347780 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #25555c !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-templemans {
  background-color: #6d88a3 !important; }

a.bg-templemans:hover, a.bg-templemans:focus,
button.bg-templemans:hover,
button.bg-templemans:focus {
  background-color: #556f88 !important; }

.bg-templemans_secondary {
  background-color: #ebebeb !important; }

a.bg-templemans_secondary:hover, a.bg-templemans_secondary:focus,
button.bg-templemans_secondary:hover,
button.bg-templemans_secondary:focus {
  background-color: #d2d2d2 !important; }

.bg-templemans_text {
  background-color: #1f364e !important; }

a.bg-templemans_text:hover, a.bg-templemans_text:focus,
button.bg-templemans_text:hover,
button.bg-templemans_text:focus {
  background-color: #101d2a !important; }

.bg-cafe-circa {
  background-color: #63a793 !important; }

a.bg-cafe-circa:hover, a.bg-cafe-circa:focus,
button.bg-cafe-circa:hover,
button.bg-cafe-circa:focus {
  background-color: #4e8978 !important; }

.bg-grey {
  background-color: #373f49 !important; }

a.bg-grey:hover, a.bg-grey:focus,
button.bg-grey:hover,
button.bg-grey:focus {
  background-color: #21262c !important; }

.bg-grey-secondary {
  background-color: #595959 !important; }

a.bg-grey-secondary:hover, a.bg-grey-secondary:focus,
button.bg-grey-secondary:hover,
button.bg-grey-secondary:focus {
  background-color: #404040 !important; }

.bg-grey-links {
  background-color: #cccccc !important; }

a.bg-grey-links:hover, a.bg-grey-links:focus,
button.bg-grey-links:hover,
button.bg-grey-links:focus {
  background-color: #b3b3b3 !important; }

.bg-background-primary {
  background-color: #414141 !important; }

a.bg-background-primary:hover, a.bg-background-primary:focus,
button.bg-background-primary:hover,
button.bg-background-primary:focus {
  background-color: #282828 !important; }

.bg-background-secondary {
  background-color: #fffcf2 !important; }

a.bg-background-secondary:hover, a.bg-background-secondary:focus,
button.bg-background-secondary:hover,
button.bg-background-secondary:focus {
  background-color: #fff0bf !important; }

.bg-saac-comp {
  background-color: #be6c13 !important; }

a.bg-saac-comp:hover, a.bg-saac-comp:focus,
button.bg-saac-comp:hover,
button.bg-saac-comp:focus {
  background-color: #90520e !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #276069 !important; }

.border-secondary {
  border-color: #347780 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-templemans {
  border-color: #6d88a3 !important; }

.border-templemans_secondary {
  border-color: #ebebeb !important; }

.border-templemans_text {
  border-color: #1f364e !important; }

.border-cafe-circa {
  border-color: #63a793 !important; }

.border-grey {
  border-color: #373f49 !important; }

.border-grey-secondary {
  border-color: #595959 !important; }

.border-grey-links {
  border-color: #cccccc !important; }

.border-background-primary {
  border-color: #414141 !important; }

.border-background-secondary {
  border-color: #fffcf2 !important; }

.border-saac-comp {
  border-color: #be6c13 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 359px) {
  .d-ssm-none {
    display: none !important; }
  .d-ssm-inline {
    display: inline !important; }
  .d-ssm-inline-block {
    display: inline-block !important; }
  .d-ssm-block {
    display: block !important; }
  .d-ssm-table {
    display: table !important; }
  .d-ssm-table-row {
    display: table-row !important; }
  .d-ssm-table-cell {
    display: table-cell !important; }
  .d-ssm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-ssm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1600px) {
  .d-xxxl-none {
    display: none !important; }
  .d-xxxl-inline {
    display: inline !important; }
  .d-xxxl-inline-block {
    display: inline-block !important; }
  .d-xxxl-block {
    display: block !important; }
  .d-xxxl-table {
    display: table !important; }
  .d-xxxl-table-row {
    display: table-row !important; }
  .d-xxxl-table-cell {
    display: table-cell !important; }
  .d-xxxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xxxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 2500px) {
  .d-xxxxl-none {
    display: none !important; }
  .d-xxxxl-inline {
    display: inline !important; }
  .d-xxxxl-inline-block {
    display: inline-block !important; }
  .d-xxxxl-block {
    display: block !important; }
  .d-xxxxl-table {
    display: table !important; }
  .d-xxxxl-table-row {
    display: table-row !important; }
  .d-xxxxl-table-cell {
    display: table-cell !important; }
  .d-xxxxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xxxxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important; }

.align-items-baseline {
  -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important; }

@media (min-width: 359px) {
  .flex-ssm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-ssm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-ssm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-ssm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-ssm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-ssm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-ssm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-ssm-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-ssm-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-ssm-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-ssm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-ssm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-ssm-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-ssm-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-ssm-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-ssm-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-ssm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-ssm-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-ssm-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-ssm-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-ssm-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-ssm-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-ssm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-ssm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-ssm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-ssm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-ssm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-ssm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-ssm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-ssm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-ssm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-ssm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-ssm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-ssm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 1400px) {
  .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-xxl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-xxl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-xxl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-xxl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-xxl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-xxl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-xxl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-xxl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 1600px) {
  .flex-xxxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xxxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xxxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xxxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xxxl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xxxl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xxxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-xxxl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xxxl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-xxxl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-xxxl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-xxxl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-xxxl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-xxxl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-xxxl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-xxxl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-xxxl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-xxxl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-xxxl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-xxxl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-xxxl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-xxxl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-xxxl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xxxl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xxxl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xxxl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xxxl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xxxl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xxxl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-xxxl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xxxl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xxxl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-xxxl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xxxl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 2500px) {
  .flex-xxxxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xxxxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xxxxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xxxxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xxxxl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xxxxl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xxxxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-xxxxl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xxxxl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-xxxxl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-xxxxl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-xxxxl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-xxxxl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-xxxxl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-xxxxl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-xxxxl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-xxxxl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-xxxxl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-xxxxl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-xxxxl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-xxxxl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-xxxxl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-xxxxl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xxxxl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xxxxl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xxxxl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xxxxl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xxxxl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xxxxl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-xxxxl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xxxxl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xxxxl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-xxxxl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xxxxl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 359px) {
  .float-ssm-left {
    float: left !important; }
  .float-ssm-right {
    float: right !important; }
  .float-ssm-none {
    float: none !important; } }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

@media (min-width: 1600px) {
  .float-xxxl-left {
    float: left !important; }
  .float-xxxl-right {
    float: right !important; }
  .float-xxxl-none {
    float: none !important; } }

@media (min-width: 2500px) {
  .float-xxxxl-left {
    float: left !important; }
  .float-xxxxl-right {
    float: right !important; }
  .float-xxxxl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 359px) {
  .m-ssm-0 {
    margin: 0 !important; }
  .mt-ssm-0,
  .my-ssm-0 {
    margin-top: 0 !important; }
  .mr-ssm-0,
  .mx-ssm-0 {
    margin-right: 0 !important; }
  .mb-ssm-0,
  .my-ssm-0 {
    margin-bottom: 0 !important; }
  .ml-ssm-0,
  .mx-ssm-0 {
    margin-left: 0 !important; }
  .m-ssm-1 {
    margin: 0.25rem !important; }
  .mt-ssm-1,
  .my-ssm-1 {
    margin-top: 0.25rem !important; }
  .mr-ssm-1,
  .mx-ssm-1 {
    margin-right: 0.25rem !important; }
  .mb-ssm-1,
  .my-ssm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-ssm-1,
  .mx-ssm-1 {
    margin-left: 0.25rem !important; }
  .m-ssm-2 {
    margin: 0.5rem !important; }
  .mt-ssm-2,
  .my-ssm-2 {
    margin-top: 0.5rem !important; }
  .mr-ssm-2,
  .mx-ssm-2 {
    margin-right: 0.5rem !important; }
  .mb-ssm-2,
  .my-ssm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-ssm-2,
  .mx-ssm-2 {
    margin-left: 0.5rem !important; }
  .m-ssm-3 {
    margin: 1rem !important; }
  .mt-ssm-3,
  .my-ssm-3 {
    margin-top: 1rem !important; }
  .mr-ssm-3,
  .mx-ssm-3 {
    margin-right: 1rem !important; }
  .mb-ssm-3,
  .my-ssm-3 {
    margin-bottom: 1rem !important; }
  .ml-ssm-3,
  .mx-ssm-3 {
    margin-left: 1rem !important; }
  .m-ssm-4 {
    margin: 1.5rem !important; }
  .mt-ssm-4,
  .my-ssm-4 {
    margin-top: 1.5rem !important; }
  .mr-ssm-4,
  .mx-ssm-4 {
    margin-right: 1.5rem !important; }
  .mb-ssm-4,
  .my-ssm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-ssm-4,
  .mx-ssm-4 {
    margin-left: 1.5rem !important; }
  .m-ssm-5 {
    margin: 3rem !important; }
  .mt-ssm-5,
  .my-ssm-5 {
    margin-top: 3rem !important; }
  .mr-ssm-5,
  .mx-ssm-5 {
    margin-right: 3rem !important; }
  .mb-ssm-5,
  .my-ssm-5 {
    margin-bottom: 3rem !important; }
  .ml-ssm-5,
  .mx-ssm-5 {
    margin-left: 3rem !important; }
  .p-ssm-0 {
    padding: 0 !important; }
  .pt-ssm-0,
  .py-ssm-0 {
    padding-top: 0 !important; }
  .pr-ssm-0,
  .px-ssm-0 {
    padding-right: 0 !important; }
  .pb-ssm-0,
  .py-ssm-0 {
    padding-bottom: 0 !important; }
  .pl-ssm-0,
  .px-ssm-0 {
    padding-left: 0 !important; }
  .p-ssm-1 {
    padding: 0.25rem !important; }
  .pt-ssm-1,
  .py-ssm-1 {
    padding-top: 0.25rem !important; }
  .pr-ssm-1,
  .px-ssm-1 {
    padding-right: 0.25rem !important; }
  .pb-ssm-1,
  .py-ssm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-ssm-1,
  .px-ssm-1 {
    padding-left: 0.25rem !important; }
  .p-ssm-2 {
    padding: 0.5rem !important; }
  .pt-ssm-2,
  .py-ssm-2 {
    padding-top: 0.5rem !important; }
  .pr-ssm-2,
  .px-ssm-2 {
    padding-right: 0.5rem !important; }
  .pb-ssm-2,
  .py-ssm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-ssm-2,
  .px-ssm-2 {
    padding-left: 0.5rem !important; }
  .p-ssm-3 {
    padding: 1rem !important; }
  .pt-ssm-3,
  .py-ssm-3 {
    padding-top: 1rem !important; }
  .pr-ssm-3,
  .px-ssm-3 {
    padding-right: 1rem !important; }
  .pb-ssm-3,
  .py-ssm-3 {
    padding-bottom: 1rem !important; }
  .pl-ssm-3,
  .px-ssm-3 {
    padding-left: 1rem !important; }
  .p-ssm-4 {
    padding: 1.5rem !important; }
  .pt-ssm-4,
  .py-ssm-4 {
    padding-top: 1.5rem !important; }
  .pr-ssm-4,
  .px-ssm-4 {
    padding-right: 1.5rem !important; }
  .pb-ssm-4,
  .py-ssm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-ssm-4,
  .px-ssm-4 {
    padding-left: 1.5rem !important; }
  .p-ssm-5 {
    padding: 3rem !important; }
  .pt-ssm-5,
  .py-ssm-5 {
    padding-top: 3rem !important; }
  .pr-ssm-5,
  .px-ssm-5 {
    padding-right: 3rem !important; }
  .pb-ssm-5,
  .py-ssm-5 {
    padding-bottom: 3rem !important; }
  .pl-ssm-5,
  .px-ssm-5 {
    padding-left: 3rem !important; }
  .m-ssm-n1 {
    margin: -0.25rem !important; }
  .mt-ssm-n1,
  .my-ssm-n1 {
    margin-top: -0.25rem !important; }
  .mr-ssm-n1,
  .mx-ssm-n1 {
    margin-right: -0.25rem !important; }
  .mb-ssm-n1,
  .my-ssm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-ssm-n1,
  .mx-ssm-n1 {
    margin-left: -0.25rem !important; }
  .m-ssm-n2 {
    margin: -0.5rem !important; }
  .mt-ssm-n2,
  .my-ssm-n2 {
    margin-top: -0.5rem !important; }
  .mr-ssm-n2,
  .mx-ssm-n2 {
    margin-right: -0.5rem !important; }
  .mb-ssm-n2,
  .my-ssm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-ssm-n2,
  .mx-ssm-n2 {
    margin-left: -0.5rem !important; }
  .m-ssm-n3 {
    margin: -1rem !important; }
  .mt-ssm-n3,
  .my-ssm-n3 {
    margin-top: -1rem !important; }
  .mr-ssm-n3,
  .mx-ssm-n3 {
    margin-right: -1rem !important; }
  .mb-ssm-n3,
  .my-ssm-n3 {
    margin-bottom: -1rem !important; }
  .ml-ssm-n3,
  .mx-ssm-n3 {
    margin-left: -1rem !important; }
  .m-ssm-n4 {
    margin: -1.5rem !important; }
  .mt-ssm-n4,
  .my-ssm-n4 {
    margin-top: -1.5rem !important; }
  .mr-ssm-n4,
  .mx-ssm-n4 {
    margin-right: -1.5rem !important; }
  .mb-ssm-n4,
  .my-ssm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-ssm-n4,
  .mx-ssm-n4 {
    margin-left: -1.5rem !important; }
  .m-ssm-n5 {
    margin: -3rem !important; }
  .mt-ssm-n5,
  .my-ssm-n5 {
    margin-top: -3rem !important; }
  .mr-ssm-n5,
  .mx-ssm-n5 {
    margin-right: -3rem !important; }
  .mb-ssm-n5,
  .my-ssm-n5 {
    margin-bottom: -3rem !important; }
  .ml-ssm-n5,
  .mx-ssm-n5 {
    margin-left: -3rem !important; }
  .m-ssm-auto {
    margin: auto !important; }
  .mt-ssm-auto,
  .my-ssm-auto {
    margin-top: auto !important; }
  .mr-ssm-auto,
  .mx-ssm-auto {
    margin-right: auto !important; }
  .mb-ssm-auto,
  .my-ssm-auto {
    margin-bottom: auto !important; }
  .ml-ssm-auto,
  .mx-ssm-auto {
    margin-left: auto !important; } }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

@media (min-width: 1600px) {
  .m-xxxl-0 {
    margin: 0 !important; }
  .mt-xxxl-0,
  .my-xxxl-0 {
    margin-top: 0 !important; }
  .mr-xxxl-0,
  .mx-xxxl-0 {
    margin-right: 0 !important; }
  .mb-xxxl-0,
  .my-xxxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxxl-0,
  .mx-xxxl-0 {
    margin-left: 0 !important; }
  .m-xxxl-1 {
    margin: 0.25rem !important; }
  .mt-xxxl-1,
  .my-xxxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxxl-1,
  .mx-xxxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxxl-1,
  .my-xxxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxxl-1,
  .mx-xxxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxxl-2 {
    margin: 0.5rem !important; }
  .mt-xxxl-2,
  .my-xxxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxxl-2,
  .mx-xxxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxxl-2,
  .my-xxxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxxl-2,
  .mx-xxxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxxl-3 {
    margin: 1rem !important; }
  .mt-xxxl-3,
  .my-xxxl-3 {
    margin-top: 1rem !important; }
  .mr-xxxl-3,
  .mx-xxxl-3 {
    margin-right: 1rem !important; }
  .mb-xxxl-3,
  .my-xxxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxxl-3,
  .mx-xxxl-3 {
    margin-left: 1rem !important; }
  .m-xxxl-4 {
    margin: 1.5rem !important; }
  .mt-xxxl-4,
  .my-xxxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxxl-4,
  .mx-xxxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxxl-4,
  .my-xxxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxxl-4,
  .mx-xxxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxxl-5 {
    margin: 3rem !important; }
  .mt-xxxl-5,
  .my-xxxl-5 {
    margin-top: 3rem !important; }
  .mr-xxxl-5,
  .mx-xxxl-5 {
    margin-right: 3rem !important; }
  .mb-xxxl-5,
  .my-xxxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxxl-5,
  .mx-xxxl-5 {
    margin-left: 3rem !important; }
  .p-xxxl-0 {
    padding: 0 !important; }
  .pt-xxxl-0,
  .py-xxxl-0 {
    padding-top: 0 !important; }
  .pr-xxxl-0,
  .px-xxxl-0 {
    padding-right: 0 !important; }
  .pb-xxxl-0,
  .py-xxxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxxl-0,
  .px-xxxl-0 {
    padding-left: 0 !important; }
  .p-xxxl-1 {
    padding: 0.25rem !important; }
  .pt-xxxl-1,
  .py-xxxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxxl-1,
  .px-xxxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxxl-1,
  .py-xxxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxxl-1,
  .px-xxxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxxl-2 {
    padding: 0.5rem !important; }
  .pt-xxxl-2,
  .py-xxxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxxl-2,
  .px-xxxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxxl-2,
  .py-xxxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxxl-2,
  .px-xxxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxxl-3 {
    padding: 1rem !important; }
  .pt-xxxl-3,
  .py-xxxl-3 {
    padding-top: 1rem !important; }
  .pr-xxxl-3,
  .px-xxxl-3 {
    padding-right: 1rem !important; }
  .pb-xxxl-3,
  .py-xxxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxxl-3,
  .px-xxxl-3 {
    padding-left: 1rem !important; }
  .p-xxxl-4 {
    padding: 1.5rem !important; }
  .pt-xxxl-4,
  .py-xxxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxxl-4,
  .px-xxxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxxl-4,
  .py-xxxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxxl-4,
  .px-xxxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxxl-5 {
    padding: 3rem !important; }
  .pt-xxxl-5,
  .py-xxxl-5 {
    padding-top: 3rem !important; }
  .pr-xxxl-5,
  .px-xxxl-5 {
    padding-right: 3rem !important; }
  .pb-xxxl-5,
  .py-xxxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxxl-5,
  .px-xxxl-5 {
    padding-left: 3rem !important; }
  .m-xxxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxxl-n1,
  .my-xxxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxxl-n1,
  .mx-xxxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxxl-n1,
  .my-xxxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxxl-n1,
  .mx-xxxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxxl-n2,
  .my-xxxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxxl-n2,
  .mx-xxxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxxl-n2,
  .my-xxxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxxl-n2,
  .mx-xxxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxxl-n3 {
    margin: -1rem !important; }
  .mt-xxxl-n3,
  .my-xxxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxxl-n3,
  .mx-xxxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxxl-n3,
  .my-xxxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxxl-n3,
  .mx-xxxl-n3 {
    margin-left: -1rem !important; }
  .m-xxxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxxl-n4,
  .my-xxxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxxl-n4,
  .mx-xxxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxxl-n4,
  .my-xxxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxxl-n4,
  .mx-xxxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxxl-n5 {
    margin: -3rem !important; }
  .mt-xxxl-n5,
  .my-xxxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxxl-n5,
  .mx-xxxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxxl-n5,
  .my-xxxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxxl-n5,
  .mx-xxxl-n5 {
    margin-left: -3rem !important; }
  .m-xxxl-auto {
    margin: auto !important; }
  .mt-xxxl-auto,
  .my-xxxl-auto {
    margin-top: auto !important; }
  .mr-xxxl-auto,
  .mx-xxxl-auto {
    margin-right: auto !important; }
  .mb-xxxl-auto,
  .my-xxxl-auto {
    margin-bottom: auto !important; }
  .ml-xxxl-auto,
  .mx-xxxl-auto {
    margin-left: auto !important; } }

@media (min-width: 2500px) {
  .m-xxxxl-0 {
    margin: 0 !important; }
  .mt-xxxxl-0,
  .my-xxxxl-0 {
    margin-top: 0 !important; }
  .mr-xxxxl-0,
  .mx-xxxxl-0 {
    margin-right: 0 !important; }
  .mb-xxxxl-0,
  .my-xxxxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxxxl-0,
  .mx-xxxxl-0 {
    margin-left: 0 !important; }
  .m-xxxxl-1 {
    margin: 0.25rem !important; }
  .mt-xxxxl-1,
  .my-xxxxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxxxl-1,
  .mx-xxxxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxxxl-1,
  .my-xxxxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxxxl-1,
  .mx-xxxxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxxxl-2 {
    margin: 0.5rem !important; }
  .mt-xxxxl-2,
  .my-xxxxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxxxl-2,
  .mx-xxxxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxxxl-2,
  .my-xxxxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxxxl-2,
  .mx-xxxxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxxxl-3 {
    margin: 1rem !important; }
  .mt-xxxxl-3,
  .my-xxxxl-3 {
    margin-top: 1rem !important; }
  .mr-xxxxl-3,
  .mx-xxxxl-3 {
    margin-right: 1rem !important; }
  .mb-xxxxl-3,
  .my-xxxxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxxxl-3,
  .mx-xxxxl-3 {
    margin-left: 1rem !important; }
  .m-xxxxl-4 {
    margin: 1.5rem !important; }
  .mt-xxxxl-4,
  .my-xxxxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxxxl-4,
  .mx-xxxxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxxxl-4,
  .my-xxxxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxxxl-4,
  .mx-xxxxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxxxl-5 {
    margin: 3rem !important; }
  .mt-xxxxl-5,
  .my-xxxxl-5 {
    margin-top: 3rem !important; }
  .mr-xxxxl-5,
  .mx-xxxxl-5 {
    margin-right: 3rem !important; }
  .mb-xxxxl-5,
  .my-xxxxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxxxl-5,
  .mx-xxxxl-5 {
    margin-left: 3rem !important; }
  .p-xxxxl-0 {
    padding: 0 !important; }
  .pt-xxxxl-0,
  .py-xxxxl-0 {
    padding-top: 0 !important; }
  .pr-xxxxl-0,
  .px-xxxxl-0 {
    padding-right: 0 !important; }
  .pb-xxxxl-0,
  .py-xxxxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxxxl-0,
  .px-xxxxl-0 {
    padding-left: 0 !important; }
  .p-xxxxl-1 {
    padding: 0.25rem !important; }
  .pt-xxxxl-1,
  .py-xxxxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxxxl-1,
  .px-xxxxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxxxl-1,
  .py-xxxxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxxxl-1,
  .px-xxxxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxxxl-2 {
    padding: 0.5rem !important; }
  .pt-xxxxl-2,
  .py-xxxxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxxxl-2,
  .px-xxxxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxxxl-2,
  .py-xxxxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxxxl-2,
  .px-xxxxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxxxl-3 {
    padding: 1rem !important; }
  .pt-xxxxl-3,
  .py-xxxxl-3 {
    padding-top: 1rem !important; }
  .pr-xxxxl-3,
  .px-xxxxl-3 {
    padding-right: 1rem !important; }
  .pb-xxxxl-3,
  .py-xxxxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxxxl-3,
  .px-xxxxl-3 {
    padding-left: 1rem !important; }
  .p-xxxxl-4 {
    padding: 1.5rem !important; }
  .pt-xxxxl-4,
  .py-xxxxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxxxl-4,
  .px-xxxxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxxxl-4,
  .py-xxxxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxxxl-4,
  .px-xxxxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxxxl-5 {
    padding: 3rem !important; }
  .pt-xxxxl-5,
  .py-xxxxl-5 {
    padding-top: 3rem !important; }
  .pr-xxxxl-5,
  .px-xxxxl-5 {
    padding-right: 3rem !important; }
  .pb-xxxxl-5,
  .py-xxxxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxxxl-5,
  .px-xxxxl-5 {
    padding-left: 3rem !important; }
  .m-xxxxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxxxl-n1,
  .my-xxxxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxxxl-n1,
  .mx-xxxxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxxxl-n1,
  .my-xxxxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxxxl-n1,
  .mx-xxxxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxxxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxxxl-n2,
  .my-xxxxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxxxl-n2,
  .mx-xxxxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxxxl-n2,
  .my-xxxxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxxxl-n2,
  .mx-xxxxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxxxl-n3 {
    margin: -1rem !important; }
  .mt-xxxxl-n3,
  .my-xxxxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxxxl-n3,
  .mx-xxxxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxxxl-n3,
  .my-xxxxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxxxl-n3,
  .mx-xxxxl-n3 {
    margin-left: -1rem !important; }
  .m-xxxxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxxxl-n4,
  .my-xxxxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxxxl-n4,
  .mx-xxxxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxxxl-n4,
  .my-xxxxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxxxl-n4,
  .mx-xxxxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxxxl-n5 {
    margin: -3rem !important; }
  .mt-xxxxl-n5,
  .my-xxxxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxxxl-n5,
  .mx-xxxxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxxxl-n5,
  .my-xxxxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxxxl-n5,
  .mx-xxxxl-n5 {
    margin-left: -3rem !important; }
  .m-xxxxl-auto {
    margin: auto !important; }
  .mt-xxxxl-auto,
  .my-xxxxl-auto {
    margin-top: auto !important; }
  .mr-xxxxl-auto,
  .mx-xxxxl-auto {
    margin-right: auto !important; }
  .mb-xxxxl-auto,
  .my-xxxxl-auto {
    margin-bottom: auto !important; }
  .ml-xxxxl-auto,
  .mx-xxxxl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 359px) {
  .text-ssm-left {
    text-align: left !important; }
  .text-ssm-right {
    text-align: right !important; }
  .text-ssm-center {
    text-align: center !important; } }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1600px) {
  .text-xxxl-left {
    text-align: left !important; }
  .text-xxxl-right {
    text-align: right !important; }
  .text-xxxl-center {
    text-align: center !important; } }

@media (min-width: 2500px) {
  .text-xxxxl-left {
    text-align: left !important; }
  .text-xxxxl-right {
    text-align: right !important; }
  .text-xxxxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder, .templemans-content-block_h3 {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #276069 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #122d31 !important; }

.text-secondary {
  color: #347780 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #1e444a !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-templemans {
  color: #6d88a3 !important; }

a.text-templemans:hover, a.text-templemans:focus {
  color: #4c6278 !important; }

.text-templemans_secondary {
  color: #ebebeb !important; }

a.text-templemans_secondary:hover, a.text-templemans_secondary:focus {
  color: #c5c5c5 !important; }

.text-templemans_text {
  color: #1f364e !important; }

a.text-templemans_text:hover, a.text-templemans_text:focus {
  color: #091017 !important; }

.text-cafe-circa {
  color: #63a793 !important; }

a.text-cafe-circa:hover, a.text-cafe-circa:focus {
  color: #44796a !important; }

.text-grey {
  color: #373f49 !important; }

a.text-grey:hover, a.text-grey:focus {
  color: #16191d !important; }

.text-grey-secondary {
  color: #595959 !important; }

a.text-grey-secondary:hover, a.text-grey-secondary:focus {
  color: #333333 !important; }

.text-grey-links {
  color: #cccccc !important; }

a.text-grey-links:hover, a.text-grey-links:focus {
  color: #a6a6a6 !important; }

.text-background-primary {
  color: #414141 !important; }

a.text-background-primary:hover, a.text-background-primary:focus {
  color: #1b1b1b !important; }

.text-background-secondary {
  color: #fffcf2 !important; }

a.text-background-secondary:hover, a.text-background-secondary:focus {
  color: #ffeaa6 !important; }

.text-saac-comp {
  color: #be6c13 !important; }

a.text-saac-comp:hover, a.text-saac-comp:focus {
  color: #78440c !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #276069; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 358.98px) {
  .navbar-expand-ssm > .container,
  .navbar-expand-ssm > .container-fluid, .navbar-expand-ssm > .container-sm, .navbar-expand-ssm > .container-md, .navbar-expand-ssm > .container-lg, .navbar-expand-ssm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 359px) {
  .navbar-expand-ssm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-ssm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-ssm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-ssm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-ssm > .container,
    .navbar-expand-ssm > .container-fluid, .navbar-expand-ssm > .container-sm, .navbar-expand-ssm > .container-md, .navbar-expand-ssm > .container-lg, .navbar-expand-ssm > .container-xl {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-ssm .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-ssm .navbar-toggler {
      display: none; } }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .bottom-header .navbar-expand-md > .container, .navbar-expand-md .bottom-header > .container, .bottom-header > .container,
  .navbar-expand-md > .container-fluid,
  .bottom-header .navbar-expand-md > .container-fluid,
  .navbar-expand-md .bottom-header > .container-fluid,
  .bottom-header > .container-fluid, .navbar-expand-md > .container-sm, .bottom-header .navbar-expand-md > .container-sm, .navbar-expand-md .bottom-header > .container-sm, .bottom-header > .container-sm, .navbar-expand-md > .container-md, .bottom-header .navbar-expand-md > .container-md, .navbar-expand-md .bottom-header > .container-md, .bottom-header > .container-md, .navbar-expand-md > .container-lg, .bottom-header .navbar-expand-md > .container-lg, .navbar-expand-md .bottom-header > .container-lg, .bottom-header > .container-lg, .navbar-expand-md > .container-xl, .bottom-header .navbar-expand-md > .container-xl, .navbar-expand-md .bottom-header > .container-xl, .bottom-header > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md, .bottom-header {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-md .navbar-nav, .bottom-header .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu, .bottom-header .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link, .bottom-header .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container, .bottom-header .navbar-expand-md > .container, .navbar-expand-md .bottom-header > .container, .bottom-header > .container,
    .navbar-expand-md > .container-fluid,
    .bottom-header .navbar-expand-md > .container-fluid,
    .navbar-expand-md .bottom-header > .container-fluid,
    .bottom-header > .container-fluid, .navbar-expand-md > .container-sm, .bottom-header .navbar-expand-md > .container-sm, .navbar-expand-md .bottom-header > .container-sm, .bottom-header > .container-sm, .navbar-expand-md > .container-md, .bottom-header .navbar-expand-md > .container-md, .navbar-expand-md .bottom-header > .container-md, .bottom-header > .container-md, .navbar-expand-md > .container-lg, .bottom-header .navbar-expand-md > .container-lg, .navbar-expand-md .bottom-header > .container-lg, .bottom-header > .container-lg, .navbar-expand-md > .container-xl, .bottom-header .navbar-expand-md > .container-xl, .navbar-expand-md .bottom-header > .container-xl, .bottom-header > .container-xl {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse, .bottom-header .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-md .navbar-toggler, .bottom-header .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1399.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

@media (max-width: 1599.98px) {
  .navbar-expand-xxxl > .container,
  .navbar-expand-xxxl > .container-fluid, .navbar-expand-xxxl > .container-sm, .navbar-expand-xxxl > .container-md, .navbar-expand-xxxl > .container-lg, .navbar-expand-xxxl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1600px) {
  .navbar-expand-xxxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-xxxl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xxxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxxl > .container,
    .navbar-expand-xxxl > .container-fluid, .navbar-expand-xxxl > .container-sm, .navbar-expand-xxxl > .container-md, .navbar-expand-xxxl > .container-lg, .navbar-expand-xxxl > .container-xl {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-xxxl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-xxxl .navbar-toggler {
      display: none; } }

@media (max-width: 2499.98px) {
  .navbar-expand-xxxxl > .container,
  .navbar-expand-xxxxl > .container-fluid, .navbar-expand-xxxxl > .container-sm, .navbar-expand-xxxxl > .container-md, .navbar-expand-xxxxl > .container-lg, .navbar-expand-xxxxl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 2500px) {
  .navbar-expand-xxxxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-xxxxl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xxxxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxxxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxxxl > .container,
    .navbar-expand-xxxxl > .container-fluid, .navbar-expand-xxxxl > .container-sm, .navbar-expand-xxxxl > .container-md, .navbar-expand-xxxxl > .container-lg, .navbar-expand-xxxxl > .container-xl {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-xxxxl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-xxxxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 25px; }

@media (min-width: 576px) {
  .card-deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    margin-right: -25px;
    margin-left: -25px; }
    .card-deck .card {
      -webkit-box-flex: 1;
          -ms-flex: 1 0 0%;
              flex: 1 0 0%;
      margin-right: 25px;
      margin-bottom: 0;
      margin-left: 25px; } }

.card-group > .card {
  margin-bottom: 25px; }

@media (min-width: 576px) {
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap; }
    .card-group > .card {
      -webkit-box-flex: 1;
          -ms-flex: 1 0 0%;
              flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.btn, .btn-cta {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, .btn-cta {
      -webkit-transition: none;
      transition: none; } }
  .btn:hover, .btn-cta:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn-cta:focus, .btn.focus, .focus.btn-cta {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(39, 96, 105, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(39, 96, 105, 0.25); }
  .btn.disabled, .disabled.btn-cta, .btn:disabled, .btn-cta:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled), .btn-cta:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled, a.disabled.btn-cta,
fieldset:disabled a.btn,
fieldset:disabled a.btn-cta {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #276069;
  border-color: #276069; }
  .btn-primary:hover {
    color: #fff;
    background-color: #1d474d;
    border-color: #193e44; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #1d474d;
    border-color: #193e44;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(71, 120, 128, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(71, 120, 128, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #276069;
    border-color: #276069; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #193e44;
    border-color: #16363b; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(71, 120, 128, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(71, 120, 128, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #347780;
  border-color: #347780; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #295e65;
    border-color: #25555c; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #295e65;
    border-color: #25555c;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 139, 147, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(82, 139, 147, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #347780;
    border-color: #347780; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #25555c;
    border-color: #224d53; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 139, 147, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(82, 139, 147, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-templemans {
  color: #fff;
  background-color: #6d88a3;
  border-color: #6d88a3; }
  .btn-templemans:hover {
    color: #fff;
    background-color: #5a758f;
    border-color: #556f88; }
  .btn-templemans:focus, .btn-templemans.focus {
    color: #fff;
    background-color: #5a758f;
    border-color: #556f88;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(131, 154, 177, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(131, 154, 177, 0.5); }
  .btn-templemans.disabled, .btn-templemans:disabled {
    color: #fff;
    background-color: #6d88a3;
    border-color: #6d88a3; }
  .btn-templemans:not(:disabled):not(.disabled):active, .btn-templemans:not(:disabled):not(.disabled).active,
  .show > .btn-templemans.dropdown-toggle {
    color: #fff;
    background-color: #556f88;
    border-color: #516880; }
    .btn-templemans:not(:disabled):not(.disabled):active:focus, .btn-templemans:not(:disabled):not(.disabled).active:focus,
    .show > .btn-templemans.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(131, 154, 177, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(131, 154, 177, 0.5); }

.btn-templemans_secondary {
  color: #212529;
  background-color: #ebebeb;
  border-color: #ebebeb; }
  .btn-templemans_secondary:hover {
    color: #212529;
    background-color: #d8d8d8;
    border-color: #d2d2d2; }
  .btn-templemans_secondary:focus, .btn-templemans_secondary.focus {
    color: #212529;
    background-color: #d8d8d8;
    border-color: #d2d2d2;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(205, 205, 206, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(205, 205, 206, 0.5); }
  .btn-templemans_secondary.disabled, .btn-templemans_secondary:disabled {
    color: #212529;
    background-color: #ebebeb;
    border-color: #ebebeb; }
  .btn-templemans_secondary:not(:disabled):not(.disabled):active, .btn-templemans_secondary:not(:disabled):not(.disabled).active,
  .show > .btn-templemans_secondary.dropdown-toggle {
    color: #212529;
    background-color: #d2d2d2;
    border-color: #cbcbcb; }
    .btn-templemans_secondary:not(:disabled):not(.disabled):active:focus, .btn-templemans_secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-templemans_secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(205, 205, 206, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(205, 205, 206, 0.5); }

.btn-templemans_text {
  color: #fff;
  background-color: #1f364e;
  border-color: #1f364e; }
  .btn-templemans_text:hover {
    color: #fff;
    background-color: #142333;
    border-color: #101d2a; }
  .btn-templemans_text:focus, .btn-templemans_text.focus {
    color: #fff;
    background-color: #142333;
    border-color: #101d2a;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(65, 84, 105, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(65, 84, 105, 0.5); }
  .btn-templemans_text.disabled, .btn-templemans_text:disabled {
    color: #fff;
    background-color: #1f364e;
    border-color: #1f364e; }
  .btn-templemans_text:not(:disabled):not(.disabled):active, .btn-templemans_text:not(:disabled):not(.disabled).active,
  .show > .btn-templemans_text.dropdown-toggle {
    color: #fff;
    background-color: #101d2a;
    border-color: #0d1620; }
    .btn-templemans_text:not(:disabled):not(.disabled):active:focus, .btn-templemans_text:not(:disabled):not(.disabled).active:focus,
    .show > .btn-templemans_text.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(65, 84, 105, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(65, 84, 105, 0.5); }

.btn-cafe-circa {
  color: #fff;
  background-color: #63a793;
  border-color: #63a793; }
  .btn-cafe-circa:hover {
    color: #fff;
    background-color: #52927f;
    border-color: #4e8978; }
  .btn-cafe-circa:focus, .btn-cafe-circa.focus {
    color: #fff;
    background-color: #52927f;
    border-color: #4e8978;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(122, 180, 163, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(122, 180, 163, 0.5); }
  .btn-cafe-circa.disabled, .btn-cafe-circa:disabled {
    color: #fff;
    background-color: #63a793;
    border-color: #63a793; }
  .btn-cafe-circa:not(:disabled):not(.disabled):active, .btn-cafe-circa:not(:disabled):not(.disabled).active,
  .show > .btn-cafe-circa.dropdown-toggle {
    color: #fff;
    background-color: #4e8978;
    border-color: #498171; }
    .btn-cafe-circa:not(:disabled):not(.disabled):active:focus, .btn-cafe-circa:not(:disabled):not(.disabled).active:focus,
    .show > .btn-cafe-circa.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(122, 180, 163, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(122, 180, 163, 0.5); }

.btn-grey {
  color: #fff;
  background-color: #373f49;
  border-color: #373f49; }
  .btn-grey:hover {
    color: #fff;
    background-color: #272c33;
    border-color: #21262c; }
  .btn-grey:focus, .btn-grey.focus {
    color: #fff;
    background-color: #272c33;
    border-color: #21262c;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(85, 92, 100, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(85, 92, 100, 0.5); }
  .btn-grey.disabled, .btn-grey:disabled {
    color: #fff;
    background-color: #373f49;
    border-color: #373f49; }
  .btn-grey:not(:disabled):not(.disabled):active, .btn-grey:not(:disabled):not(.disabled).active,
  .show > .btn-grey.dropdown-toggle {
    color: #fff;
    background-color: #21262c;
    border-color: #1c2025; }
    .btn-grey:not(:disabled):not(.disabled):active:focus, .btn-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-grey.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(85, 92, 100, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(85, 92, 100, 0.5); }

.btn-grey-secondary {
  color: #fff;
  background-color: #595959;
  border-color: #595959; }
  .btn-grey-secondary:hover {
    color: #fff;
    background-color: #464646;
    border-color: #404040; }
  .btn-grey-secondary:focus, .btn-grey-secondary.focus {
    color: #fff;
    background-color: #464646;
    border-color: #404040;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 114, 114, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(114, 114, 114, 0.5); }
  .btn-grey-secondary.disabled, .btn-grey-secondary:disabled {
    color: #fff;
    background-color: #595959;
    border-color: #595959; }
  .btn-grey-secondary:not(:disabled):not(.disabled):active, .btn-grey-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-grey-secondary.dropdown-toggle {
    color: #fff;
    background-color: #404040;
    border-color: #393939; }
    .btn-grey-secondary:not(:disabled):not(.disabled):active:focus, .btn-grey-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-grey-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 114, 114, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(114, 114, 114, 0.5); }

.btn-grey-links {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc; }
  .btn-grey-links:hover {
    color: #212529;
    background-color: #b9b9b9;
    border-color: #b3b3b3; }
  .btn-grey-links:focus, .btn-grey-links.focus {
    color: #212529;
    background-color: #b9b9b9;
    border-color: #b3b3b3;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(178, 179, 180, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(178, 179, 180, 0.5); }
  .btn-grey-links.disabled, .btn-grey-links:disabled {
    color: #212529;
    background-color: #cccccc;
    border-color: #cccccc; }
  .btn-grey-links:not(:disabled):not(.disabled):active, .btn-grey-links:not(:disabled):not(.disabled).active,
  .show > .btn-grey-links.dropdown-toggle {
    color: #212529;
    background-color: #b3b3b3;
    border-color: #acacac; }
    .btn-grey-links:not(:disabled):not(.disabled):active:focus, .btn-grey-links:not(:disabled):not(.disabled).active:focus,
    .show > .btn-grey-links.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(178, 179, 180, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(178, 179, 180, 0.5); }

.btn-background-primary {
  color: #fff;
  background-color: #414141;
  border-color: #414141; }
  .btn-background-primary:hover {
    color: #fff;
    background-color: #2e2e2e;
    border-color: #282828; }
  .btn-background-primary:focus, .btn-background-primary.focus {
    color: #fff;
    background-color: #2e2e2e;
    border-color: #282828;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(94, 94, 94, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(94, 94, 94, 0.5); }
  .btn-background-primary.disabled, .btn-background-primary:disabled {
    color: #fff;
    background-color: #414141;
    border-color: #414141; }
  .btn-background-primary:not(:disabled):not(.disabled):active, .btn-background-primary:not(:disabled):not(.disabled).active,
  .show > .btn-background-primary.dropdown-toggle {
    color: #fff;
    background-color: #282828;
    border-color: #212121; }
    .btn-background-primary:not(:disabled):not(.disabled):active:focus, .btn-background-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-background-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(94, 94, 94, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(94, 94, 94, 0.5); }

.btn-background-secondary {
  color: #212529;
  background-color: #fffcf2;
  border-color: #fffcf2; }
  .btn-background-secondary:hover {
    color: #212529;
    background-color: #fff3cc;
    border-color: #fff0bf; }
  .btn-background-secondary:focus, .btn-background-secondary.focus {
    color: #212529;
    background-color: #fff3cc;
    border-color: #fff0bf;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 220, 212, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(222, 220, 212, 0.5); }
  .btn-background-secondary.disabled, .btn-background-secondary:disabled {
    color: #212529;
    background-color: #fffcf2;
    border-color: #fffcf2; }
  .btn-background-secondary:not(:disabled):not(.disabled):active, .btn-background-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-background-secondary.dropdown-toggle {
    color: #212529;
    background-color: #fff0bf;
    border-color: #ffedb2; }
    .btn-background-secondary:not(:disabled):not(.disabled):active:focus, .btn-background-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-background-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 220, 212, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(222, 220, 212, 0.5); }

.btn-saac-comp {
  color: #fff;
  background-color: #be6c13;
  border-color: #be6c13; }
  .btn-saac-comp:hover {
    color: #fff;
    background-color: #9b5810;
    border-color: #90520e; }
  .btn-saac-comp:focus, .btn-saac-comp.focus {
    color: #fff;
    background-color: #9b5810;
    border-color: #90520e;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(200, 130, 54, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(200, 130, 54, 0.5); }
  .btn-saac-comp.disabled, .btn-saac-comp:disabled {
    color: #fff;
    background-color: #be6c13;
    border-color: #be6c13; }
  .btn-saac-comp:not(:disabled):not(.disabled):active, .btn-saac-comp:not(:disabled):not(.disabled).active,
  .show > .btn-saac-comp.dropdown-toggle {
    color: #fff;
    background-color: #90520e;
    border-color: #844b0d; }
    .btn-saac-comp:not(:disabled):not(.disabled):active:focus, .btn-saac-comp:not(:disabled):not(.disabled).active:focus,
    .show > .btn-saac-comp.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(200, 130, 54, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(200, 130, 54, 0.5); }

.btn-outline-primary {
  color: #276069;
  border-color: #276069; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #276069;
    border-color: #276069; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(39, 96, 105, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(39, 96, 105, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #276069;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #276069;
    border-color: #276069; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(39, 96, 105, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(39, 96, 105, 0.5); }

.btn-outline-secondary {
  color: #347780;
  border-color: #347780; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #347780;
    border-color: #347780; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 119, 128, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(52, 119, 128, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #347780;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #347780;
    border-color: #347780; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 119, 128, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(52, 119, 128, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-templemans {
  color: #6d88a3;
  border-color: #6d88a3; }
  .btn-outline-templemans:hover {
    color: #fff;
    background-color: #6d88a3;
    border-color: #6d88a3; }
  .btn-outline-templemans:focus, .btn-outline-templemans.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(109, 136, 163, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(109, 136, 163, 0.5); }
  .btn-outline-templemans.disabled, .btn-outline-templemans:disabled {
    color: #6d88a3;
    background-color: transparent; }
  .btn-outline-templemans:not(:disabled):not(.disabled):active, .btn-outline-templemans:not(:disabled):not(.disabled).active,
  .show > .btn-outline-templemans.dropdown-toggle {
    color: #fff;
    background-color: #6d88a3;
    border-color: #6d88a3; }
    .btn-outline-templemans:not(:disabled):not(.disabled):active:focus, .btn-outline-templemans:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-templemans.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(109, 136, 163, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(109, 136, 163, 0.5); }

.btn-outline-templemans_secondary {
  color: #ebebeb;
  border-color: #ebebeb; }
  .btn-outline-templemans_secondary:hover {
    color: #212529;
    background-color: #ebebeb;
    border-color: #ebebeb; }
  .btn-outline-templemans_secondary:focus, .btn-outline-templemans_secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(235, 235, 235, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(235, 235, 235, 0.5); }
  .btn-outline-templemans_secondary.disabled, .btn-outline-templemans_secondary:disabled {
    color: #ebebeb;
    background-color: transparent; }
  .btn-outline-templemans_secondary:not(:disabled):not(.disabled):active, .btn-outline-templemans_secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-templemans_secondary.dropdown-toggle {
    color: #212529;
    background-color: #ebebeb;
    border-color: #ebebeb; }
    .btn-outline-templemans_secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-templemans_secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-templemans_secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(235, 235, 235, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(235, 235, 235, 0.5); }

.btn-outline-templemans_text {
  color: #1f364e;
  border-color: #1f364e; }
  .btn-outline-templemans_text:hover {
    color: #fff;
    background-color: #1f364e;
    border-color: #1f364e; }
  .btn-outline-templemans_text:focus, .btn-outline-templemans_text.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(31, 54, 78, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(31, 54, 78, 0.5); }
  .btn-outline-templemans_text.disabled, .btn-outline-templemans_text:disabled {
    color: #1f364e;
    background-color: transparent; }
  .btn-outline-templemans_text:not(:disabled):not(.disabled):active, .btn-outline-templemans_text:not(:disabled):not(.disabled).active,
  .show > .btn-outline-templemans_text.dropdown-toggle {
    color: #fff;
    background-color: #1f364e;
    border-color: #1f364e; }
    .btn-outline-templemans_text:not(:disabled):not(.disabled):active:focus, .btn-outline-templemans_text:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-templemans_text.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(31, 54, 78, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(31, 54, 78, 0.5); }

.btn-outline-cafe-circa {
  color: #63a793;
  border-color: #63a793; }
  .btn-outline-cafe-circa:hover {
    color: #fff;
    background-color: #63a793;
    border-color: #63a793; }
  .btn-outline-cafe-circa:focus, .btn-outline-cafe-circa.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(99, 167, 147, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(99, 167, 147, 0.5); }
  .btn-outline-cafe-circa.disabled, .btn-outline-cafe-circa:disabled {
    color: #63a793;
    background-color: transparent; }
  .btn-outline-cafe-circa:not(:disabled):not(.disabled):active, .btn-outline-cafe-circa:not(:disabled):not(.disabled).active,
  .show > .btn-outline-cafe-circa.dropdown-toggle {
    color: #fff;
    background-color: #63a793;
    border-color: #63a793; }
    .btn-outline-cafe-circa:not(:disabled):not(.disabled):active:focus, .btn-outline-cafe-circa:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-cafe-circa.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(99, 167, 147, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(99, 167, 147, 0.5); }

.btn-outline-grey {
  color: #373f49;
  border-color: #373f49; }
  .btn-outline-grey:hover {
    color: #fff;
    background-color: #373f49;
    border-color: #373f49; }
  .btn-outline-grey:focus, .btn-outline-grey.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(55, 63, 73, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(55, 63, 73, 0.5); }
  .btn-outline-grey.disabled, .btn-outline-grey:disabled {
    color: #373f49;
    background-color: transparent; }
  .btn-outline-grey:not(:disabled):not(.disabled):active, .btn-outline-grey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-grey.dropdown-toggle {
    color: #fff;
    background-color: #373f49;
    border-color: #373f49; }
    .btn-outline-grey:not(:disabled):not(.disabled):active:focus, .btn-outline-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-grey.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(55, 63, 73, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(55, 63, 73, 0.5); }

.btn-outline-grey-secondary {
  color: #595959;
  border-color: #595959; }
  .btn-outline-grey-secondary:hover {
    color: #fff;
    background-color: #595959;
    border-color: #595959; }
  .btn-outline-grey-secondary:focus, .btn-outline-grey-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(89, 89, 89, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(89, 89, 89, 0.5); }
  .btn-outline-grey-secondary.disabled, .btn-outline-grey-secondary:disabled {
    color: #595959;
    background-color: transparent; }
  .btn-outline-grey-secondary:not(:disabled):not(.disabled):active, .btn-outline-grey-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-grey-secondary.dropdown-toggle {
    color: #fff;
    background-color: #595959;
    border-color: #595959; }
    .btn-outline-grey-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-grey-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-grey-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(89, 89, 89, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(89, 89, 89, 0.5); }

.btn-outline-grey-links {
  color: #cccccc;
  border-color: #cccccc; }
  .btn-outline-grey-links:hover {
    color: #212529;
    background-color: #cccccc;
    border-color: #cccccc; }
  .btn-outline-grey-links:focus, .btn-outline-grey-links.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5); }
  .btn-outline-grey-links.disabled, .btn-outline-grey-links:disabled {
    color: #cccccc;
    background-color: transparent; }
  .btn-outline-grey-links:not(:disabled):not(.disabled):active, .btn-outline-grey-links:not(:disabled):not(.disabled).active,
  .show > .btn-outline-grey-links.dropdown-toggle {
    color: #212529;
    background-color: #cccccc;
    border-color: #cccccc; }
    .btn-outline-grey-links:not(:disabled):not(.disabled):active:focus, .btn-outline-grey-links:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-grey-links.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5); }

.btn-outline-background-primary {
  color: #414141;
  border-color: #414141; }
  .btn-outline-background-primary:hover {
    color: #fff;
    background-color: #414141;
    border-color: #414141; }
  .btn-outline-background-primary:focus, .btn-outline-background-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(65, 65, 65, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(65, 65, 65, 0.5); }
  .btn-outline-background-primary.disabled, .btn-outline-background-primary:disabled {
    color: #414141;
    background-color: transparent; }
  .btn-outline-background-primary:not(:disabled):not(.disabled):active, .btn-outline-background-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-background-primary.dropdown-toggle {
    color: #fff;
    background-color: #414141;
    border-color: #414141; }
    .btn-outline-background-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-background-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-background-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(65, 65, 65, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(65, 65, 65, 0.5); }

.btn-outline-background-secondary {
  color: #fffcf2;
  border-color: #fffcf2; }
  .btn-outline-background-secondary:hover {
    color: #212529;
    background-color: #fffcf2;
    border-color: #fffcf2; }
  .btn-outline-background-secondary:focus, .btn-outline-background-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 252, 242, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(255, 252, 242, 0.5); }
  .btn-outline-background-secondary.disabled, .btn-outline-background-secondary:disabled {
    color: #fffcf2;
    background-color: transparent; }
  .btn-outline-background-secondary:not(:disabled):not(.disabled):active, .btn-outline-background-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-background-secondary.dropdown-toggle {
    color: #212529;
    background-color: #fffcf2;
    border-color: #fffcf2; }
    .btn-outline-background-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-background-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-background-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 252, 242, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(255, 252, 242, 0.5); }

.btn-outline-saac-comp {
  color: #be6c13;
  border-color: #be6c13; }
  .btn-outline-saac-comp:hover {
    color: #fff;
    background-color: #be6c13;
    border-color: #be6c13; }
  .btn-outline-saac-comp:focus, .btn-outline-saac-comp.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(190, 108, 19, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(190, 108, 19, 0.5); }
  .btn-outline-saac-comp.disabled, .btn-outline-saac-comp:disabled {
    color: #be6c13;
    background-color: transparent; }
  .btn-outline-saac-comp:not(:disabled):not(.disabled):active, .btn-outline-saac-comp:not(:disabled):not(.disabled).active,
  .show > .btn-outline-saac-comp.dropdown-toggle {
    color: #fff;
    background-color: #be6c13;
    border-color: #be6c13; }
    .btn-outline-saac-comp:not(:disabled):not(.disabled):active:focus, .btn-outline-saac-comp:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-saac-comp.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(190, 108, 19, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(190, 108, 19, 0.5); }

.btn-link {
  font-weight: 400;
  color: #276069;
  text-decoration: none; }
  .btn-link:hover {
    color: #122d31;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      transition: none; } }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #51b0be;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(39, 96, 105, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(39, 96, 105, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      -ms-flex-negative: 0;
          flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .input-group-prepend .btn, .input-group-prepend .btn-cta,
  .input-group-append .btn,
  .input-group-append .btn-cta {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus, .input-group-prepend .btn-cta:focus,
    .input-group-append .btn:focus,
    .input-group-append .btn-cta:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn, .input-group-prepend .btn-cta + .btn, .input-group-prepend .btn + .btn-cta, .input-group-prepend .btn-cta + .btn-cta,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .btn-cta + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .input-group-text + .btn-cta,
  .input-group-append .btn + .btn,
  .input-group-append .btn-cta + .btn,
  .input-group-append .btn + .btn-cta,
  .input-group-append .btn-cta + .btn-cta,
  .input-group-append .btn + .input-group-text,
  .input-group-append .btn-cta + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .input-group-text + .btn-cta {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .btn-cta,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .btn-cta {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .btn-cta,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .btn-cta {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .btn-cta,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .btn-cta,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .btn-cta:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .btn-cta,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .btn-cta,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .btn-cta:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.display-5 {
  font-size: 2.9rem;
  font-weight: bolder;
  line-height: 1.2; }

body {
  background-color: #fff;
  opacity: 1;
  -webkit-transition: 1s opacity;
  transition: 1s opacity; }

body.tax-product_cat #content {
  background-image: url(../images/Ampersand.png);
  background-position: top right;
  background-size: 50%;
  background-repeat: no-repeat; }
  @media (min-width: 992px) {
    body.tax-product_cat #content {
      background-attachment: fixed; } }

body.tax-product_cat.templemans-type #content {
  background-image: url(../images/Templemans-t.png);
  background-position-x: right;
  background-position-y: 200px; }

#content {
  padding-top: 40px; }

.archive-item {
  border: 2px solid #276069;
  position: relative;
  background: #ddd;
  padding-top: 100%; }
  .archive-item a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
    .archive-item a:after {
      content: 'View now';
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #276069;
      -webkit-transition: 0.4s opacity ease;
      transition: 0.4s opacity ease;
      z-index: 200;
      text-align: center;
      padding-top: 40%;
      color: #fff;
      text-transform: uppercase; }
    .archive-item a:hover:after {
      opacity: 0.9; }
    .archive-item a img {
      height: 100%; }
  .archive-item .archive-label {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 20px;
    z-index: 300; }
    .archive-item .archive-label h3 {
      position: relative;
      text-transform: uppercase;
      padding: 10px;
      color: #434343;
      text-align: center;
      background: white;
      width: auto;
      display: inline-block;
      max-width: 90%;
      font-size: 18px;
      font-weight: bold; }
      @media (min-width: 1400px) {
        .archive-item .archive-label h3 {
          padding: 10px 30px; } }

.modal button.close {
  background: none;
  border: 0; }

.woocommerce span.onsale {
  background-color: #276069; }

.woocommerce-breadcrumb {
  font-size: 1rem;
  padding-top: 15px;
  color: #777;
  margin-bottom: 16px; }
  .woocommerce-breadcrumb a {
    color: #777;
    font-weight: 500;
    line-height: 1.7; }
    .woocommerce-breadcrumb a:hover {
      color: #be6c13; }

select#category-filter {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px 15px;
  border: 3px solid #276069; }
  @media (min-width: 576px) {
    select#category-filter {
      margin-left: 1em;
      width: auto; } }

.templemans-type select#category-filter {
  border: 3px solid #6d88a3; }

.templemans-type .archive-item a:after {
  background: #6d88a3; }

.section-spacing {
  margin-top: 7rem;
  margin-bottom: 7rem; }

.woocommerce button.button.single_add_to_cart_button {
  background-color: #000;
  color: #fff;
  border-radius: 0;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 22px;
  padding: 10px 45px;
  float: none !important; }
  .woocommerce button.button.single_add_to_cart_button.disabled, .woocommerce button.button.single_add_to_cart_button.disabled:hover {
    background-color: black; }
  .woocommerce button.button.single_add_to_cart_button:hover, .woocommerce button.button.single_add_to_cart_button:active {
    background-color: #353535; }

.btn-highlight {
  overflow: hidden;
  position: relative; }
  .btn-highlight span {
    z-index: 20; }
  .btn-highlight:after {
    background: #fff;
    content: "";
    height: 155px;
    left: -75px;
    opacity: .1;
    position: absolute;
    top: -50px;
    -webkit-transform: rotate(35deg);
            transform: rotate(35deg);
    -webkit-transition: all 850ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 850ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: 1; }

.btn-highlight:hover:after {
  left: 120%;
  -webkit-transition: all 850ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 850ms cubic-bezier(0.19, 1, 0.22, 1); }

.btn-cta {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 0.75rem;
     -moz-column-gap: 0.75rem;
          column-gap: 0.75rem;
  color: #fff !important;
  background-color: #6d88a3;
  padding: 0.5rem 1rem;
  font-family: "Roboto", sans-seirf;
  font-weight: 400;
  font-size: 1rem;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  border-radius: 0;
  border: none; }
  .btn-cta:hover {
    background-color: #556f88; }
  .btn-cta > svg {
    font-size: 1.25rem; }
  .btn-cta.btn-light {
    background-color: #fff;
    color: #1f364e !important; }
    .btn-cta.btn-light:hover {
      background-color: #f5f5f5; }
  .btn-cta.btn-outline {
    background-color: #fff;
    color: #1f364e !important;
    border: 1px solid #6d88a3; }
    .btn-cta.btn-outline:hover {
      background-color: #f5f5f5; }

.share-container {
  margin-bottom: 2em;
  text-align: center; }

.contain {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-left: var(--contain-padding);
  padding-right: var(--contain-padding);
  width: 1600px; }

html {
  --contain-margin: calc(
    (
        100vw - var(--scrollbar-width, 0.5rem) - 1600px + (var(
                --contain-padding
              ) * 2)
      ) / 2
  );
  --contain-padding: 2rem; }

.template-portal-new .hero-section {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 80vh;
  background: #fffcf2;
  z-index: -1;
  min-height: 500px; }
  .template-portal-new .hero-section .hero-wrapper {
    position: fixed;
    width: 100%;
    z-index: 2;
    -ms-flex-item-align: center;
        align-self: center; }
  .template-portal-new .hero-section .media-wrapper {
    width: 100%;
    height: 100%;
    z-index: 5; }
    .template-portal-new .hero-section .media-wrapper img,
    .template-portal-new .hero-section .media-wrapper video {
      position: absolute;
      top: 0;
      left: 0;
      -o-object-fit: cover;
         object-fit: cover;
      height: 100%;
      width: 100%; }
  .template-portal-new .hero-section #hero-banner {
    display: block;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.5rem 0;
    text-align: center;
    z-index: 9;
    color: #fff; }
  .template-portal-new .hero-section .hidden {
    display: none; }
  .template-portal-new .hero-section .show {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    overflow: hidden; }
  .template-portal-new .hero-section .banner-hidden {
    display: none; }
  .template-portal-new .hero-section .banner-show {
    display: block;
    width: 100%;
    z-index: 8; }
  .template-portal-new .hero-section .text-wrapper {
    padding: 0 16rem;
    text-align: center;
    margin-top: -4rem; }
    @media (max-width: 1399.98px) {
      .template-portal-new .hero-section .text-wrapper {
        padding: 6rem; } }
    @media (max-width: 1199.98px) {
      .template-portal-new .hero-section .text-wrapper {
        padding: 4rem; } }
    @media (max-width: 991.98px) {
      .template-portal-new .hero-section .text-wrapper {
        padding: 0; } }
    .template-portal-new .hero-section .text-wrapper h1 {
      font-size: 3.5rem;
      line-height: 3.5rem;
      color: #276069; }
      @media (max-width: 991.98px) {
        .template-portal-new .hero-section .text-wrapper h1 {
          font-size: 2.75rem;
          line-height: 2.75rem; } }
      @media (max-width: 575.98px) {
        .template-portal-new .hero-section .text-wrapper h1 {
          font-size: 2.25rem;
          line-height: 2.25rem; } }
    .template-portal-new .hero-section .text-wrapper p {
      font-weight: 200;
      color: #276069;
      font-size: 1.15rem;
      padding-top: 1rem; }
      @media (max-width: 575.98px) {
        .template-portal-new .hero-section .text-wrapper p {
          font-size: 1rem; } }

.template-portal-new .cards-section {
  background-color: #414141;
  z-index: 9; }
  .template-portal-new .cards-section .card-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    top: -7rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: 9;
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem; }
    @media (max-width: 991.98px) {
      .template-portal-new .cards-section .card-row {
        row-gap: 1rem; } }
    .template-portal-new .cards-section .card-row .saac-card {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      position: relative;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      grid-template-rows: auto 1fr auto;
      padding: 4rem 3rem 3rem 3rem;
      color: #fff;
      text-align: center;
      width: calc(33.33% - 20px);
      cursor: pointer;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      @media (max-width: 1199.98px) {
        .template-portal-new .cards-section .card-row .saac-card {
          padding: 3rem 1.5rem 2rem 1.5rem; } }
      @media (max-width: 991.98px) {
        .template-portal-new .cards-section .card-row .saac-card {
          width: calc( 50% - 20px);
          /* Adjust width of each card for 2 cards per row */ } }
      @media (max-width: 767.98px) {
        .template-portal-new .cards-section .card-row .saac-card {
          width: calc( 100% - 200px);
          /* Adjust width of each card for 1 card per row */ } }
      @media (max-width: 600px) {
        .template-portal-new .cards-section .card-row .saac-card {
          width: calc( 100% - 20px);
          /* Adjust width of each card for 1 card per row */ } }
      @media (max-width: 575.98px) {
        .template-portal-new .cards-section .card-row .saac-card {
          width: calc( 100% - 20px);
          /* Adjust width of each card for 1 card per row */ } }
      .template-portal-new .cards-section .card-row .saac-card .image-wrapper {
        height: 75px; }
        .template-portal-new .cards-section .card-row .saac-card .image-wrapper img {
          width: 100%;
          -o-object-fit: cover;
             object-fit: cover;
          -o-object-position: center;
             object-position: center;
          overflow: none;
          padding: 0 2rem; }
      .template-portal-new .cards-section .card-row .saac-card p {
        padding-top: 5rem;
        font-weight: 200;
        font-size: 1.15rem; }
        @media (max-width: 767.98px) {
          .template-portal-new .cards-section .card-row .saac-card p {
            font-size: 1rem; } }
        @media (max-width: 500px) {
          .template-portal-new .cards-section .card-row .saac-card p {
            padding-top: 2rem;
            font-size: 1rem; } }
      .template-portal-new .cards-section .card-row .saac-card a {
        color: #fff;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        font-weight: 200;
        justify-self: end;
        font-size: 1.35rem; }
        @media (max-width: 767.98px) {
          .template-portal-new .cards-section .card-row .saac-card a {
            font-size: 1.15rem; } }
      .template-portal-new .cards-section .card-row .saac-card .card-btn {
        display: block;
        opacity: 0;
        background-color: #fff;
        color: #414141;
        font-size: 1rem;
        margin: 0 auto;
        border-radius: 5px;
        padding: 0.25rem 0.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        margin-top: -1rem; }
    .template-portal-new .cards-section .card-row .saac-card:hover .card-btn {
      opacity: 1;
      margin-top: 0; }

.template-portal-new .instagram-section {
  padding: 0 0 8rem 0;
  margin-top: -2.5rem;
  background: #414141;
  z-index: 9; }
  @media (max-width: 575.98px) {
    .template-portal-new .instagram-section {
      padding: 0 0 6rem 0; } }
  .template-portal-new .instagram-section .insta-title {
    text-align: center;
    color: #fff;
    font-size: 1.25rem;
    margin-bottom: 4rem; }
    @media (max-width: 767.98px) {
      .template-portal-new .instagram-section .insta-title {
        font-size: 1rem;
        margin-bottom: 2rem; } }
  .template-portal-new .instagram-section #sb_instagram #sbi_images.sbi_carousel {
    padding: 12px 6px !important; }
  .template-portal-new .instagram-section .sbi-owl-dot span {
    background: #cccccc; }
  .template-portal-new .instagram-section .sbi-owl-dot.active span {
    background: #fff; }

.template-portal-new .icon-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .template-portal-new .icon-wrapper:after {
    height: 2px;
    width: calc(100% - 2rem);
    background: #595959;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: absolute;
    z-index: 1; }
    @media (min-width: 576px) {
      .template-portal-new .icon-wrapper:after {
        content: ""; } }
  .template-portal-new .icon-wrapper .svg-background {
    width: 8rem;
    height: 4rem;
    background: #414141;
    z-index: 3; }
  .template-portal-new .icon-wrapper .svg-holder {
    z-index: 3;
    padding: 0 2rem; }
    .template-portal-new .icon-wrapper .svg-holder .svg-wrapper {
      height: 4rem;
      width: 4rem;
      color: #414141;
      background: #fff;
      -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
              filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      font-size: 2.5rem; }

.template-portal-new .button-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .template-portal-new .button-wrapper:after {
    height: 2px;
    width: calc(100% - 2rem);
    background: #595959;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: absolute;
    z-index: 1; }
    @media (min-width: 576px) {
      .template-portal-new .button-wrapper:after {
        content: ""; } }
  .template-portal-new .button-wrapper .button-background {
    padding: 0 1rem;
    background: #414141;
    z-index: 3; }
  .template-portal-new .button-wrapper a {
    font-size: 1.25rem;
    font-weight: 200;
    z-index: 4;
    background-color: #fff;
    color: #414141;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    text-decoration: underline;
    text-decoration-thickness: 1px; }
    @media (max-width: 575.98px) {
      .template-portal-new .button-wrapper a {
        font-size: 1rem; } }

.portal-footer {
  background-color: #fffcf2;
  color: #414141;
  padding-top: 6rem;
  padding-bottom: 4.5rem;
  position: relative;
  z-index: 9; }
  @media (max-width: 575.98px) {
    .portal-footer {
      padding-top: 4rem; } }
  .portal-footer .latest-news {
    text-align: center;
    position: relative; }
    .portal-footer .latest-news p {
      font-size: 1.25rem;
      margin-bottom: 0; }
    @media (min-width: 992px) {
      .portal-footer .latest-news {
        text-align: left; } }
    .portal-footer .latest-news .post-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .portal-footer .latest-news .post-wrapper p {
        font-size: 1.25rem; }
      .portal-footer .latest-news .post-wrapper a {
        font-size: 1.25rem;
        margin-left: 1rem;
        color: #347780;
        text-decoration: underline;
        text-decoration-thickness: 1px; }
      @media (max-width: 991.98px) {
        .portal-footer .latest-news .post-wrapper {
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center; } }
      @media (max-width: 767.98px) {
        .portal-footer .latest-news .post-wrapper {
          display: block; } }
  .portal-footer a {
    color: #f8f9fa;
    text-decoration: none; }
  .portal-footer .social-media {
    margin-top: 30px; }
    @media (max-width: 767.98px) {
      .portal-footer .social-media {
        width: 50%;
        display: inline-block; } }
    @media (max-width: 620px) {
      .portal-footer .social-media {
        width: 100%; } }
    .portal-footer .social-media a {
      font-size: 20px;
      background: #fff;
      border-radius: 50px;
      display: inline-block;
      width: 50px;
      height: 50px;
      text-align: center;
      margin-left: 10px;
      -webkit-transition: 0.2s;
      transition: 0.2s; }
      @media (max-width: 620px) {
        .portal-footer .social-media a:first-child {
          margin-left: 0; } }
      .portal-footer .social-media a:hover {
        -webkit-transition: 0.2s;
        transition: 0.2s;
        opacity: 0.8; }
      .portal-footer .social-media a svg {
        color: #276069;
        font-size: 25px;
        top: 11px;
        position: relative; }
    @media (min-width: 768px) {
      .portal-footer .social-media {
        text-align: right; } }
  .portal-footer .footer-links {
    margin: 30px 0;
    font-size: 18px;
    float: right;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    text-decoration: none; }
    @media (max-width: 767.98px) {
      .portal-footer .footer-links {
        width: 50%;
        display: inline-block; } }
    @media (max-width: 620px) {
      .portal-footer .footer-links {
        width: 100%; } }
    .portal-footer .footer-links a {
      margin-left: 30px;
      color: #414141;
      -webkit-transition: 0.2s;
      transition: 0.2s; }
      @media (max-width: 620px) {
        .portal-footer .footer-links a:first-child {
          margin-left: 0; } }
      .portal-footer .footer-links a:hover {
        -webkit-transition: 0.2s;
        transition: 0.2s;
        opacity: 0.8; }
    .portal-footer .footer-links svg {
      margin-right: 5px; }
  .portal-footer .payment-icons {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 1.5rem 2rem 0 0; }
    .portal-footer .payment-icons img {
      background-color: #fffcf2;
      height: 50px; }
    .portal-footer .payment-icons img:last-child {
      background-color: #fffcf2;
      height: 30px; }
    @media (max-width: 991.98px) {
      .portal-footer .payment-icons {
        position: relative;
        text-align: center; } }
  .portal-footer .footer-logo img {
    max-width: 280px;
    width: 100%; }
  .portal-footer .footer-logo--templemans img {
    max-width: 210px; }
    @media (min-width: 768px) {
      .portal-footer .footer-logo--templemans img {
        margin-left: 30px; } }
  .portal-footer #copytext {
    margin: 30px 0;
    font-size: 16px;
    font-weight: 500; }
  .portal-footer #copyaddress {
    margin-bottom: 0;
    font-size: 16px; }
    .portal-footer #copyaddress a {
      color: #414141; }
      .portal-footer #copyaddress a:hover {
        text-decoration: underline; }

.footer-img {
  background: #fffcf2;
  text-align: center;
  position: relative;
  border-radius: 10px;
  padding: 10px; }
  .footer-img img {
    height: 100px;
    width: auto;
    margin: 10px; }
  @media (min-width: 992px) {
    .footer-img {
      text-align: right; }
      .footer-img img {
        height: 90px;
        margin: 0 0 1rem 2rem; }
      .footer-img img:first-child {
        margin: 0 0 1rem 0; } }

.copyright {
  text-align: center; }
  @media (min-width: 992px) {
    .copyright {
      text-align: left; } }

.footer-credits {
  background: #414141; }

.credit {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #414141; }
  @media (max-width: 767.98px) {
    .credit {
      padding-bottom: 40px; } }
  .credit #menu-footer-menu {
    margin-top: 20px;
    padding-left: 0px; }
    .credit #menu-footer-menu li {
      margin-right: 0 !important; }
      .credit #menu-footer-menu li:after {
        content: " | ";
        margin: 0 0.5rem;
        color: #cccccc; }
      .credit #menu-footer-menu li:last-child:after {
        content: unset !important;
        margin: 0; }
      .credit #menu-footer-menu li:last-child {
        margin-right: 0 !important; }
      .credit #menu-footer-menu li a {
        color: #cccccc;
        font-size: 14px;
        font-weight: 400;
        text-decoration: none;
        text-transform: none !important;
        -webkit-transition: 0.2s;
        transition: 0.2s; }
        .credit #menu-footer-menu li a:hover {
          -webkit-transition: 0.2s;
          transition: 0.2s;
          color: #fff; }
  .credit #b2 {
    color: #cccccc;
    font-size: 14px; }
    .credit #b2 span {
      position: relative;
      top: 13px; }
    .credit #b2 img {
      width: 70px;
      margin-left: 10px;
      margin-top: 20px; }

.templemans-type .site-footer {
  background-color: #6d88a3; }

.templemans-type #scroll-to-top {
  background: #6d88a3 !important; }

.portal-fade-in {
  -webkit-animation: portalFadeIn 1s;
          animation: portalFadeIn 1s; }

@-webkit-keyframes portalFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes portalFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.portal-fade-out {
  -webkit-animation: portalFadeOut 1s;
          animation: portalFadeOut 1s; }

@-webkit-keyframes portalFadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes portalFadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.bottom-header .fixed-container-padding {
  width: 100%; }

.main_nav {
  display: block;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 0px;
  margin-bottom: 0px;
  /*    width: 100%;*/ }
  @media (min-width: 768px) {
    .main_nav {
      display: inline-block;
      width: 100%; } }
  .main_nav li {
    list-style: none;
    display: block; }
    .main_nav li:last-child {
      margin-right: 0; }
    @media (min-width: 768px) {
      .main_nav li {
        display: inline-block;
        margin-right: 40px; } }
    .main_nav li a {
      display: block;
      position: relative;
      text-decoration: none;
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      color: #000;
      padding-top: 10px;
      padding-bottom: 10px;
      -webkit-transition: 0.2s;
      transition: 0.2s;
      border-bottom: 1px solid #d9d9d9; }
      .main_nav li a:hover {
        -webkit-transition: 0.2s;
        transition: 0.2s;
        color: #be6c13; }
      @media (min-width: 768px) {
        .main_nav li a {
          padding-top: 0;
          padding-bottom: 0;
          border-bottom: none;
          color: #fff; }
          .main_nav li a:after {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 3px;
            background: rgba(0, 0, 0, 0.1);
            content: '';
            opacity: 0;
            -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
            transition: opacity 0.3s, -webkit-transform 0.3s;
            transition: opacity 0.3s, transform 0.3s;
            transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
            -webkit-transform: translateY(10px);
            transform: translateY(10px); }
          .main_nav li a:hover::after {
            opacity: 1;
            -webkit-transform: translateY(0px);
            transform: translateY(0px); } }
    .main_nav li:last-child a {
      margin-right: 0px; }

.saac-type .navbar-toggler {
  top: 20px;
  max-width: 75px; }

.saac-type .top-header .top-right a {
  border: 0 none; }

.saac-type .nav-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: justify;
      align-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.saac-type .main_nav {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  padding-bottom: 0;
  padding-top: 0; }
  @media (min-width: 768px) {
    .saac-type .main_nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .saac-type .main_nav li {
      display: inline-block;
      width: 30%; }
      .saac-type .main_nav li a {
        padding-top: 8px; } }
  @media (min-width: 768px) {
    .saac-type .main_nav li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      margin-right: 10px; } }
  @media (min-width: 1200px) {
    .saac-type .main_nav li {
      margin-right: 30px; } }
  @media (min-width: 1400px) {
    .saac-type .main_nav li {
      margin-right: 40px; } }
  .saac-type .main_nav li a {
    font-weight: normal;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    @media (min-width: 768px) {
      .saac-type .main_nav li a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        border-bottom: 3px solid transparent;
        padding-top: 8px; } }
    @media (min-width: 768px) {
      .saac-type .main_nav li a.active, .saac-type .main_nav li a:hover {
        border-bottom: 3px solid #fff;
        color: #fff; } }

@media (min-width: 768px) {
  .saac-type .secondary-links-container {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0; } }

.saac-type .secondary-links-container > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center; }
  @media (max-width: 767.98px) {
    .saac-type .secondary-links-container > div {
      padding: 0 5px 0 0 !important;
      max-width: 54%; }
      .saac-type .secondary-links-container > div > * {
        margin-top: 15px; }
      .saac-type .secondary-links-container > div:last-child {
        padding: 0 0 0 5px !important;
        max-width: 46%; } }
  @media (min-width: 768px) {
    .saac-type .secondary-links-container > div {
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      padding: 0 !important; } }
  .saac-type .secondary-links-container > div a {
    width: 100%; }
    @media (max-width: 767.98px) {
      .saac-type .secondary-links-container > div a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-line-pack: start;
            align-content: flex-start; } }
  @media (max-width: 767.98px) {
    .saac-type .secondary-links-container > div img {
      width: auto;
      -o-object-fit: contain;
         object-fit: contain;
      height: 75px;
      margin: 0; } }

.saac-type #navbarMain {
  border-top: 1px solid #347780;
  border-bottom: 1px solid #347780;
  margin-top: 25px; }

.templemans-type .main_nav li.active {
  border-bottom: 3px solid #B7DBFF; }

.templemans-type .main_nav li a {
  color: #fff; }
  .templemans-type .main_nav li a:hover {
    color: #000; }

.flex-direction-nav {
  list-style: none;
  padding: 0; }

/*
Pagination
 */
.nav-links {
  text-align: center;
  margin: 0 auto; }
  .nav-links .page-numbers {
    font-size: 18px;
    padding: 0 4px; }
  .nav-links a.prev, .nav-links a.next {
    /*       @extend .btn;
        @extend .btn-primary;*/ }
  .nav-links a.prev {
    float: left; }
  .nav-links a.next {
    float: right; }
  @media (min-width: 576px) {
    .nav-links {
      max-width: 300px; } }

.woocommerce .woocommerce-breadcrumb {
  padding-top: 15px; }
  .woocommerce .woocommerce-breadcrumb a:hover {
    color: #be6c13; }

.woocommerce ul.products {
  margin-bottom: 0px; }
  .woocommerce ul.products li.product a:after {
    content: 'View Product';
    display: block;
    position: absolute;
    top: 40%;
    width: auto;
    width: 96%;
    left: 2%;
    margin: 0 auto;
    padding: 20px;
    opacity: 0;
    text-transform: uppercase;
    background: rgba(255, 255, 255, 0.93);
    text-align: center;
    font-family: "Roboto", sans-seirf;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease; }
  .woocommerce ul.products li.product a:hover::after {
    opacity: 1;
    top: 30%; }
  .woocommerce ul.products li.product .woocommerce-loop-product__title {
    font-size: 18px;
    font-weight: bold;
    padding: 5px 20px;
    margin-top: 15px; }
  .woocommerce ul.products li.product .price {
    color: #000;
    font-weight: bold;
    padding: 0 20px; }

a {
  text-decoration: none;
  -webkit-transition: 0.2s;
  transition: 0.2s; }
  a:hover {
    text-decoration: none;
    -webkit-transition: 0.2s;
    transition: 0.2s; }

p,
a {
  font-weight: 500;
  line-height: 1.7; }

header {
  /*.feefo-rating-header {
    display: none;
    float: right;
    max-width: 140px;

    box-shadow: none;
    transition: 0.3s all ease;
    img {
    }
    span {
      display: block;
      color: #fff;
      font-size: 11px;
      text-align: center;
      padding: 0 7px;
    }
    &:hover {
      margin-top: -10px;
      box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1);
    }
  }*/ }
  header .navbar-toggler {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 20%; }
    @media (min-width: 768px) {
      header .navbar-toggler {
        display: none; } }
  header .top-header {
    padding-top: 0px;
    padding-bottom: 0px; }
  header .top-header {
    background-color: #276069;
    color: #fff;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; }
    @media (min-width: 768px) {
      header .top-header {
        max-width: 100%; } }
    header .top-header .top-right {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 100%;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      @media (min-width: 359px) {
        header .top-header .top-right {
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between; } }
      @media (min-width: 992px) {
        header .top-header .top-right {
          float: right; } }
      header .top-header .top-right a {
        vertical-align: middle;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        padding: 0px 20px;
        color: #fff;
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
        text-decoration: none;
        -webkit-transition: 0.2s;
        transition: 0.2s;
        width: 100%;
        /* @include media-breakpoint-up(xxl){
          font-size: 1.2vw;
        } */ }
        @media (min-width: 359px) {
          header .top-header .top-right a {
            width: auto; } }
        @media (min-width: 768px) {
          header .top-header .top-right a {
            border-right: 1px solid #347780; } }
        @media (min-width: 1200px) {
          header .top-header .top-right a {
            width: auto;
            padding-top: 0;
            padding-bottom: 0; } }
        header .top-header .top-right a .svg-inline--fa {
          margin-right: 5px;
          -webkit-transition: 0.2s;
          transition: 0.2s; }
        @media (max-width: 1199.98px) {
          header .top-header .top-right a:nth-last-child(3) {
            border-right: none; } }
        header .top-header .top-right a:last-child {
          border-right: none !important; }
        header .top-header .top-right a:hover {
          -webkit-transition: 0.2s;
          transition: 0.2s;
          background: #347780; }
          @media (min-width: 1400px) {
            header .top-header .top-right a:hover .svg-inline--fa {
              color: #4397a2; } }
  header .brand {
    max-width: 80%;
    margin-bottom: 15px; }
    header .brand--mobile {
      -webkit-filter: invert(1);
              filter: invert(1); }
    @media (min-width: 768px) {
      header .brand {
        margin-bottom: 0px;
        max-width: 100%; } }
    header .brand a {
      display: inline-block; }
  header .mobile-nav-elements {
    padding-top: 15px;
    position: relative;
    width: 100%; }
  header .mobile-header-tools {
    width: 100%;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    -ms-flex-line-pack: stretch;
        align-content: stretch;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
    header .mobile-header-tools .my-account,
    header .mobile-header-tools .my-cart {
      text-align: center; }
      header .mobile-header-tools .my-account span,
      header .mobile-header-tools .my-cart span {
        width: 100%; }
        header .mobile-header-tools .my-account span svg,
        header .mobile-header-tools .my-cart span svg {
          font-size: 20px; }
          @media (min-width: 359px) {
            header .mobile-header-tools .my-account span svg,
            header .mobile-header-tools .my-cart span svg {
              font-size: 25px; } }
    header .mobile-header-tools .my-cart span {
      font-size: 15px; }
      header .mobile-header-tools .my-cart span.no-items {
        display: none; }
        @media (min-width: 400px) {
          header .mobile-header-tools .my-cart span.no-items {
            display: initial; } }
    header .mobile-header-tools a {
      -ms-flex-item-align: stretch;
          align-self: stretch;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      font-size: 14px;
      color: #373f49; }
      header .mobile-header-tools a span {
        -ms-flex-item-align: center;
            align-self: center; }
      header .mobile-header-tools a [class*="fa"] {
        margin: 5px 5px 0px; }
    header .mobile-header-tools a,
    header .mobile-header-tools form {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1; }
    header .mobile-header-tools a + a,
    header .mobile-header-tools form {
      border-left: 1px solid #000; }
      @media (max-width: 320px) {
        header .mobile-header-tools a + a,
        header .mobile-header-tools form {
          border-left: none; } }
      header .mobile-header-tools a + a input,
      header .mobile-header-tools a + a button,
      header .mobile-header-tools form input,
      header .mobile-header-tools form button {
        border: 0 none;
        color: #373f49; }
    header .mobile-header-tools form {
      padding-left: 20px;
      border-right: 1px solid #fff;
      border-left: 0px !important; }
      header .mobile-header-tools form .search-input {
        border-radius: 0px; }
      header .mobile-header-tools form svg {
        color: #fff; }
  header .bottom-header {
    background: #fff;
    padding-top: 15px;
    padding-bottom: 5px;
    border-bottom: 2px solid #ccc;
    -webkit-box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.1); }
    @media (min-width: 768px) {
      header .bottom-header {
        padding-bottom: 25px;
        background: #276069; } }
    @media (min-width: 768px) {
      header .bottom-header {
        padding-top: 25px; } }
    header .bottom-header #site_logo img {
      max-height: 108px; }
      @media (min-width: 992px) {
        header .bottom-header #site_logo img {
          max-height: none; } }
    header .bottom-header #site_logo__secondary {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-line-pack: end;
          align-content: flex-end; }
      @media (min-width: 768px) {
        header .bottom-header #site_logo__secondary img {
          -webkit-filter: brightness(200);
                  filter: brightness(200);
          margin: 10px auto 0;
          max-width: 148px; } }
    header .bottom-header form {
      background: #fff;
      color: #000; }
      @media (min-width: 768px) {
        header .bottom-header form {
          background: #347780;
          color: #fff;
          padding-top: 7px;
          padding-bottom: 7px; } }
      @media (min-width: 1200px) {
        header .bottom-header form {
          padding-top: 0;
          padding-bottom: 0; } }
      header .bottom-header form label {
        margin-bottom: 0px; }
      header .bottom-header form .search-input {
        /* @include media-breakpoint-up(xl){
          font-size: 1.33vw
        }
        @include media-breakpoint-up(xxl){
          font-size: 1.2vw;
        }
        @include media-breakpoint-up(xxxl){
          font-size: 28px;
        } */ }
        @media (min-width: 768px) {
          header .bottom-header form .search-input {
            padding: 30px 20px;
            background: transparent;
            outline: none;
            border: none;
            -webkit-box-shadow: none;
                    box-shadow: none;
            color: #fff;
            font-size: 18px; } }
      @media (min-width: 768px) {
        header .bottom-header form ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #fff; }
        header .bottom-header form ::-moz-placeholder {
          /* Firefox 19+ */
          color: #fff; }
        header .bottom-header form :-ms-input-placeholder {
          /* IE 10+ */
          color: #fff; }
        header .bottom-header form :-moz-placeholder {
          /* Firefox 18- */
          color: #fff; } }
      header .bottom-header form button {
        outline: none;
        border: none;
        color: #fff;
        -webkit-transition: 0.2s;
        transition: 0.2s;
        font-size: 18px;
        -webkit-box-shadow: none;
                box-shadow: none;
        opacity: 1; }
        @media (min-width: 1200px) {
          header .bottom-header form button {
            font-size: 1.33vw; } }
        @media (min-width: 1400px) {
          header .bottom-header form button {
            font-size: 1.2vw; } }
        @media (min-width: 1600px) {
          header .bottom-header form button {
            font-size: 28px; } }
        header .bottom-header form button:hover {
          -webkit-transition: 0.2s;
          transition: 0.2s;
          background: #347780;
          opacity: 0.8; }
  header #templemans-text {
    padding: 0; }
    header #templemans-text.mobile {
      padding-bottom: 20px; }
    @media (min-width: 576px) {
      header #templemans-text {
        padding-left: 25px;
        padding-right: 25px; } }
    @media (min-width: 768px) {
      header #templemans-text.mobile {
        padding-bottom: 0px; } }
    header #templemans-text p {
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 14px; }

body.page-template-template-homepage .bottom-header {
  border-bottom: none;
  -webkit-box-shadow: none;
          box-shadow: none; }

.templemans-type header {
  -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px; }
  .templemans-type header .brand {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 0;
    width: 300px; }
    @media (min-width: 768px) {
      .templemans-type header .brand {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        width: auto; } }
    .templemans-type header .brand a {
      display: block; }
    .templemans-type header .brand img {
      width: 100%; }
  .templemans-type header #templemans-text .other-site p {
    color: #fff;
    text-align: right;
    padding-top: 7px;
    padding-right: 7px;
    font-weight: 400; }
    @media (min-width: 768px) {
      .templemans-type header #templemans-text .other-site p {
        text-align: left;
        padding-top: 0px;
        padding-right: 0px; } }
  .templemans-type header #templemans-text .other-site a {
    width: 45%; }
    @media (min-width: 768px) {
      .templemans-type header #templemans-text .other-site a {
        width: 100%; } }
  .templemans-type header #templemans-text .other-site img {
    display: inline-block;
    padding-right: 0;
    margin-bottom: 10px;
    padding-left: 10px; }
    @media (min-width: 768px) {
      .templemans-type header #templemans-text .other-site img {
        padding-left: 0;
        margin-bottom: 0; } }
  .templemans-type header .mobile-header-tools {
    border-color: #fff; }
    .templemans-type header .mobile-header-tools a {
      border-color: #fff;
      font-size: 20px; }
    .templemans-type header .mobile-header-tools a + a,
    .templemans-type header .mobile-header-tools form {
      border-left: 1px solid #fff; }
      @media (max-width: 320px) {
        .templemans-type header .mobile-header-tools a + a,
        .templemans-type header .mobile-header-tools form {
          border-left: none; } }

.templemans-type .top-header {
  padding-top: 15px;
  padding-bottom: 15px;
  background: #6d88a3; }
  .templemans-type .top-header_right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .templemans-type .top-header_right .top-right {
      float: none;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1; }
      @media (max-width: 991.98px) {
        .templemans-type .top-header_right .top-right {
          display: grid;
          grid-template-columns: 1fr 1fr; } }
      .templemans-type .top-header_right .top-right a {
        color: #fff;
        border-right: none; }
        @media (max-width: 991.98px) {
          .templemans-type .top-header_right .top-right a {
            font-size: 0.75rem; } }
        .templemans-type .top-header_right .top-right a:hover {
          -webkit-transition: 0.2s;
          transition: 0.2s;
          background: #5e7893; }
          .templemans-type .top-header_right .top-right a:hover .svg-inline--fa {
            color: #334251; }
    .templemans-type .top-header_right .feefo-rating-header {
      -ms-flex-negative: 0;
          flex-shrink: 0;
      display: none;
      width: 130px; }
      @media (min-width: 768px) {
        .templemans-type .top-header_right .feefo-rating-header {
          display: block; } }

.templemans-type .bottom-header {
  background: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-bottom: none; }
  @media (min-width: 768px) {
    .templemans-type .bottom-header {
      padding-top: 0px;
      padding-bottom: 0px; }
      .templemans-type .bottom-header .fixed-container-padding {
        border: 1px solid #fff;
        border-bottom: none; }
      .templemans-type .bottom-header #site_logo {
        margin-left: -1px;
        margin-top: -1px; }
      .templemans-type .bottom-header .other-site {
        margin-right: 25px; }
      .templemans-type .bottom-header .main_nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: 40px;
        padding: 0; }
        .templemans-type .bottom-header .main_nav li {
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          margin: 0; }
          .templemans-type .bottom-header .main_nav li a {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            color: #1f364e;
            padding-bottom: 0;
            text-transform: none;
            font-weight: 400;
            font-size: 18px; }
            .templemans-type .bottom-header .main_nav li a:hover {
              color: #1f364e; } }
  @media (max-width: 991.98px) {
    .templemans-type .bottom-header {
      display: none; } }
  @media (min-width: 1200px) {
    .templemans-type .bottom-header {
      /*.feefo-rating-header {
        display: block;
      }*/ } }
  .templemans-type .bottom-header form {
    background: #fff;
    color: #000; }
    @media (min-width: 768px) {
      .templemans-type .bottom-header form {
        background: #d6dbdf;
        color: #1f364e;
        padding-top: 7px;
        padding-bottom: 7px; } }
    @media (min-width: 1200px) {
      .templemans-type .bottom-header form {
        padding-top: 0;
        padding-bottom: 0; } }
    .templemans-type .bottom-header form label {
      margin-bottom: 0px; }
    .templemans-type .bottom-header form .search-input {
      /* @include media-breakpoint-up(xl){
          font-size: 1.33vw
        }
        @include media-breakpoint-up(xxl){
          font-size: 1.2vw;
        }
        @include media-breakpoint-up(xxxl){
          font-size: 28px;
        } */ }
      @media (min-width: 768px) {
        .templemans-type .bottom-header form .search-input {
          padding: 30px 20px;
          background: transparent;
          outline: none;
          border: none;
          -webkit-box-shadow: none;
                  box-shadow: none;
          color: #1f364e;
          font-size: 18px; } }
    @media (min-width: 768px) {
      .templemans-type .bottom-header form ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #1f364e; }
      .templemans-type .bottom-header form ::-moz-placeholder {
        /* Firefox 19+ */
        color: #1f364e; }
      .templemans-type .bottom-header form :-ms-input-placeholder {
        /* IE 10+ */
        color: #1f364e; }
      .templemans-type .bottom-header form :-moz-placeholder {
        /* Firefox 18- */
        color: #1f364e; } }
    .templemans-type .bottom-header form button {
      outline: none;
      border: none;
      border-radius: 0;
      color: #1f364e;
      -webkit-transition: 0.2s;
      transition: 0.2s;
      font-size: 18px;
      -webkit-box-shadow: none;
              box-shadow: none;
      opacity: 1; }
      @media (min-width: 1200px) {
        .templemans-type .bottom-header form button {
          font-size: 1.33vw; } }
      @media (min-width: 1400px) {
        .templemans-type .bottom-header form button {
          font-size: 1.2vw; } }
      @media (min-width: 1600px) {
        .templemans-type .bottom-header form button {
          font-size: 28px; } }
      .templemans-type .bottom-header form button:hover {
        -webkit-transition: 0.2s;
        transition: 0.2s;
        background: #c4c9cd;
        opacity: 1; }

.templemans-type .mobile-bottom-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 25px;
  -webkit-column-gap: 25px;
     -moz-column-gap: 25px;
          column-gap: 25px; }
  @media (min-width: 768px) {
    .templemans-type .mobile-bottom-header {
      display: none; } }
  .templemans-type .mobile-bottom-header .other-site {
    margin-top: 0; }
    .templemans-type .mobile-bottom-header .other-site p {
      display: block;
      width: 100%;
      color: #000;
      font-weight: 500;
      float: none;
      font-size: 0.65rem;
      margin-bottom: 0; }
    .templemans-type .mobile-bottom-header .other-site a {
      width: 100%; }
    .templemans-type .mobile-bottom-header .other-site img {
      display: inline-block;
      padding-right: 0;
      margin-bottom: 10px;
      padding-left: 10px;
      mix-blend-mode: difference; }

.templemans-type .mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  padding-bottom: 3.625rem;
  padding-top: 1.5rem;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease; }
  .templemans-type .mobile-overlay.open {
    pointer-events: all;
    opacity: 1; }

.templemans-type .mobile-search {
  padding-left: 25px;
  padding-right: 25px;
  z-index: 1001; }

.templemans-type .mobile-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1002; }
  .templemans-type .mobile-menu_wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%; }
  @media (min-width: 768px) {
    .templemans-type .mobile-menu {
      display: none; } }
  .templemans-type .mobile-menu_logo {
    display: block;
    max-width: 13rem;
    margin-left: auto;
    margin-right: auto; }
    .templemans-type .mobile-menu_logo > img {
      width: 100%; }
  .templemans-type .mobile-menu_logo-wrapper {
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 1rem;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .templemans-type .mobile-menu_nav {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow: auto; }
    .templemans-type .mobile-menu_nav > li > a {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      border-bottom: rgba(99, 120, 148, 0.3) 1px solid;
      font-size: 1.2rem;
      font-family: "p22-underground", sans-serif;
      font-weight: bolder;
      color: #637894;
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 15px;
      padding-bottom: 15px;
      -webkit-transition: background-color 0.3s ease;
      transition: background-color 0.3s ease; }
      .templemans-type .mobile-menu_nav > li > a:hover {
        background: rgba(99, 120, 148, 0.1); }
      .templemans-type .mobile-menu_nav > li > a > span {
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1; }

.templemans-type .mobile-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  -webkit-box-shadow: rgba(99, 120, 148, 0.34) 0px 0px 15px;
          box-shadow: rgba(99, 120, 148, 0.34) 0px 0px 15px;
  z-index: 1003; }
  @media (min-width: 768px) {
    .templemans-type .mobile-bottom {
      display: none; } }
  .templemans-type .mobile-bottom_row {
    display: grid;
    grid-template-columns: repeat(4, 1fr); }
    .templemans-type .mobile-bottom_row .mobile-bottom_item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      text-align: center;
      row-gap: 0.2rem;
      font-size: 0.65rem;
      font-weight: 400;
      color: #1f364e;
      padding: 10px 0;
      cursor: pointer; }
      .templemans-type .mobile-bottom_row .mobile-bottom_item > svg {
        font-size: 1.125rem;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1; }

.templemans-type .main_nav {
  border-top: none; }
  @media (min-width: 768px) {
    .templemans-type .main_nav {
      padding-left: 20px;
      margin-top: 0px;
      padding-top: 25px; }
      .templemans-type .main_nav li a {
        padding-bottom: 15px;
        text-transform: uppercase; } }

.templemans-type .hero-slider .swiper-slide {
  background: #6d88a3; }

@media (min-width: 768px) {
  .templemans-type .hero-slider:before, .templemans-type .hero-slider:after {
    display: block;
    content: "";
    position: absolute;
    width: 1px;
    background: #fff;
    top: 0;
    bottom: 0;
    z-index: 200; }
  .templemans-type .hero-slider:before {
    left: 25px; }
  .templemans-type .hero-slider:after {
    right: 25px; } }

@media (min-width: 1200px) {
  .templemans-type .hero-slider:before {
    left: 130px; }
  .templemans-type .hero-slider:after {
    right: 130px; }
  .templemans-type .hero-slider .swiper-pagination {
    left: 145px; } }

.templemans-type .swiper-nav {
  display: none; }

.saac-type header .top-header {
  margin-top: 25px; }
  @media (max-width: 767.98px) {
    .saac-type header .top-header {
      margin-top: 0; }
      .saac-type header .top-header:before {
        content: '';
        background-color: #276069;
        width: 100%;
        height: 100px;
        position: absolute;
        bottom: 100%; } }

.saac-type header #templemans-text p {
  color: #000;
  /*@media(max-width:550px){
          text-align: center;
          width: 100%;
        } */ }
  @media (min-width: 768px) {
    .saac-type header #templemans-text p {
      color: #fff; } }

@media (min-width: 768px) {
  .saac-type header .feefo-rating-header img {
    max-width: 148px; } }

.saac-type header .search-form .input-group {
  border-left: 1px solid #000;
  border-right: 1px solid #000; }
  @media (min-width: 768px) {
    .saac-type header .search-form .input-group {
      border-left: 0px;
      border-right: 0px; } }
  .saac-type header .search-form .input-group input {
    border-right: 1px solid #000; }
    @media (min-width: 768px) {
      .saac-type header .search-form .input-group input {
        border-right: 0px; } }

.saac-type header .search-form .input-group-append svg {
  color: #000; }
  @media (min-width: 768px) {
    .saac-type header .search-form .input-group-append svg {
      color: #fff; } }

.saac-type .hero-slider .swiper-slide {
  background: #276069; }
  .saac-type .hero-slider .swiper-slide .hero-slider--slide-images {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    @media (min-width: 768px) {
      .saac-type .hero-slider .swiper-slide .hero-slider--slide-images.is-padded--1 {
        margin-top: -2rem;
        height: calc(35vw + 3rem); } }
    .saac-type .hero-slider .swiper-slide .hero-slider--slide-images > div {
      max-height: 100%; }
    @media (max-width: 991.98px) {
      .saac-type .hero-slider .swiper-slide .hero-slider--slide-images.image-count--1 > div, .saac-type .hero-slider .swiper-slide .hero-slider--slide-images.image-count--2 > div, .saac-type .hero-slider .swiper-slide .hero-slider--slide-images.image-count--3 > div {
        height: 340px; } }
    @media (max-width: 767.98px) {
      .saac-type .hero-slider .swiper-slide .hero-slider--slide-images.image-count--1 > div, .saac-type .hero-slider .swiper-slide .hero-slider--slide-images.image-count--2 > div, .saac-type .hero-slider .swiper-slide .hero-slider--slide-images.image-count--3 > div {
        height: 300px; } }
    @media (max-width: 991.98px) {
      .saac-type .hero-slider .swiper-slide .hero-slider--slide-images.image-count--4 > div, .saac-type .hero-slider .swiper-slide .hero-slider--slide-images.image-count--5 > div, .saac-type .hero-slider .swiper-slide .hero-slider--slide-images.image-count--6 > div {
        height: 170px; } }
    @media (max-width: 767.98px) {
      .saac-type .hero-slider .swiper-slide .hero-slider--slide-images.image-count--4 > div, .saac-type .hero-slider .swiper-slide .hero-slider--slide-images.image-count--5 > div, .saac-type .hero-slider .swiper-slide .hero-slider--slide-images.image-count--6 > div {
        height: 150px; } }
    @media (min-width: 768px) {
      .saac-type .hero-slider .swiper-slide .hero-slider--slide-images {
        height: 35vw; } }
    @media (max-width: 767.98px) {
      .saac-type .hero-slider .swiper-slide .hero-slider--slide-images > div {
        min-width: 33%; } }
    .saac-type .hero-slider .swiper-slide .hero-slider--slide-images > div[class*='p-2'] > div {
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
    .saac-type .hero-slider .swiper-slide .hero-slider--slide-images > div.no-box-shadow > div {
      -webkit-box-shadow: none;
              box-shadow: none; }
    .saac-type .hero-slider .swiper-slide .hero-slider--slide-images > div > div {
      width: 100%;
      height: 100%; }
    .saac-type .hero-slider .swiper-slide .hero-slider--slide-images > div img {
      height: 100%;
      width: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: center;
         object-position: center; }

.saac-type .hero-slider .swiper-slide-active .hero-text .hero-inner {
  margin-top: 25px; }

.saac-type .hero-slider .hero-text {
  position: relative; }
  .saac-type .hero-slider .hero-text .hero-inner {
    min-height: 200px;
    margin-top: 25px;
    -webkit-transition: none;
    transition: none;
    opacity: 1; }
    .saac-type .hero-slider .hero-text .hero-inner:before {
      content: unset;
      -webkit-box-shadow: none;
              box-shadow: none; }
    .saac-type .hero-slider .hero-text .hero-inner h2 {
      font-family: "Roboto", sans-seirf;
      font-size: 24px;
      margin-bottom: 8px; }
      @media (min-width: 992px) {
        .saac-type .hero-slider .hero-text .hero-inner h2 {
          font-size: 32px; } }
    .saac-type .hero-slider .hero-text .hero-inner p {
      margin-bottom: 8px;
      text-transform: none; }
    .saac-type .hero-slider .hero-text .hero-inner .hero-btn {
      background: transparent;
      border-width: 1px;
      border-radius: 3px;
      font-weight: 400;
      padding: 5px 20px;
      text-transform: none; }

.saac-type .hero-slider .swiper-pagination {
  display: none; }

.saac-type .hero-slider .swiper-nav {
  position: absolute;
  bottom: 10px;
  height: 100px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media (min-width: 768px) {
    .saac-type .hero-slider .swiper-nav {
      bottom: 120px; } }
  @media (min-width: 1200px) {
    .saac-type .hero-slider .swiper-nav {
      width: 70%;
      left: 15%; } }
  .saac-type .hero-slider .swiper-nav [class*='swiper-button-'] {
    width: 90px;
    height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../images/swiper-nav-decoration.png);
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    @media (min-width: 768px) {
      .saac-type .hero-slider .swiper-nav [class*='swiper-button-'] {
        width: 130px;
        height: 80px; } }
    .saac-type .hero-slider .swiper-nav [class*='swiper-button-'] > * {
      margin-left: -55px; }
  .saac-type .hero-slider .swiper-nav .swiper-button-prev {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1); }
    .saac-type .hero-slider .swiper-nav .swiper-button-prev > * {
      -webkit-transform: scaleX(-1);
              transform: scaleX(-1); }

/*@include media-breakpoint-down(sm) {
  .other-site {
    width: 100%;
    margin-top:30px;
    p {
      float: left;
    }
    a {
      float: right;
      @media(max-width:550px){
        width: 100%;
      }
    }
    @media(max-width:550px) {
      img {
        max-height: 70px !important;
      }
    }

  }
} */
.other-site {
  margin-top: 15px; }
  @media (min-width: 768px) {
    .other-site {
      margin-top: 0px; } }
  @media (max-width: 991.98px) {
    .other-site {
      width: 100%; } }
  .other-site img {
    margin-bottom: 20px;
    padding-right: 15px; }
    @media (min-width: 768px) {
      .other-site img {
        padding-right: 0px; } }
  .other-site p,
  .other-site a {
    width: 50%;
    float: left; }
    @media (min-width: 768px) {
      .other-site p,
      .other-site a {
        width: 100%;
        float: none; } }

.header-category {
  padding: 0px 20px;
  border-top: 1px solid #fff; }
  .header-category a {
    color: #fff;
    font-size: 20px;
    padding-right: 20px; }

.cat-swiper-container {
  width: 100%;
  height: auto; }

body {
  font-family: "Roboto", sans-seirf;
  font-weight: 500; }

h1,
.h1,
h2,
.h2,
h3, .h3, .templemans-content-block_h3 {
  font-family: "Crimson Text", sans-serif; }

.content-container {
  width: 100%;
  padding-right: 25px;
  padding-left: 25px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 1200px) {
    .content-container {
      padding-left: 130px;
      padding-right: 130px; } }

.hero-slider {
  position: relative;
  background: #276069; }
  @media (min-width: 768px) {
    .hero-slider {
      background: none; } }
  .hero-slider .swiper-slide {
    height: 100%;
    overflow: hidden; }
    .hero-slider .swiper-slide img {
      width: 150%; }
      @media (min-width: 1200px) {
        .hero-slider .swiper-slide img {
          width: 120%; } }
      @media (min-width: 1400px) {
        .hero-slider .swiper-slide img {
          width: 100%; } }
    .hero-slider .swiper-slide .overlay-logo {
      padding: 0 15px; }
      @media (min-width: 768px) {
        .hero-slider .swiper-slide .overlay-logo {
          max-height: 150px;
          width: auto; } }
  .hero-slider .hero-text {
    position: relative;
    bottom: 0;
    text-align: center;
    z-index: 9999;
    width: 100%;
    margin: 15px 0; }
    @media (min-width: 768px) {
      .hero-slider .hero-text {
        margin-top: 0px;
        position: absolute; } }
    .hero-slider .hero-text .hero-inner {
      max-width: 600px;
      width: 100%;
      margin: 0 auto; }
      @media (min-width: 768px) {
        .hero-slider .hero-text .hero-inner {
          opacity: 0;
          margin-top: 40px;
          -webkit-transition: 3s opacity ease, 1.8s margin-top ease;
          transition: 3s opacity ease, 1.8s margin-top ease; } }
      .hero-slider .hero-text .hero-inner h2 {
        font-size: 35px;
        margin-bottom: 15px;
        color: #fff; }
        @media (min-width: 992px) {
          .hero-slider .hero-text .hero-inner h2 {
            font-size: 56px;
            margin-bottom: 30px; } }
      .hero-slider .hero-text .hero-inner p {
        text-transform: uppercase;
        font-size: 23px;
        color: #fff;
        margin-bottom: 40px;
        font-weight: 100; }
        @media (max-width: 991.98px) {
          .hero-slider .hero-text .hero-inner p {
            font-size: 18px;
            margin-bottom: 15px; } }
      .hero-slider .hero-text .hero-inner .hero-btn {
        display: inline-block;
        font-size: 18px;
        text-transform: uppercase;
        border: 2px solid #fff;
        padding: 10px 50px;
        background: rgba(52, 119, 128, 0.7);
        color: #fff; }
        .hero-slider .hero-text .hero-inner .hero-btn:hover {
          background: #347780; }
      @media (min-width: 576px) {
        .hero-slider .hero-text .hero-inner:before {
          border-radius: 50%;
          -webkit-box-shadow: 0 0 270px 370px rgba(0, 0, 0, 0.15), 0 0 405px 600px rgba(0, 0, 0, 0.33);
                  box-shadow: 0 0 270px 370px rgba(0, 0, 0, 0.15), 0 0 405px 600px rgba(0, 0, 0, 0.33);
          content: "";
          display: block;
          position: absolute;
          left: 50%;
          width: 0;
          height: 25%;
          z-index: -1; } }
  @media (min-width: 768px) {
    .hero-slider .swiper-slide-active .hero-text .hero-inner {
      opacity: 1;
      margin-top: 0px; } }
  .hero-slider .swiper-pagination {
    position: absolute;
    z-index: 99;
    text-align: left;
    top: 30px;
    left: 30px;
    bottom: auto; }
    @media (min-width: 1200px) {
      .hero-slider .swiper-pagination {
        left: 125px; } }
    .hero-slider .swiper-pagination .swiper-pagination-bullet {
      border: 3px solid #fff;
      height: 25px;
      width: 25px;
      background: transparent;
      opacity: 1;
      -webkit-transition: 0.4s;
      transition: 0.4s; }
      .hero-slider .swiper-pagination .swiper-pagination-bullet:hover {
        -webkit-transition: 0.4s;
        transition: 0.4s;
        background: #fff; }
    .hero-slider .swiper-pagination .swiper-pagination-bullet-active {
      background: #fff; }

.panel {
  position: relative; }
  .panel .panel-bg {
    position: absolute;
    top: 0px; }
  .panel hr {
    width: 50%;
    border-color: #ccc;
    margin-top: 60px; }

.text-area {
  padding: 40px;
  text-align: center; }
  @media (min-width: 1200px) {
    .text-area {
      padding: 40px 22%; } }
  .text-area .panel-bg {
    right: 0px; }
  .text-area h1,
  .text-area h2 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 30px;
    margin-top: 30px;
    text-transform: uppercase; }
  .text-area p {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.7; }
    .text-area p span {
      color: #276069; }

.events-panel .event-image {
  margin-bottom: 20px;
  padding-right: 0px; }

.events-panel .event-details h2 {
  font-size: 34px;
  text-transform: uppercase;
  margin: 0 0 30px 0;
  font-weight: 600; }

.events-panel .event-details .event-info {
  margin-bottom: 50px;
  padding-right: 50px; }
  .events-panel .event-details .event-info h3 {
    font-size: 28px;
    font-weight: 500; }
  .events-panel .event-details .event-info .more {
    display: block;
    margin-top: 30px;
    font-size: 20px;
    color: #000; }
    .events-panel .event-details .event-info .more:hover {
      color: #276069; }

.tripadvisor {
  padding: 25px 15px 75px 15px; }
  @media (min-width: 992px) {
    .tripadvisor {
      padding: 25px 200px; } }
  .tripadvisor .title-img {
    margin: 0 auto 50px auto;
    display: block; }
  .tripadvisor .review {
    margin-bottom: 30px; }
    .tripadvisor .review h3 {
      font-weight: bold;
      margin-bottom: 20px;
      font-size: 20px; }
    .tripadvisor .review p {
      line-height: 1.2;
      font-weight: 400;
      font-size: 18px; }
    .tripadvisor .review a {
      color: #276069;
      font-size: 18px; }
    .tripadvisor .review .name {
      font-weight: bold;
      font-style: italic; }
  .tripadvisor .reviewtotal {
    text-align: center;
    margin-bottom: 30px; }
    .tripadvisor .reviewtotal a {
      color: #000;
      font-size: 28px;
      font-weight: 500;
      top: 5px;
      position: relative; }
    .tripadvisor .reviewtotal img {
      width: 200px;
      margin-right: 20px; }
  .tripadvisor .viewmore {
    padding: 10px 40px;
    text-transform: uppercase;
    color: #276069;
    font-weight: 700;
    background: transparent;
    display: inline-block;
    margin-top: 50px;
    text-align: center;
    border: 2px solid #276069;
    -webkit-transition: 0.3s;
    transition: 0.3s; }
    .tripadvisor .viewmore:hover {
      background: #276069;
      color: white; }

/*****************************************************/
/*Homepage Gallery 1*/
/*****************************************************/
.background-panel {
  background-position: right -250px top -200px;
  background-repeat: no-repeat;
  background-size: 37%; }

.gallery-block {
  margin-bottom: 50px; }
  .gallery-block .news h2 {
    text-transform: uppercase; }
  @media (max-width: 991.98px) {
    .gallery-block .news {
      padding-top: 30px; } }
  .gallery-block .two-block-horiz {
    margin-bottom: 50px; }

.two-block-horiz {
  width: 100%;
  background-position: center;
  background-size: auto 100%;
  overflow: hidden;
  height: 0;
  padding-top: 100%; }

.one-block-horiz {
  width: 100%;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  height: 0;
  padding-top: 47.25%;
  background-repeat: no-repeat; }

.one-block-vert {
  width: 100%;
  background-position: center;
  background-size: auto 100%;
  overflow: hidden;
  height: 100%; }
  @media (max-width: 991.98px) {
    .one-block-vert {
      display: none; } }

/*****************************************************/
/*Homepage Gallery 1*/
/*****************************************************/
.upcoming-post {
  margin-bottom: 30px; }
  .upcoming-post a {
    background: #276069;
    border: 1px solid #276069;
    padding: 10px 30px;
    color: white;
    text-transform: uppercase; }
    .upcoming-post a:hover {
      background: white;
      color: #276069; }

/*****************************************************/
/* Featured Categories*/
/*****************************************************/
.featured-categories {
  margin-top: 50px;
  margin-bottom: 50px; }
  .featured-categories .two-block-horiz,
  .featured-categories .one-block-horiz {
    margin-bottom: 50px; }
  .featured-categories .featured-item {
    position: relative; }
    .featured-categories .featured-item .feature-inner {
      position: absolute;
      z-index: 100;
      bottom: 20px;
      text-align: center;
      width: 100%; }
      .featured-categories .featured-item .feature-inner h3 {
        position: relative;
        text-transform: uppercase;
        padding: 10px 30px;
        padding-top: 13px;
        font-size: 1rem;
        color: #000;
        text-align: center;
        background: white;
        width: auto;
        display: inline-block;
        max-width: 90%; }
        @media (max-width: 1399.98px) {
          .featured-categories .featured-item .feature-inner h3 {
            font-size: 20px; } }
        @media (max-width: 991.98px) {
          .featured-categories .featured-item .feature-inner h3 {
            padding: 5px 10px;
            font-size: 18px; } }
        .featured-categories .featured-item .feature-inner h3 svg {
          margin-left: 5px;
          padding-top: 3px; }
  .featured-categories a .featured-item {
    background-size: 100%;
    -webkit-transition: 0.8s background-size ease;
    transition: 0.8s background-size ease; }
  .featured-categories a:hover .featured-item {
    background-size: 110%; }

/*****************************************************/
/* 404 */
/*****************************************************/
.four-zero-four {
  padding-top: 100px;
  padding-bottom: 100px; }
  .four-zero-four h1 {
    text-transform: uppercase;
    color: #276069;
    text-align: center;
    font-weight: 700;
    padding-top: 20px;
    margin-bottom: 20px; }
  .four-zero-four h2 {
    text-align: center;
    margin-bottom: 40px; }
  .four-zero-four .page-content {
    text-align: center; }
    .four-zero-four .page-content .search-field {
      height: 48px;
      border: 1px solid #276069;
      padding: 10px; }
    .four-zero-four .page-content .search-submit {
      background-color: #276069;
      color: white;
      border: 2px solid #276069;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      text-transform: uppercase;
      height: 48px; }
      .four-zero-four .page-content .search-submit:hover {
        background: transparent;
        color: #276069; }

/*****************************************************/
/* Documentation Categories*/
/*****************************************************/
.documentation-template {
  margin-bottom: 50px; }
  .documentation-template h1 {
    text-transform: uppercase;
    color: #276069;
    border-top: 2px solid #ddd;
    font-weight: 700;
    padding-top: 20px;
    margin-bottom: 20px; }
  .documentation-template p,
  .documentation-template li {
    font-size: 18px; }

/*
Blog Archive
 */
.blog .subscribe-section {
  margin-top: 0px; }

.blog-archive {
  background: #276069; }
  .blog-archive article {
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    width: 100%;
    padding-right: 25px;
    padding-left: 25px; }
    .blog-archive article:not(.featured) h2 {
      font-size: 1.3rem; }
    @media (min-width: 768px) {
      .blog-archive article:not(.featured) {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
                flex: 0 0 50%;
        max-width: 50%; } }
    @media (min-width: 992px) {
      .blog-archive article:not(.featured) {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 33.33333%;
                flex: 0 0 33.33333%;
        max-width: 33.33333%;
        border-left: 1px solid #1f4a50;
        border-bottom: 1px solid #1f4a50; }
        .blog-archive article:not(.featured):nth-child(3n+1) {
          border-right: 1px solid #1f4a50; }
        .blog-archive article:not(.featured):last-child {
          border-right: 1px solid #1f4a50; } }
    .blog-archive article:not(.featured) .content-wrap {
      background: #fff;
      padding: 30px;
      height: 100%;
      position: relative; }
      .blog-archive article:not(.featured) .content-wrap header {
        margin-bottom: 15px; }
    .blog-archive article:not(.featured) .date {
      position: absolute;
      top: -15px;
      background: #133236;
      padding: 0 9px;
      color: #fff;
      font-weight: normal; }
    .blog-archive article.featured {
      color: #fff;
      margin-bottom: 50px;
      border-bottom: 1px solid #133236;
      padding-bottom: 25px;
      background: #133236;
      -webkit-box-shadow: inset 0px 0px 20px 2px rgba(0, 0, 0, 0.3);
              box-shadow: inset 0px 0px 20px 2px rgba(0, 0, 0, 0.3);
      background-size: 50%;
      background-position: right;
      background-repeat: no-repeat;
      background-image: url(https://d1ssu070pg2v9i.cloudfront.net/pex/ace_saac/2018/10/29165552/Ampersand.png); }
      @media (min-width: 768px) {
        .blog-archive article.featured {
          margin-bottom: 0px; } }
      @media (min-width: 768px) {
        .blog-archive article.featured .content {
          margin-bottom: 0px;
          padding-top: 50px;
          position: relative; }
          .blog-archive article.featured .content a h2 {
            font-size: 2.5rem; }
          .blog-archive article.featured .content .cta {
            display: inline-block;
            background: #fff;
            padding: 10px 30px;
            position: absolute;
            right: 0;
            margin-top: 30px;
            -webkit-transition: 0.3s background ease;
            transition: 0.3s background ease; }
            .blog-archive article.featured .content .cta:hover {
              background: #6ab4c0; } }
      @media (min-width: 992px) {
        .blog-archive article.featured .content {
          width: 80%; } }
      .blog-archive article.featured .content a h2 {
        color: #fff;
        -webkit-transition: 0.3s color ease;
        transition: 0.3s color ease; }
      .blog-archive article.featured .content a:hover h2 {
        color: #6ab4c0; }

/*.blog-archive {

    @include media-breakpoint-down(lg) {
        padding-top:50px;
    }

    position: relative;
    article {
        @include make-col-ready();
        position: initial;
        margin-bottom: $blog-padding * 2;
        padding: 0 $blog-padding;
        &:not(.featured) {
            @include media-breakpoint-up(md) {
                @include make-col(6);
            }
            @include media-breakpoint-up(lg) {
                @include make-col(4);
            }
        }

        &:last-of-type {
            @include media-breakpoint-only(xs) {
                margin-bottom: 75px;
            }
        } 

        .content-wrap {
            height: 100%;
            border: 2px solid theme-color("primary");
            padding: ($grid-gutter-width / 2);
        }

        &.featured {
            .feature-image {
                margin-bottom: 15px;
            }

            @include media-breakpoint-up(lg) {
                height: 500px;
                padding: 0;
                margin-bottom: 75px;
                .content-wrap {
                    border: none;
                    padding: 0;
                }

                .feature-image, .content {
                    width: 50%;
                    float: left;
                }

                .feature-image {
                    position: absolute;
                    left: 0;
                    margin-bottom: 0;
                    img {
                        width: 100%;
                        max-height: 500px;
                        object-fit: cover;
                    }
                } 

                .content {
                    padding: 80px;
                    margin-left: 50%;
                }
            }
        }
    }
}  */
/*
Single News
 */
.single-news .categories {
  border-top: 1px solid #f1f1f1;
  padding: 0px; }
  .single-news .categories li {
    text-transform: capitalize;
    list-style-type: none;
    padding: 0;
    border-bottom: 1px solid #f1f1f1; }
    .single-news .categories li a {
      display: block;
      padding: 10px 0;
      width: 100%;
      color: #fff;
      background: #276069;
      -webkit-transition: 0.3s all ease;
      transition: 0.3s all ease; }
      .single-news .categories li a:hover {
        background: #133236;
        padding-left: 10px; }

.single-news .content,
.single-news .sidebar {
  position: relative;
  width: 100%;
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 30px;
  padding-bottom: 30px; }
  @media (min-width: 768px) {
    .single-news .content,
    .single-news .sidebar {
      padding-top: 70px;
      padding-bottom: 70px; } }

@media (min-width: 992px) {
  .single-news .content {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
    border-right: 1px solid #276069;
    padding-right: 100px; } }

.single-news .sidebar {
  background: #276069; }
  @media (min-width: 768px) {
    .single-news .sidebar {
      padding-top: 30px; } }
  @media (min-width: 992px) {
    .single-news .sidebar {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 33.33333%;
              flex: 0 0 33.33333%;
      max-width: 33.33333%; } }
  .single-news .sidebar h2 {
    color: #fff;
    margin-top: 30px; }

.single-post .subscribe-section,
.category .subscribe-section {
  margin-top: 0px; }

.site-footer {
  background-color: #276069;
  color: #f8f9fa;
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative; }
  .site-footer .content-container {
    position: relative; }
  .site-footer #scroll-to-top {
    background: #276069;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    position: absolute;
    top: -50px;
    right: 25px;
    height: 50px;
    width: 80px;
    line-height: 60px;
    text-align: center; }
    .site-footer #scroll-to-top:hover {
      cursor: pointer; }
    .site-footer #scroll-to-top svg {
      color: white; }
    @media (min-width: 1200px) {
      .site-footer #scroll-to-top {
        right: 100px; } }
  .site-footer a {
    color: #f8f9fa;
    text-decoration: none; }
  .site-footer .social-media {
    margin-top: 30px; }
    @media (max-width: 767.98px) {
      .site-footer .social-media {
        width: 50%;
        display: inline-block; } }
    @media (max-width: 620px) {
      .site-footer .social-media {
        width: 100%; } }
    .site-footer .social-media a {
      font-size: 20px;
      background: #fff;
      border-radius: 50px;
      display: inline-block;
      width: 50px;
      height: 50px;
      text-align: center;
      margin-left: 10px;
      -webkit-transition: 0.2s;
      transition: 0.2s; }
      @media (max-width: 620px) {
        .site-footer .social-media a:first-child {
          margin-left: 0; } }
      .site-footer .social-media a:hover {
        -webkit-transition: 0.2s;
        transition: 0.2s;
        opacity: 0.8; }
      .site-footer .social-media a svg {
        color: #276069;
        font-size: 25px;
        top: 11px;
        position: relative; }
    @media (min-width: 768px) {
      .site-footer .social-media {
        text-align: right; } }
  .site-footer .footer-links {
    margin: 30px 0;
    font-size: 18px;
    float: right;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    text-decoration: none; }
    @media (max-width: 767.98px) {
      .site-footer .footer-links {
        width: 50%;
        display: inline-block; } }
    @media (max-width: 620px) {
      .site-footer .footer-links {
        width: 100%; } }
    .site-footer .footer-links a {
      margin-left: 30px;
      -webkit-transition: 0.2s;
      transition: 0.2s; }
      @media (max-width: 620px) {
        .site-footer .footer-links a:first-child {
          margin-left: 0; } }
      .site-footer .footer-links a:hover {
        -webkit-transition: 0.2s;
        transition: 0.2s;
        opacity: 0.8; }
    .site-footer .footer-links svg {
      margin-right: 5px; }
  .site-footer .payment-icons {
    width: 100%;
    margin: 5px 0; }
    .site-footer .payment-icons img {
      background-color: white; }
    @media (min-width: 768px) {
      .site-footer .payment-icons {
        float: right;
        text-align: right; } }
  .site-footer .footer-logo img {
    max-width: 280px;
    width: 100%; }
  .site-footer .footer-logo--templemans img {
    max-width: 210px; }
    @media (min-width: 768px) {
      .site-footer .footer-logo--templemans img {
        margin-left: 30px; } }
  .site-footer #copytext {
    margin: 30px 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500; }
  .site-footer #copyaddress {
    margin-bottom: 0;
    font-size: 14px; }
    .site-footer #copyaddress a:hover {
      text-decoration: underline; }

.footer-img {
  background: #fff;
  text-align: center;
  position: relative;
  border-radius: 10px;
  padding: 10px; }
  .footer-img img {
    height: 125px;
    width: auto;
    margin: 10px; }
  @media (min-width: 768px) {
    .footer-img {
      text-align: right; }
      .footer-img img {
        height: 115px;
        margin: 0 0 10px 10px; } }

.credit {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #000; }
  @media (max-width: 767.98px) {
    .credit {
      padding-bottom: 40px; } }
  .credit #menu-footer-menu {
    margin-top: 20px;
    padding-left: 0px; }
    .credit #menu-footer-menu li {
      margin-right: 30px !important; }
      .credit #menu-footer-menu li:last-child {
        margin-right: 0 !important; }
      .credit #menu-footer-menu li a {
        color: #fff;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 400;
        text-decoration: none;
        -webkit-transition: 0.2s;
        transition: 0.2s; }
        .credit #menu-footer-menu li a:hover {
          -webkit-transition: 0.2s;
          transition: 0.2s;
          color: #276069; }
  .credit #b2 {
    color: #fff; }
    .credit #b2 span {
      position: relative;
      top: 13px; }
    .credit #b2 img {
      width: 70px;
      margin-left: 10px;
      margin-top: 20px; }

.templemans-type .site-footer {
  background-color: #6d88a3; }
  .templemans-type .site-footer .copyright a {
    color: #fff; }

.templemans-type #scroll-to-top {
  background: #6d88a3 !important; }

#customModal .modal-dialog {
  margin-top: 5rem; }
  @media (min-width: 992px) {
    #customModal .modal-dialog {
      max-width: 550px; } }

#customModal .modal-header .modal-title {
  text-transform: uppercase;
  margin: 0 auto;
  color: #6d88a3;
  font-size: 2rem; }

#customModal .modal-body p {
  font-size: 1rem; }

#customModal .modal-footer .btn, #customModal .modal-footer .btn-cta {
  font-size: 1.5rem;
  background-color: #6d88a3;
  border-color: unset !important; }

.the-portal {
  background-color: #ddd;
  padding: 20px 20px 0 20px; }
  .the-portal h1 {
    margin-bottom: 0; }
  @media (max-width: 991.98px) {
    .the-portal {
      padding: 10px 10px 0 10px; } }
  .the-portal.footer {
    padding-bottom: 20px;
    padding-top: 0;
    border-top: 1px solid #ddd; }
    @media (max-width: 991.98px) {
      .the-portal.footer {
        padding-bottom: 10px; } }
    .the-portal.footer .site-footer {
      background: white;
      color: #000; }
      .the-portal.footer .site-footer a {
        color: #000; }
      .the-portal.footer .site-footer .social-media a {
        background: #000; }
        .the-portal.footer .site-footer .social-media a svg {
          color: white; }
  .the-portal .portal-block {
    padding-top: 50px;
    padding-bottom: 50px;
    height: 100%;
    position: relative;
    overflow: hidden; }
    .the-portal .portal-block .portal-link .inner-section {
      background-color: rgba(255, 255, 255, 0.9);
      padding: 20px;
      max-width: 60%;
      -webkit-animation: fade-in 3s ease-out;
              animation: fade-in 3s ease-out;
      display: block;
      margin: 0 auto;
      position: relative;
      z-index: 50; }
      @media (max-width: 1399.98px) {
        .the-portal .portal-block .portal-link .inner-section {
          max-width: 80%; } }
      @media (max-width: 991.98px) {
        .the-portal .portal-block .portal-link .inner-section {
          padding: 10px; } }
      .the-portal .portal-block .portal-link .inner-section img {
        max-width: 70%;
        margin: 30px auto;
        display: block;
        height: auto; }
      .the-portal .portal-block .portal-link .inner-section hr {
        border: 3px solid #000;
        width: 100px;
        margin-top: 10px;
        margin-bottom: 20px; }
      .the-portal .portal-block .portal-link .inner-section h2 {
        color: #212529; }
      .the-portal .portal-block .portal-link .inner-section p {
        text-align: center;
        margin-bottom: 50px;
        padding-right: 20px;
        padding-left: 20px;
        color: #212529; }
        @media (max-width: 991.98px) {
          .the-portal .portal-block .portal-link .inner-section p {
            padding-right: 10px;
            padding-left: 10px; } }
      .the-portal .portal-block .portal-link .inner-section .cta {
        display: block;
        background: #747468;
        color: white;
        position: absolute;
        bottom: -20px;
        right: 30px;
        padding: 5px 20px;
        text-transform: uppercase;
        -webkit-transition: 0.3s;
        transition: 0.3s; }
        .the-portal .portal-block .portal-link .inner-section .cta:hover {
          background: #979786; }
        .the-portal .portal-block .portal-link .inner-section .cta svg {
          margin-left: 5px;
          padding-top: 3px; }
    .the-portal .portal-block .portal-link .image-bg {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-size: cover;
      background-position: 50%;
      z-index: 30;
      -webkit-transform: translateZ(0) scale(1);
              transform: translateZ(0) scale(1);
      -webkit-transition: 1s all ease;
      transition: 1s all ease;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden; }
    .the-portal .portal-block .portal-link:hover .image-bg {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    .the-portal .portal-block .portal-link:hover .inner-section .cta {
      right: 20px; }
    .the-portal .portal-block .cover-bg-image-link {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: transparent;
      z-index: 40; }
  .the-portal .block-col {
    background: white; }
    .the-portal .block-col.block-one {
      padding: 25px 12.5px 25px 25px; }
      @media (max-width: 991.98px) {
        .the-portal .block-col.block-one {
          padding: 25px 25px 12.5px 25px; } }
      .the-portal .block-col.block-one .portal-block {
        height: 100%; }
      .the-portal .block-col.block-one .portal-link:hover .inner-section .cta {
        background: #276069; }
    .the-portal .block-col.block-two {
      padding: 25px 25px 25px 12.5px;
      position: relative; }
      @media (max-width: 991.98px) {
        .the-portal .block-col.block-two {
          padding: 12.5px 25px 25px 25px; } }
      .the-portal .block-col.block-two .portal-link:hover .inner-section .cta {
        background: #6d88a3; }
    .the-portal .block-col.block-three {
      margin-top: 25px;
      padding-right: 12.5px; }
      @media (max-width: 1399.98px) {
        .the-portal .block-col.block-three {
          padding-right: 0; } }
      @media (max-width: 1199.98px) {
        .the-portal .block-col.block-three {
          padding: 0; } }
      .the-portal .block-col.block-three .portal-block {
        padding-top: 150px;
        padding-bottom: 150px; }
        @media (max-width: 991.98px) {
          .the-portal .block-col.block-three .portal-block {
            padding-top: 50px;
            padding-bottom: 50px; } }
        .the-portal .block-col.block-three .portal-block .inner-section {
          padding: 20px;
          max-width: 80%;
          text-align: center; }
          .the-portal .block-col.block-three .portal-block .inner-section h2 {
            font-weight: 700; }
      .the-portal .block-col.block-three .portal-link:hover .inner-section .cta {
        background: #276069; }
    .the-portal .block-col.block-four {
      margin-top: 25px;
      padding-left: 12.5px; }
      @media (max-width: 1399.98px) {
        .the-portal .block-col.block-four {
          padding: 0 0 12.5px 0; } }
      @media (max-width: 1199.98px) {
        .the-portal .block-col.block-four {
          padding-bottom: 0; } }
      .the-portal .block-col.block-four .event-block {
        background: #ddd;
        height: 100%;
        padding: 20px; }
        .the-portal .block-col.block-four .event-block .view-all {
          display: block;
          text-transform: uppercase;
          color: #212529;
          text-align: center; }
  .the-portal .portal-event {
    margin-bottom: 20px; }
    .the-portal .portal-event a {
      display: block;
      color: #212529; }
      .the-portal .portal-event a .image {
        width: 100%; }
        @media (min-width: 768px) {
          .the-portal .portal-event a .image {
            background: #fff;
            position: relative; }
            .the-portal .portal-event a .image .cta {
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              background: rgba(39, 96, 105, 0.9);
              text-align: center;
              padding-top: 30%;
              -webkit-transition: 0.5s all ease;
              transition: 0.5s all ease;
              color: #fff; } }
        .the-portal .portal-event a .image .cta {
          opacity: 0; }
        .the-portal .portal-event a .image img {
          width: 100%;
          margin: 10px auto;
          display: block; }
          @media (min-width: 768px) {
            .the-portal .portal-event a .image img {
              max-width: 100%;
              width: auto;
              max-height: 200px; } }
        @media (min-width: 768px) {
          .the-portal .portal-event a .image {
            width: 40%;
            float: left;
            padding: 0 10px; }
            .the-portal .portal-event a .image img {
              max-height: 125px; } }
      .the-portal .portal-event a .text {
        width: 100%; }
        @media (min-width: 768px) {
          .the-portal .portal-event a .text {
            width: 60%;
            float: left;
            padding-left: 15px; } }
        .the-portal .portal-event a .text h3 {
          font-size: 14px;
          padding-top: 10px;
          font-family: "Roboto";
          font-weight: 300; }
        .the-portal .portal-event a .text .date, .the-portal .portal-event a .text .cta {
          color: #212529;
          width: 50%;
          float: left;
          font-size: 14px; }
        .the-portal .portal-event a .text .cta {
          display: inline-block;
          text-align: right; }
        .the-portal .portal-event a .text .date {
          letter-spacing: 1px; }
      @media (min-width: 1200px) {
        .the-portal .portal-event a .image, .the-portal .portal-event a .text {
          width: 50%; } }
      .the-portal .portal-event a:hover .image .cta {
        opacity: 1; }
      .the-portal .portal-event a:hover .text .cta {
        color: #276069; }
  .the-portal .see-more {
    text-align: center;
    color: #000;
    text-transform: uppercase;
    display: block;
    letter-spacing: 1px; }
    .the-portal .see-more:hover {
      text-decoration: underline; }

.slider-menu-bar {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  z-index: 100;
  width: 100%;
  color: white; }
  @media (max-width: 991.98px) {
    .slider-menu-bar {
      border-top: 1px solid #000; }
      .slider-menu-bar .slide-cont {
        padding: 0;
        margin: 0;
        width: 100%;
        max-width: 100% !important; } }
  @media (max-width: 1199.98px) {
    .slider-menu-bar {
      position: relative;
      background: black; } }
  .slider-menu-bar .circa-slide-overlay .menu-wrapper {
    padding: 0; }
    @media (min-width: 768px) {
      .slider-menu-bar .circa-slide-overlay .menu-wrapper {
        width: auto;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        -ms-flex: 1;
            flex: 1; } }
  .slider-menu-bar .circa-slide-overlay .menu-links {
    text-align: center; }
    .slider-menu-bar .circa-slide-overlay .menu-links a {
      text-transform: uppercase;
      display: block;
      height: 100%;
      width: 100%;
      padding: 20px;
      color: white;
      -webkit-transition: 0.3s;
      transition: 0.3s; }
      .slider-menu-bar .circa-slide-overlay .menu-links a:hover {
        background: #276069; }
  .slider-menu-bar .circa-slide-overlay .active-item a {
    background: #276069; }

.introduction {
  padding-top: 40px;
  padding-bottom: 40px; }
  .introduction h1 {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px; }
  .introduction p {
    text-align: center;
    margin-bottom: 40px; }
  .introduction hr {
    margin: 40px 20% 20px 20%; }

.section-gallery {
  -webkit-column-count: 3;
     -moz-column-count: 3;
          column-count: 3;
  margin-bottom: 100px; }
  .section-gallery .single-text-block {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    overflow: hidden;
    background: #276069;
    color: white;
    padding: 20px; }
    .section-gallery .single-text-block a {
      color: white;
      text-decoration: underline; }
  @media (max-width: 1199.98px) {
    .section-gallery {
      -webkit-column-count: 2;
         -moz-column-count: 2;
              column-count: 2;
      -webkit-column-gap: 0;
         -moz-column-gap: 0;
              column-gap: 0; }
      .section-gallery .single-text-block {
        margin-bottom: 0; } }
  @media (max-width: 991.98px) {
    .section-gallery {
      -webkit-column-count: 1;
         -moz-column-count: 1;
              column-count: 1;
      -webkit-column-gap: 0;
         -moz-column-gap: 0;
              column-gap: 0; }
      .section-gallery .single-text-block {
        margin-bottom: 25px; } }
  .section-gallery .single-image {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    overflow: hidden; }
    @media (max-width: 1199.98px) {
      .section-gallery .single-image {
        margin-bottom: 0; } }
    @media (max-width: 991.98px) {
      .section-gallery .single-image {
        margin-bottom: 25px; } }
    .section-gallery .single-image:hover {
      cursor: pointer; }
    .section-gallery .single-image img {
      width: 100%;
      height: auto; }

/*********************************
MENU
*********************************/
.cafe-menu .menu-tabs {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  margin-bottom: 50px;
  background-color: #276069; }
  .cafe-menu .menu-tabs .nav-tabs {
    border: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
    display: block;
    text-align: center; }
  .cafe-menu .menu-tabs .single-tab {
    background: #276069;
    text-align: center;
    border-radius: 0;
    margin-bottom: 0;
    font-size: 20px;
    text-transform: uppercase;
    font-family: "Roboto", sans-seirf;
    display: inline-block; }
    .cafe-menu .menu-tabs .single-tab a {
      color: white;
      padding: 20px;
      border-radius: 0;
      border: none; }
      .cafe-menu .menu-tabs .single-tab a.active {
        background: #000; }

.cafe-menu .menu-section {
  margin-bottom: 50px; }
  .cafe-menu .menu-section .tab-content {
    background: #eee;
    padding: 50px; }
    .cafe-menu .menu-section .tab-content .menu-block {
      margin-bottom: 40px; }
      .cafe-menu .menu-section .tab-content .menu-block h3 {
        text-transform: uppercase;
        font-weight: 600; }
      .cafe-menu .menu-section .tab-content .menu-block table {
        width: 100%;
        border-collapse: collapse;
        font-size: 22px; }
        .cafe-menu .menu-section .tab-content .menu-block table tr.custom-row {
          border-bottom: 1px solid #373f49; }
        .cafe-menu .menu-section .tab-content .menu-block table td {
          padding-top: 10px;
          padding-bottom: 10px; }
          .cafe-menu .menu-section .tab-content .menu-block table td .description {
            font-size: 16px;
            color: #494e52; }
        .cafe-menu .menu-section .tab-content .menu-block table .pricing {
          text-align: right;
          font-weight: 500; }

.cafe-menu .side-panel {
  background: #276069;
  color: white; }
  .cafe-menu .side-panel .content-section {
    padding: 40px 50px; }
    .cafe-menu .side-panel .content-section h2 {
      text-align: center;
      text-transform: uppercase; }
    .cafe-menu .side-panel .content-section p:last-child {
      margin-bottom: 0; }
  .cafe-menu .side-panel .image-section {
    background-size: cover;
    padding: 20px; }
    .cafe-menu .side-panel .image-section img {
      width: 200px;
      margin: 0 auto;
      display: block; }
    .cafe-menu .side-panel .image-section .button-holder {
      text-align: center; }
      .cafe-menu .side-panel .image-section .button-holder a {
        color: white;
        text-decoration: underline; }

/*********************************
How to find us
*********************************/
.custom-find-us {
  padding-top: 50px; }
  .custom-find-us h1 {
    text-align: center;
    text-transform: uppercase; }
  .custom-find-us #map {
    height: 400px;
    width: 100%;
    background: #eee;
    margin-top: 50px; }

/*********************************
Booking
*********************************/
.custom-booking h1 {
  text-transform: uppercase;
  border-top: 2px solid #ccc;
  padding-top: 30px; }

/*********************************
Set Menu
*********************************/
.custom-menu-title .fixed-menu-price {
  font-weight: 700; }
  @media (min-width: 768px) {
    .custom-menu-title .fixed-menu-price {
      float: right; } }

.book-online hr {
  margin: 40px 20% 20px 20%; }

.book-online h2 {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-weight: 600; }

.book-online p.content {
  text-align: center;
  margin-bottom: 40px; }

.book-online form {
  margin-top: 50px;
  margin-bottom: 100px; }
  .book-online form label {
    text-transform: uppercase;
    width: 100%; }
  .book-online form input, .book-online form textarea, .book-online form select {
    height: 48px;
    border: 1px solid #276069;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px; }
  .book-online form textarea {
    height: 150px; }
  .book-online form .custom-submit {
    width: auto;
    float: right;
    height: auto;
    background-color: #276069;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    padding: 10px 30px; }

/*.feefo-container {
    text-align: center;
	@include media-breakpoint-up(md){
		margin-bottom: 90px;
	}
}*/
.feature-video {
  padding: 35px 0; }
  .feature-video iframe {
    min-height: 500px;
    width: 100%;
    height: auto; }

.container-feefo .row {
  width: 100%; }

.container-feefo #feefo-product-review-widgetId {
  display: block !important; }

.feefo-review-carousel-widget-service {
  text-align: center;
  overflow: hidden; }

.square {
  border-radius: 0;
  height: 0;
  padding: 0 0 100% 0;
  overflow: hidden;
  position: relative; }
  .square > * {
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    top: 0;
    left: 0; }

.circle {
  border-radius: 50%;
  height: 0;
  padding: 0 0 100% 0;
  overflow: hidden;
  position: relative; }
  .circle > * {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    -o-object-fit: cover;
       object-fit: cover;
    top: 0;
    left: 0; }

.saac-type:not(.home) .content-container:not(footer) p,
.saac-type:not(.home) .text-area p {
  font-weight: 400;
  color: #373f49; }
  .saac-type:not(.home) .content-container:not(footer) p span,
  .saac-type:not(.home) .text-area p span {
    color: #373f49; }

.saac-type:not(.home) .background-panel {
  background-size: 100%; }

.saac-type:not(.home) .cards-block {
  margin-bottom: 50px;
  margin-top: 150px; }
  .saac-type:not(.home) .cards-block .card h2 {
    font-weight: 700;
    margin-top: -150px; }
    @media (min-width: 992px) {
      .saac-type:not(.home) .cards-block .card h2 {
        font-size: 62px; } }
  .saac-type:not(.home) .cards-block .card h3 {
    font-size: 32px;
    font-weight: 700; }

.saac-type:not(.home) .card {
  border: 0 none;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  font-family: "Roboto", sans-seirf;
  font-weight: normal;
  margin-bottom: 25px;
  padding: 25px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  min-height: 100%; }
  .saac-type:not(.home) .card h2 {
    color: black;
    font-family: "Roboto", sans-seirf;
    font-size: 52px;
    margin-bottom: -5px; }
  .saac-type:not(.home) .card h3 {
    color: #276069;
    font-family: "Roboto", sans-seirf; }
  .saac-type:not(.home) .card p {
    font-weight: normal; }
  .saac-type:not(.home) .card a {
    color: #276069;
    text-decoration: underline;
    margin-bottom: 10px; }
  .saac-type:not(.home) .card .btn, .saac-type:not(.home) .card .btn-cta {
    background: #276069;
    border: 1px solid #276069;
    color: #fff;
    text-decoration: none;
    -webkit-transition: .3s all ease;
    transition: .3s all ease; }
    .saac-type:not(.home) .card .btn:hover, .saac-type:not(.home) .card .btn-cta:hover, .saac-type:not(.home) .card .btn:focus, .saac-type:not(.home) .card .btn-cta:focus {
      background: #fff;
      color: #276069; }

.saac-type:not(.home) .cards-block-footer {
  font-weight: normal;
  padding-bottom: 75px;
  text-align: center; }
  .saac-type:not(.home) .cards-block-footer:after {
    content: '';
    display: block;
    background-image: url(../images/cards-block-footer-decoration.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 15px; }

.saac-type:not(.home) .gallery-block--video {
  position: relative; }
  .saac-type:not(.home) .gallery-block--video .two-block-horiz {
    position: absolute;
    left: 0;
    z-index: 100; }
  .saac-type:not(.home) .gallery-block--video .video-block {
    width: 100%;
    position: relative;
    height: 0;
    padding-bottom: 100%; }
    .saac-type:not(.home) .gallery-block--video .video-block:before, .saac-type:not(.home) .gallery-block--video .video-block:after {
      content: '';
      background: #fff;
      display: block;
      position: absolute;
      z-index: 101; }
    .saac-type:not(.home) .gallery-block--video .video-block:before {
      width: 100%;
      height: 50px;
      left: 0;
      top: calc(50% - 50px/2); }
    .saac-type:not(.home) .gallery-block--video .video-block:after {
      height: 50%;
      width: 50px;
      left: calc(50% - 50px/2);
      top: 0; }
    .saac-type:not(.home) .gallery-block--video .video-block video {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      -o-object-fit: cover;
         object-fit: cover; }

.saac-type:not(.home) .link--video {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: -10px; }
  .saac-type:not(.home) .link--video .icon--circle {
    background: #fff;
    border: 2px solid #276069;
    border-radius: 50%;
    padding: 20px;
    margin-right: 10px;
    width: 50px;
    height: 50px;
    position: relative;
    display: block; }

.saac-type:not(.home) .btn--video {
  background: #276069;
  padding: 20px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media (min-width: 1400px) {
    .saac-type:not(.home) .btn--video {
      width: calc(120% + 50px);
      margin-left: calc(-20% - 50px); } }
  .saac-type:not(.home) .btn--video img {
    max-width: 80%;
    height: auto;
    -o-object-fit: contain;
       object-fit: contain; }

.saac-type:not(.home) .news .upcoming-post h3 {
  color: #276069;
  font-family: "Roboto", sans-seirf;
  font-size: 26px;
  line-height: normal; }

.saac-type:not(.home) .news .upcoming-post p {
  font-weight: normal;
  line-height: normal; }

.saac-type:not(.home) .tripadvisor-container {
  background: #f5f5f5;
  margin-top: 25px;
  margin-bottom: 25px; }
  .saac-type:not(.home) .tripadvisor-container .viewmore {
    border: 0 none;
    padding: 0;
    text-decoration: underline;
    text-transform: uppercase; }
    .saac-type:not(.home) .tripadvisor-container .viewmore:hover, .saac-type:not(.home) .tripadvisor-container .viewmore:focus {
      background: transparent;
      color: #347780; }

.saac-type:not(.home) .featured-dealer {
  margin-top: 25px;
  margin-bottom: 50px;
  padding-top: 25px; }
  .saac-type:not(.home) .featured-dealer h2 {
    color: #276069;
    font-family: "Roboto", sans-seirf;
    font-weight: 700;
    text-transform: uppercase; }
    @media (min-width: 768px) {
      .saac-type:not(.home) .featured-dealer h2 {
        font-size: 52px; } }
    .saac-type:not(.home) .featured-dealer h2 span {
      font-size: 18px;
      display: block; }
      @media (min-width: 768px) {
        .saac-type:not(.home) .featured-dealer h2 span {
          font-size: 28px; } }
  .saac-type:not(.home) .featured-dealer .btn, .saac-type:not(.home) .featured-dealer .btn-cta {
    background: #276069;
    border: 1px solid #276069;
    color: #fff;
    text-decoration: none;
    -webkit-transition: .3s all ease;
    transition: .3s all ease; }
    .saac-type:not(.home) .featured-dealer .btn:hover, .saac-type:not(.home) .featured-dealer .btn-cta:hover, .saac-type:not(.home) .featured-dealer .btn:focus, .saac-type:not(.home) .featured-dealer .btn-cta:focus {
      background: #fff;
      color: #276069; }

.saac-type:not(.home) .instagram-section {
  margin-bottom: 100px; }
  .saac-type:not(.home) .instagram-section .instagram-header,
  .saac-type:not(.home) .instagram-section .instagram-footer {
    border-top: 1px solid rgba(39, 96, 105, 0.2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .saac-type:not(.home) .instagram-section .instagram-header .icon--circle,
    .saac-type:not(.home) .instagram-section .instagram-footer .icon--circle {
      background: #fff;
      -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
              box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      color: #276069;
      padding: 12px;
      margin-top: -30px;
      width: 60px;
      height: 60px;
      position: relative;
      display: block;
      font-size: 34px;
      line-height: 0; }
  .saac-type:not(.home) .instagram-section .instagram-footer {
    margin-top: 25px;
    margin-bottom: 50px; }
    .saac-type:not(.home) .instagram-section .instagram-footer .btn, .saac-type:not(.home) .instagram-section .instagram-footer .btn-cta {
      margin-top: -30px; }
  .saac-type:not(.home) .instagram-section .btn, .saac-type:not(.home) .instagram-section .btn-cta {
    background: #276069;
    border: 1px solid #276069;
    border-radius: 8px;
    color: #fff;
    padding: 15px 30px;
    font-size: 18px;
    text-decoration: none;
    -webkit-transition: .3s all ease;
    transition: .3s all ease; }
    .saac-type:not(.home) .instagram-section .btn:hover, .saac-type:not(.home) .instagram-section .btn-cta:hover, .saac-type:not(.home) .instagram-section .btn:focus, .saac-type:not(.home) .instagram-section .btn-cta:focus {
      background: #fff;
      color: #276069; }

.subscribe-section {
  background: #f1f1f1;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 20px;
  position: relative; }
  @media (min-width: 768px) {
    .subscribe-section:before {
      display: block;
      content: '';
      position: absolute;
      background: url("../images/ampersand.png");
      width: 400px;
      height: 500px;
      left: 0;
      top: 0;
      background-size: contain; } }

.subscribe-container {
  margin-bottom: 0px;
  margin-top: 20px;
  overflow: auto; }
  .subscribe-container h2 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700; }
  .subscribe-container h3 {
    text-align: center;
    text-transform: uppercase;
    font-family: "Roboto", sans-seirf;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 1px;
    margin-bottom: 30px; }

#mc_embed_signup input {
  width: 100%;
  padding: 10px;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #373f49;
  margin-bottom: 20px; }

#mc_embed_signup #mce-responses {
  margin: 15px 0; }

#mc_embed_signup #mc-embedded-subscribe {
  width: auto;
  border: 2px solid #276069;
  color: #276069;
  text-transform: uppercase;
  padding: 10px 30px;
  background: transparent;
  font-weight: 700;
  float: right;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 0; }
  @media (max-width: 991.98px) {
    #mc_embed_signup #mc-embedded-subscribe {
      float: none; } }
  #mc_embed_signup #mc-embedded-subscribe:hover {
    background: #276069;
    color: white;
    cursor: pointer; }

.dealer-archive #breadcrumbs, .custom-single-dealer #breadcrumbs {
  border-top: 2px solid #ccc;
  padding-top: 10px; }

.dealer-archive h1, .custom-single-dealer h1 {
  text-transform: uppercase; }
  @media (max-width: 1199.98px) {
    .dealer-archive h1, .custom-single-dealer h1 {
      font-size: 30px; } }

.dealer-archive .top-block-row, .custom-single-dealer .top-block-row {
  margin-top: 40px;
  margin-bottom: 40px; }
  .dealer-archive .top-block-row .content-block-right, .custom-single-dealer .top-block-row .content-block-right {
    background: #276069;
    color: white;
    padding: 20px; }
    .dealer-archive .top-block-row .content-block-right h2, .custom-single-dealer .top-block-row .content-block-right h2 {
      text-transform: uppercase; }
    .dealer-archive .top-block-row .content-block-right .button-holder, .custom-single-dealer .top-block-row .content-block-right .button-holder {
      margin-top: 20px;
      margin-bottom: 20px; }
      .dealer-archive .top-block-row .content-block-right .button-holder a, .custom-single-dealer .top-block-row .content-block-right .button-holder a {
        border: 2px solid #fff;
        color: #fff;
        background: transparent;
        text-transform: uppercase;
        padding: 10px 20px;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        text-align: center;
        display: block; }
        @media (max-width: 1199.98px) {
          .dealer-archive .top-block-row .content-block-right .button-holder a, .custom-single-dealer .top-block-row .content-block-right .button-holder a {
            display: block; } }
        .dealer-archive .top-block-row .content-block-right .button-holder a:hover, .custom-single-dealer .top-block-row .content-block-right .button-holder a:hover {
          background: white;
          color: #276069; }

.dealer-archive hr, .custom-single-dealer hr {
  width: 25%;
  border-color: #ccc;
  margin-top: 50px;
  margin-bottom: 50px;
  display: block; }

.dealer-archive .dealer-col, .custom-single-dealer .dealer-col {
  margin-bottom: 50px; }
  .dealer-archive .dealer-col a, .custom-single-dealer .dealer-col a {
    display: block;
    height: 100%;
    width: 100%; }
  .dealer-archive .dealer-col .archive-item, .custom-single-dealer .dealer-col .archive-item {
    background-image: url("https://d1ssu070pg2v9i.cloudfront.net/pex/ace_saac/2018/10/25170954/dealer-holding-image5.jpg");
    background-size: cover;
    background-position: center; }
  .dealer-archive .dealer-col:nth-child(even) .archive-item, .custom-single-dealer .dealer-col:nth-child(even) .archive-item {
    background-image: url("https://d1ssu070pg2v9i.cloudfront.net/pex/ace_saac/2018/10/25170922/dealer-holding-image3.jpg"); }
  .dealer-archive .dealer-col .has-products a:before, .custom-single-dealer .dealer-col .has-products a:before {
    content: 'product catalogue online';
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
    background: #276069;
    color: #fff;
    z-index: 100;
    text-transform: uppercase;
    font-size: 11px;
    padding: 4px 7px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    text-align: center;
    padding-top: 20px;
    z-index: 250;
    border: 1px solid transparent;
    -webkit-transition: 0.6s all ease;
    transition: 0.6s all ease; }
  .dealer-archive .dealer-col .has-products a:hover:before, .custom-single-dealer .dealer-col .has-products a:hover:before {
    top: -20px;
    border: 1px solid #fff; }

.dealer-archive button.become-dealer, .custom-single-dealer button.become-dealer {
  border: 2px solid #fff;
  color: #fff;
  background: transparent;
  text-transform: uppercase;
  padding: 10px 20px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  text-align: center;
  display: block; }
  @media (max-width: 1199.98px) {
    .dealer-archive button.become-dealer, .custom-single-dealer button.become-dealer {
      display: block; } }
  .dealer-archive button.become-dealer:hover, .custom-single-dealer button.become-dealer:hover {
    background: white;
    color: #276069; }

.dealer-archive .social, .custom-single-dealer .social {
  margin-bottom: 25px; }
  @media (min-width: 768px) {
    .dealer-archive .social, .custom-single-dealer .social {
      margin-bottom: 0; } }
  .dealer-archive .social a, .custom-single-dealer .social a {
    margin: 0 8px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
    .dealer-archive .social a:hover .svg-inline--fa, .custom-single-dealer .social a:hover .svg-inline--fa {
      color: #122d31; }
    .dealer-archive .social a .svg-inline--fa, .custom-single-dealer .social a .svg-inline--fa {
      -webkit-transition: color ease 0.3s;
      transition: color ease 0.3s;
      color: #276069; }

.become-a-dealer-custom {
  padding-bottom: 100px; }
  .become-a-dealer-custom h1 {
    margin-bottom: 30px; }
  .become-a-dealer-custom form label, .become-a-dealer-custom form textarea, .become-a-dealer-custom form .wpcf7-text {
    width: 100%;
    padding: 10px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #373f49;
    margin-bottom: 10px;
    background: white; }
  .become-a-dealer-custom form textarea {
    height: 99px; }
  .become-a-dealer-custom form .wpcf7-checkbox {
    width: auto; }
    .become-a-dealer-custom form .wpcf7-checkbox .wpcf7-list-item {
      display: block; }
  .become-a-dealer-custom form .button-holder {
    margin-bottom: 30px; }
    .become-a-dealer-custom form .button-holder .wpcf7-submit {
      border: 2px solid #276069;
      color: #276069;
      text-transform: uppercase;
      padding: 10px 30px;
      background: transparent;
      font-weight: 700;
      float: right;
      -webkit-transition: 0.3s;
      transition: 0.3s; }
      .become-a-dealer-custom form .button-holder .wpcf7-submit:hover {
        background: #276069;
        color: white; }
  .become-a-dealer-custom div.wpcf7-validation-errors, .become-a-dealer-custom div.wpcf7-acceptance-missing, .become-a-dealer-custom .wpcf7-response-output {
    margin-left: 0;
    margin-right: 0;
    margin-top: 40px; }
  .become-a-dealer-custom .form-holder {
    background: #f1f1f1;
    padding: 20px; }
    .become-a-dealer-custom .form-holder h2 {
      color: #276069; }

.custom-pagination {
  text-align: center;
  padding-bottom: 60px; }
  .custom-pagination .nav-links {
    width: auto;
    max-width: 100%; }
  .custom-pagination .page-numbers {
    border: 2px solid #276069;
    color: #276069;
    font-size: 20px;
    padding: 5px 10px;
    -webkit-transition: 0.3s;
    transition: 0.3s; }
    .custom-pagination .page-numbers:hover {
      background: #276069;
      color: white; }
    .custom-pagination .page-numbers.current {
      background: #276069;
      color: white; }
    .custom-pagination .page-numbers.prev, .custom-pagination .page-numbers.next {
      background: white !important;
      float: none !important;
      border-radius: 0; }
      .custom-pagination .page-numbers.prev:hover, .custom-pagination .page-numbers.next:hover {
        background: #276069 !important;
        color: white; }

.dealer-filter {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: white;
  z-index: 10000;
  padding-top: 10px; }

.top-dealers {
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .top-dealers {
      margin-bottom: 0; } }

.custom-locations #map {
  height: 600px; }

.custom-locations .loc-container {
  padding-top: 60px;
  padding-bottom: 60px;
  background-position: right -250px top -100px;
  background-repeat: no-repeat;
  background-size: 37%; }

.custom-locations h1, .custom-locations h2 {
  text-transform: uppercase;
  margin-bottom: 40px;
  font-size: 40px; }

.custom-locations .location-item {
  margin-bottom: 40px; }
  .custom-locations .location-item h3 {
    color: #276069;
    font-family: "Roboto", sans-seirf;
    margin-bottom: 20px; }
  .custom-locations .location-item ul {
    padding-left: 0; }
    .custom-locations .location-item ul li {
      list-style-type: none;
      font-size: 20px;
      color: #000; }
      .custom-locations .location-item ul li span {
        color: #276069; }
      .custom-locations .location-item ul li a {
        color: #000; }

.custom-locations .info-side {
  border-right: 2px solid #ccc; }
  @media (max-width: 1199.98px) {
    .custom-locations .info-side {
      border-right: 0; } }

.custom-locations .info-side, .custom-locations .location-form {
  padding: 0 50px; }
  @media (max-width: 1199.98px) {
    .custom-locations .info-side, .custom-locations .location-form {
      padding: 0; } }

.custom-locations .location-form input, .custom-locations .location-form select, .custom-locations .location-form textarea {
  width: 100%;
  padding: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #373f49;
  margin-bottom: 20px;
  background: transparent; }

.custom-locations .location-form .wpcf7-submit {
  width: auto;
  border: 2px solid #276069;
  color: #276069;
  text-transform: uppercase;
  padding: 10px 30px;
  background: transparent;
  font-weight: 700;
  float: right;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  .custom-locations .location-form .wpcf7-submit:hover {
    background-color: #276069;
    color: white; }

.templemans-type h1,
.templemans-type h2,
.templemans-type h3 {
  font-family: "p22-underground", sans-serif;
  color: #334b63; }

.templemans-type .hero-slider {
  background: #6d88a3; }

.templemans-type header .mobile-header-tools a {
  color: white; }

.templemans-type .archive-item {
  border: 2px solid #6d88a3; }

.templemans-type .temple-hr {
  border: 1px solid #6d88a3;
  margin-bottom: 60px; }

.templemans-type .btn-outline-secondary:hover {
  background: #6d88a3; }

@media (min-width: 768px) {
  .templemans-type .featured-categories {
    margin-top: 100px; } }

@media (min-width: 768px) {
  .templemans-type .featured-categories .featured-item:before {
    content: '';
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
    -webkit-transition: 0.3s border ease;
    transition: 0.3s border ease; } }

.templemans-type .featured-categories .featured-item .feature-inner h3 {
  font-family: "p22-underground", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease; }

@media (min-width: 768px) {
  .templemans-type .featured-categories a:hover .featured-item:before {
    border: 1px solid white; } }

.templemans-type .featured-categories a:hover .featured-item .feature-inner h3 {
  background: #6d88a3;
  color: #fff; }

.templemans-type .featured-categories-v2_row {
  display: grid;
  grid-template-columns: 1fr;
  -webkit-column-gap: 2rem;
     -moz-column-gap: 2rem;
          column-gap: 2rem;
  row-gap: 4rem; }
  @media (min-width: 576px) {
    .templemans-type .featured-categories-v2_row {
      grid-template-columns: 1fr 1fr; } }
  @media (min-width: 992px) {
    .templemans-type .featured-categories-v2_row {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      -webkit-column-gap: 4rem;
         -moz-column-gap: 4rem;
              column-gap: 4rem; } }

.templemans-type .featured-categories-v2_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 1rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  background-color: #F7F7F7;
  color: #1E364E;
  margin: 0;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem; }
  .templemans-type .featured-categories-v2_item:hover .featured-categories-v2_image {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  .templemans-type .featured-categories-v2_item:hover .featured-categories-v2_title {
    text-decoration: underline; }

.templemans-type .featured-categories-v2_image-wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%; }

.templemans-type .featured-categories-v2_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

.templemans-type .featured-categories-v2_title {
  font-weight: 800;
  color: #1E364E;
  margin-bottom: 0; }

.templemans-type .featured-categories-v2_tagline {
  font-family: "p22-underground", sans-serif; }

.templemans-type .featured-categories-v2_button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 0.75rem;
     -moz-column-gap: 0.75rem;
          column-gap: 0.75rem;
  color: #fff;
  background-color: #6d88a3;
  padding: 0.5rem 1rem;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: calc(((1rem * 1.7) - 0.5rem) * -1);
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease; }
  .templemans-type .featured-categories-v2_button:hover {
    background-color: #556f88; }
  .templemans-type .featured-categories-v2_button > svg {
    font-size: 1.25rem; }

@media (min-width: 768px) {
  .templemans-type .subscribe-section:before {
    background-image: url("../images/Templemans-t.png"); } }

.templemans-type .subscribe-container h2 {
  font-family: "Roboto", sans-seirf;
  font-weight: 400; }

.templemans-type .subscribe-container .submit-col .wpcf7-submit {
  color: #6d88a3;
  border: 1px solid #6d88a3;
  border-radius: 0px; }
  .templemans-type .subscribe-container .submit-col .wpcf7-submit:hover {
    background: #6d88a3;
    color: white; }

.templemans-type .subscribe-container .sub-first-name,
.templemans-type .subscribe-container .sub-last-name,
.templemans-type .subscribe-container .sub-email {
  border: 1px solid #d2d2d2; }

.templemans-type a {
  color: #6d88a3; }

.templemans-type .content-container .inner {
  padding: 20px 20px 20px 20px;
  border-bottom: 2px solid #6d88a3;
  border-right: 2px solid #6d88a3;
  border-left: 2px solid #6d88a3;
  z-index: 10000;
  position: relative; }
  .templemans-type .content-container .inner p {
    margin-bottom: 0;
    font-size: 18px;
    color: #1e364e; }
  .templemans-type .content-container .inner .opening {
    text-align: right;
    font-weight: bold;
    font-style: italic; }

.templemans-type .main_nav li a {
  border-bottom: 3px solid transparent; }
  .templemans-type .main_nav li a:hover {
    color: white;
    border-bottom: 3px solid #B7DBFF; }

.templemans-type .hero-slider .hero-text .hero-inner .the-border {
  border: 1px solid white;
  padding: 10px; }
  .templemans-type .hero-slider .hero-text .hero-inner .the-border .top {
    background: white;
    padding: 25px 10px; }
    .templemans-type .hero-slider .hero-text .hero-inner .the-border .top h2 {
      color: #0C2741;
      text-transform: uppercase;
      margin-bottom: 0;
      font-family: "p22-underground", sans-serif;
      font-size: 37px;
      padding-top: 4px; }
    .templemans-type .hero-slider .hero-text .hero-inner .the-border .top p {
      color: #6d88a3;
      text-transform: uppercase;
      font-size: 18px;
      letter-spacing: 4px;
      margin-bottom: 0; }

.templemans-type .hero-slider .hero-text .hero-inner .bottom {
  margin-top: 30px; }
  .templemans-type .hero-slider .hero-text .hero-inner .bottom a {
    text-transform: uppercase;
    background: #6d88a3;
    /* The Fallback */
    background: rgba(109, 136, 163, 0.8);
    color: #ffffff;
    border: 1px solid #fff;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    font-weight: 400; }
    .templemans-type .hero-slider .hero-text .hero-inner .bottom a:hover {
      background: #6d88a3;
      /* The Fallback */
      background: #6d88a3; }

@media (min-width: 576px) {
  .templemans-type .hero-slider .hero-text .hero-inner:before {
    -webkit-box-shadow: 0 0 200px 190px rgba(0, 0, 0, 0.15), 0 0 275px 150px rgba(0, 0, 0, 0.33);
            box-shadow: 0 0 200px 190px rgba(0, 0, 0, 0.15), 0 0 275px 150px rgba(0, 0, 0, 0.33); } }

.templemans-type .related.products h2 {
  font-family: "p22-underground", sans-serif; }

.templemans-type .related.products .products li.product {
  border: 1px solid #6d88a3; }

.templemans-type .site-footer .social-media a svg {
  color: #6d88a3; }

.templemans-type .site-footer #scroll-to-top {
  border-radius: 0px; }

.templemans-type .loc-container .location-item h3 {
  color: #6d88a3; }

.templemans-type .loc-container .location-item ul li span {
  color: #6d88a3; }

.templemans-type .page-numbers {
  border: 2px solid #6d88a3;
  color: #6d88a3; }
  .templemans-type .page-numbers:hover {
    background: #6d88a3;
    color: white; }
  .templemans-type .page-numbers.current {
    background: #6d88a3; }
  .templemans-type .page-numbers.prev:hover, .templemans-type .page-numbers.next:hover {
    background: #6d88a3 !important;
    color: white; }

.templemans-type #mc_embed_signup #mc-embedded-subscribe {
  border: 2px solid #6d88a3;
  color: #6d88a3; }
  .templemans-type #mc_embed_signup #mc-embedded-subscribe:hover {
    background: #6d88a3;
    color: white; }

.templemans-type .location-form .wpcf7-submit {
  border: 2px solid #6d88a3;
  color: #6d88a3; }
  .templemans-type .location-form .wpcf7-submit:hover {
    background-color: #6d88a3; }

.hero-slider-templemans .hero-inner .top h1 {
  color: #0C2741;
  text-transform: uppercase;
  margin-bottom: 0;
  font-family: "p22-underground", sans-serif;
  font-size: 37px;
  padding-top: 4px; }
  .hero-slider-templemans .hero-inner .top h1 span {
    color: #6d88a3;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 4px;
    margin-bottom: 0;
    display: block;
    font-weight: 400; }

.hp-featured-products .hp-product {
  display: block;
  position: relative;
  text-align: center; }
  .hp-featured-products .hp-product:hover .hp-product_image {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  .hp-featured-products .hp-product:hover .hp-product_title {
    font-weight: 700; }
  .hp-featured-products .hp-product_image-wrapper {
    position: relative;
    padding-top: 100%;
    border: 1px solid #6d88a3;
    overflow: hidden;
    margin-bottom: 0.75rem; }
  .hp-featured-products .hp-product_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
  .hp-featured-products .hp-product_sale-badge {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    background-color: #B2134A;
    color: #fff;
    font-size: 0.85rem;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0.2rem 0.75rem;
    z-index: 2; }
  .hp-featured-products .hp-product_title {
    font-family: "p22-underground", sans-serif;
    color: #1f364e;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
    text-decoration: underline; }
  .hp-featured-products .hp-product_price {
    color: #1E364E;
    font-size: 1.125rem; }
  .hp-featured-products .hp-product_original-price {
    position: relative;
    display: inline-block;
    color: rgba(31, 54, 78, 0.5);
    font-size: 1rem;
    margin-right: 0.25rem; }
    .hp-featured-products .hp-product_original-price:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 100%;
      border-top: 1.5px solid #B2134A;
      left: 0;
      -webkit-transform: rotate(18deg);
              transform: rotate(18deg); }

.hp-content-wrapper {
  border: 1px solid #6d88a3;
  margin-top: 50px;
  padding: 20px; }
  @media (min-width: 992px) {
    .hp-content-wrapper {
      padding: 100px; } }
  .hp-content-wrapper .content-title {
    text-align: center; }
    .hp-content-wrapper .content-title h2 {
      text-transform: uppercase; }
      @media (min-width: 768px) {
        .hp-content-wrapper .content-title h2 {
          font-size: 50px;
          margin-bottom: 0px; } }
    .hp-content-wrapper .content-title .subtitle-links {
      list-style: none;
      padding-left: 0px; }
      .hp-content-wrapper .content-title .subtitle-links li.link {
        display: inline-block;
        text-transform: uppercase;
        font-size: 18px; }
        .hp-content-wrapper .content-title .subtitle-links li.link:before {
          content: "\2022 "; }
        .hp-content-wrapper .content-title .subtitle-links li.link:first-child:before {
          content: unset !important; }
        .hp-content-wrapper .content-title .subtitle-links li.link a {
          font-weight: 200; }
          .hp-content-wrapper .content-title .subtitle-links li.link a:hover {
            text-decoration: underline; }
  .hp-content-wrapper .content-wrapper {
    padding-bottom: 20px;
    padding-top: 20px; }
    @media (min-width: 992px) {
      .hp-content-wrapper .content-wrapper {
        -webkit-column-count: 2;
           -moz-column-count: 2;
                column-count: 2;
        padding-top: 40px;
        -webkit-column-gap: 50px;
           -moz-column-gap: 50px;
                column-gap: 50px; } }
  .hp-content-wrapper .content-menu {
    list-style: none;
    padding-left: 0px;
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2; }
    @media (min-width: 992px) {
      .hp-content-wrapper .content-menu {
        -webkit-column-count: 3;
           -moz-column-count: 3;
                column-count: 3; } }
    .hp-content-wrapper .content-menu li a {
      font-family: p22-underground, sans-serif;
      width: 100%;
      display: block; }
      .hp-content-wrapper .content-menu li a:hover {
        text-decoration: underline; }
  .hp-content-wrapper .bottom-left-content {
    font-size: 19px;
    color: #6d88a3;
    font-family: p22-underground, sans-serif; }
    @media (min-width: 768px) {
      .hp-content-wrapper .bottom-left-content {
        width: 80%; } }
  .hp-content-wrapper .address a,
  .hp-content-wrapper .address p {
    color: #6d88a3;
    font-family: p22-underground, sans-serif; }
  .hp-content-wrapper .address a:hover {
    text-decoration: underline; }

.hp-opening-wrapper {
  width: 100%;
  background-color: #6d88a3;
  color: #fff;
  min-height: 89px; }
  .hp-opening-wrapper .hp-opening {
    display: inline-block;
    font-size: 23px;
    padding: 20px 20px 10px;
    margin: 0px; }
    @media (min-width: 992px) {
      .hp-opening-wrapper .hp-opening {
        padding: 20px 0 0 100px; } }
  .hp-opening-wrapper .content-logo {
    display: none; }
    @media (min-width: 992px) {
      .hp-opening-wrapper .content-logo {
        display: inline-block;
        float: right;
        background-color: #fff; }
        .hp-opening-wrapper .content-logo img {
          max-height: 91px;
          margin-top: -1px; } }

@media (min-width: 768px) {
  .templemans-content-block_inner-wrapper {
    padding-left: 3rem;
    padding-right: 3rem; } }

.templemans-content-block p {
  font-size: 1.125rem;
  color: #637894;
  font-weight: 400; }

.templemans-content-block_image-col {
  margin-bottom: 3rem; }
  @media (min-width: 992px) {
    .templemans-content-block_image-col {
      padding-right: 4rem;
      margin-bottom: 0; } }

.templemans-content-block_paragraphs > p:last-child {
  margin-bottom: 0; }

.templemans-content-block_h3 {
  color: #637894 !important; }

.templemans-content-block_sub-heading {
  text-transform: uppercase;
  font-family: "p22-underground", sans-serif;
  font-weight: 500;
  color: #1f364e;
  font-size: 0.85rem; }

@media (min-width: 992px) {
  .templemans-content-block.second .templemans-content-block_image-col {
    padding-left: 4rem;
    padding-right: 25px; } }

.templemans-content-block.second p {
  color: #1f364e; }

.templemans-content-block_links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-column-gap: 1.5rem;
     -moz-column-gap: 1.5rem;
          column-gap: 1.5rem;
  row-gap: 1.5rem;
  margin-top: 2rem; }

.search-results .active.menu-item.menu-news {
  border: 0; }

.custom-search-results {
  border-top: 2px solid #ccc;
  padding-top: 60px;
  padding-bottom: 60px; }
  .custom-search-results h1 {
    margin-bottom: 30px; }
  .custom-search-results .custom-search-result {
    font-weight: 700;
    border-top: 2px solid #ccc;
    padding-top: 20px;
    padding-bottom: 20px; }
    .custom-search-results .custom-search-result h3 a {
      font-weight: 700;
      font-size: 30px; }
    .custom-search-results .custom-search-result p {
      font-size: 18px;
      margin: 0; }

.search .search-results-container {
  padding-bottom: 15px; }
  .search .search-results-container .search-results-wrapper {
    display: block;
    position: relative;
    max-width: 300px; }
    .search .search-results-container .search-results-wrapper:after {
      content: "View More";
      display: block;
      position: absolute;
      top: 40%;
      width: auto;
      width: 96%;
      left: 2%;
      margin: 0 auto;
      padding: 20px;
      opacity: 0;
      text-transform: uppercase;
      background: rgba(255, 255, 255, 0.93);
      text-align: center;
      font-family: Roboto,sans-seirf;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
    .search .search-results-container .search-results-wrapper:hover:after {
      opacity: 1;
      top: 30%; }
    .search .search-results-container .search-results-wrapper .search-img {
      border: 1px solid #276069; }
    .search .search-results-container .search-results-wrapper .search-result-title {
      font-size: 18px;
      font-weight: 700;
      margin-top: 15px; }
    .search .search-results-container .search-results-wrapper .search-result-excerpt {
      font-size: 17px;
      font-weight: 400;
      color: #000; }

.top-section {
  margin-bottom: 40px;
  padding-top: 50px;
  background-color: #276069; }
  @media screen and (min-width: 1200px) {
    .top-section {
      margin-bottom: 90px; } }
  .top-section .row {
    background-color: white; }
  .top-section .top-section-left {
    padding: 30px;
    color: white;
    background-color: #276069;
    margin-bottom: 70px; }
    @media screen and (min-width: 992px) {
      .top-section .top-section-left {
        padding: 30px 50px 20px 25px; } }
    @media screen and (min-width: 1200px) {
      .top-section .top-section-left {
        padding: 30px 70px 20px 130px; } }
    .top-section .top-section-left h1 {
      font-size: 30px;
      font-weight: 500; }
      @media screen and (min-width: 576px) {
        .top-section .top-section-left h1 {
          font-size: 60px;
          font-weight: 600;
          margin-bottom: 0px; } }
    .top-section .top-section-left h2 {
      font-family: "Roboto", sans-seirf;
      font-weight: 100;
      margin-bottom: 20px; }
    .top-section .top-section-left p {
      margin-bottom: 30px; }
    .top-section .top-section-left .ctas {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      -webkit-box-pack: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly; }
      @media screen and (min-width: 992px) {
        .top-section .top-section-left .ctas {
          display: block;
          width: unset;
          -webkit-box-pack: unset;
              -ms-flex-pack: unset;
                  justify-content: unset; } }
      .top-section .top-section-left .ctas .cta1 {
        border: 1px solid white;
        border-radius: 4px;
        color: white;
        padding: 5px 15px; }
      .top-section .top-section-left .ctas .cta2 {
        color: white;
        text-decoration: underline;
        padding: 5px 15px; }
      .top-section .top-section-left .ctas a {
        font-size: 16px; }
  .top-section .top-section-right {
    padding: 0px; }
    .top-section .top-section-right img {
      width: 100%;
      max-width: 100%; }

.floor-plan-section .floor-plan-title {
  font-size: 60px;
  font-weight: 600;
  color: #276069; }

.floor-plan-section h2, .floor-plan-section p {
  text-align: center; }

.floor-plan-section h2 {
  font-size: 40px; }
  @media screen and (min-width: 992px) {
    .floor-plan-section h2 {
      font-size: 60px; } }

.floor-plan-section p {
  font-weight: 100; }

.floor-plan-section img {
  width: 100%; }

.fifty-fifty-section {
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 30px;
  margin-bottom: 30px; }
  @media screen and (min-width: 1200px) {
    .fifty-fifty-section {
      padding-left: 130px;
      padding-right: 130px;
      margin-top: 150px;
      margin-bottom: 120px; } }
  .fifty-fifty-section img {
    max-width: 100%;
    width: 100%; }
  .fifty-fifty-section #map {
    height: 100%; }
  .fifty-fifty-section .fifty-fifty-content {
    padding: 50px 0px; }
    @media screen and (min-width: 992px) {
      .fifty-fifty-section .fifty-fifty-content {
        padding: 20px 20px; } }
    @media screen and (min-width: 1500px) {
      .fifty-fifty-section .fifty-fifty-content {
        padding: 70px 45px; } }
    .fifty-fifty-section .fifty-fifty-content h2 {
      color: #276069;
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 20px; }
      @media screen and (min-width: 992px) {
        .fifty-fifty-section .fifty-fifty-content h2 {
          font-size: 40px;
          line-height: 35px; } }
      @media screen and (min-width: 1500px) {
        .fifty-fifty-section .fifty-fifty-content h2 {
          font-size: 60px;
          line-height: 55px; } }
      .fifty-fifty-section .fifty-fifty-content h2 span {
        font-size: 35px;
        font-weight: 600;
        display: block;
        margin-bottom: 0px; }
        @media screen and (min-width: 992px) {
          .fifty-fifty-section .fifty-fifty-content h2 span {
            font-size: 28px; } }
    .fifty-fifty-section .fifty-fifty-content p {
      margin-bottom: 30px; }
    .fifty-fifty-section .fifty-fifty-content .contact {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      margin-bottom: 25px; }
      @media screen and (min-width: 992px) {
        .fifty-fifty-section .fifty-fifty-content .contact {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row; } }
      .fifty-fifty-section .fifty-fifty-content .contact a {
        margin-right: 20px; }
        @media screen and (min-width: 1500px) {
          .fifty-fifty-section .fifty-fifty-content .contact a {
            margin-right: 30px; } }
      .fifty-fifty-section .fifty-fifty-content .contact svg {
        margin-right: 5px; }
    .fifty-fifty-section .fifty-fifty-content .fifty-fifty-button {
      background-color: #276069;
      color: white;
      border-radius: 4px; }
  .fifty-fifty-section .fifty-fifty-image {
    padding-left: 0px;
    padding-right: 0px; }

.gallery-section {
  margin-top: 30px;
  margin-bottom: 30px; }
  .gallery-section .hero-slider .swiper-nav {
    width: 90%;
    left: 5%;
    top: 40%; }
    .gallery-section .hero-slider .swiper-nav [class*=swiper-button-] {
      background-image: none !important;
      width: 50px;
      height: 50px;
      border-radius: 4px;
      background: #276069; }
      .gallery-section .hero-slider .swiper-nav [class*=swiper-button-] > * {
        margin: 0 auto; }
  .gallery-section .hero-slider .hero-text {
    display: none !important; }
  @media screen and (min-width: 992px) {
    .gallery-section {
      margin-top: 150px;
      margin-bottom: 180px; } }
  .gallery-section h2 {
    text-align: center;
    color: #276069;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .gallery-section h2 {
        font-size: 60px; } }
  .gallery-section .swiper-wrapper {
    height: auto; }
    .gallery-section .swiper-wrapper .gallery-image img {
      max-width: 100%;
      height: 400px;
      width: 100%;
      -o-object-fit: cover;
         object-fit: cover; }

.templemans-type .saac-hr {
  display: none; }

.templemans-type .maggies-banner {
  border: 1px solid #6d88a3; }
  .templemans-type .maggies-banner h2 {
    font-weight: 700;
    text-transform: uppercase; }

.saac-type .maggies-banner h2 {
  text-transform: uppercase; }

.saac-type .saac-hr {
  width: 30%;
  margin: 0 auto;
  border-top: 1px solid rgba(0, 0, 0, 0.3); }
  .saac-type .saac-hr.top {
    margin-top: 60px; }
    @media (max-width: 1199.98px) {
      .saac-type .saac-hr.top {
        margin-top: 0; } }
  .saac-type .saac-hr.bottom {
    margin-bottom: 60px; }
    @media (max-width: 1199.98px) {
      .saac-type .saac-hr.bottom {
        margin-bottom: 0; } }

.maggies-banner {
  padding: 70px 100px 50px 100px; }
  @media (max-width: 1199.98px) {
    .maggies-banner {
      padding: 70px 25px; }
      .maggies-banner .img-inner {
        display: block !important; } }
  @media (max-width: 991.98px) {
    .maggies-banner img {
      margin-bottom: 40px; } }
  .maggies-banner a {
    color: #fa9d6f;
    text-decoration: underline;
    -webkit-transition: 0.3s;
    transition: 0.3s; }
    .maggies-banner a:hover {
      text-decoration: none; }

.templemans-slideshow {
  display: grid;
  grid-template-columns: auto 38%; }
  @media (max-width: 991.98px) {
    .templemans-slideshow {
      grid-template-columns: 1fr; } }
  .templemans-slideshow_container {
    background-color: #F7F7F7;
    padding-bottom: 3rem; }
    @media (min-width: 768px) {
      .templemans-slideshow_container {
        padding-top: 3rem;
        background-color: transparent; } }
  .templemans-slideshow_content-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: #F7F7F7;
    padding: 4rem; }
    @media (max-width: 991.98px) {
      .templemans-slideshow_content-wrapper {
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2;
        padding: 0;
        padding-top: 2rem; } }
    .templemans-slideshow_content-wrapper_inner {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      width: 70%; }
      @media (max-width: 991.98px) {
        .templemans-slideshow_content-wrapper_inner {
          width: 100%; } }
      @media (max-width: 991.98px) {
        .templemans-slideshow_content-wrapper_inner .display-4 {
          font-size: 1.5rem; } }
  .templemans-slideshow_stats {
    border-top: 1px solid rgba(112, 112, 112, 0.15);
    padding-top: 2rem;
    margin-top: 2rem; }
    .templemans-slideshow_stats > .row {
      row-gap: 1rem; }
  .templemans-slideshow_stat-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-weight: 700; }
    @media (max-width: 991.98px) {
      .templemans-slideshow_stat-wrapper {
        font-size: 0.75rem; } }
    .templemans-slideshow_stat-wrapper .h2 {
      font-weight: 700; }
      @media (max-width: 991.98px) {
        .templemans-slideshow_stat-wrapper .h2 {
          font-size: 1.6rem; } }
    .templemans-slideshow_stat-wrapper svg {
      font-size: 1.75rem;
      margin-right: 0.75rem; }
      @media (max-width: 991.98px) {
        .templemans-slideshow_stat-wrapper svg {
          font-size: 1.45rem; } }
  .templemans-slideshow_stat {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; }
    @media (max-width: 991.98px) {
      .templemans-slideshow_stat {
        -webkit-box-flex: 0;
            -ms-flex-positive: 0;
                flex-grow: 0; } }
  .templemans-slideshow_swiper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .templemans-slideshow_swiper_container {
      position: relative;
      padding-top: 100%; }
      @media (max-width: 991.98px) {
        .templemans-slideshow_swiper_container {
          -webkit-box-ordinal-group: 2;
              -ms-flex-order: 1;
                  order: 1;
          margin-bottom: 3rem; } }
    .templemans-slideshow_swiper_slide {
      position: relative; }
      .templemans-slideshow_swiper_slide_image {
        width: 100%; }
      .templemans-slideshow_swiper_slide_content-wrapper {
        position: absolute;
        top: 4rem;
        left: 4rem;
        min-width: calc(50% - 2rem);
        max-width: calc(100% - 8rem);
        background-color: rgba(31, 54, 78, 0.75);
        padding: 1rem;
        border: 1px solid #fff; }
        @media (max-width: 991.98px) {
          .templemans-slideshow_swiper_slide_content-wrapper {
            top: 2rem;
            left: 2rem;
            min-width: calc(50% - 1rem);
            max-width: calc(100% - 4rem); } }
        .templemans-slideshow_swiper_slide_content-wrapper > h1 {
          color: #fff; }
          @media (max-width: 991.98px) {
            .templemans-slideshow_swiper_slide_content-wrapper > h1 {
              font-size: 1.2rem; } }
    .templemans-slideshow_swiper_navigation {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: 0.85rem;
      -webkit-column-gap: 0.5rem;
         -moz-column-gap: 0.5rem;
              column-gap: 0.5rem;
      margin-top: 0.4rem;
      font-weight: 700; }
      .templemans-slideshow_swiper_navigation_wrapper {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding-top: 0.4rem; }
    .templemans-slideshow_swiper_scrollbar {
      height: 0.4rem;
      background-color: rgba(109, 136, 163, 0.27);
      border-radius: 0; }
      .templemans-slideshow_swiper_scrollbar > .swiper-scrollbar-drag {
        background-color: #6d88a3;
        border-radius: 0; }
    .templemans-slideshow_swiper_prev.swiper-button-disabled, .templemans-slideshow_swiper_next.swiper-button-disabled {
      opacity: 0.25;
      cursor: not-allowed; }

.woocommerce-cart h1 {
  border-top: 2px solid #ccc;
  text-transform: uppercase;
  color: #276069;
  font-weight: 700;
  margin-bottom: 20px;
  padding-top: 20px; }

.woocommerce-cart h2 {
  text-transform: uppercase;
  color: #276069;
  font-weight: 700;
  margin-bottom: 20px;
  padding-top: 20px; }

.woocommerce-cart .return-to-shop {
  margin-bottom: 50px; }
  .woocommerce-cart .return-to-shop.not-empty {
    display: inline-block;
    margin-right: 20px;
    width: 100%;
    text-align: center;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .woocommerce-cart .return-to-shop.not-empty {
        margin-bottom: 0px;
        width: auto; } }
  .woocommerce-cart .return-to-shop a {
    color: white;
    background-color: #276069;
    border: 2px solid #276069; }
    .woocommerce-cart .return-to-shop a:hover {
      background: transparent;
      color: #276069; }

.woocommerce-cart .wc-proceed-to-checkout a {
  background-color: #276069 !important;
  border: 2px solid #276069;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  .woocommerce-cart .wc-proceed-to-checkout a:hover {
    background-color: white !important;
    color: #276069 !important; }

.woocommerce-cart .cart_totals {
  margin-bottom: 50px; }

.woocommerce-checkout h1 {
  border-top: 2px solid #ccc;
  text-transform: uppercase;
  color: #276069;
  font-weight: 700;
  margin-bottom: 20px;
  padding-top: 20px; }

.woocommerce-checkout h2 {
  text-transform: uppercase; }

.woocommerce-checkout h3 {
  text-transform: uppercase; }

.woocommerce-checkout #order_review_heading {
  margin-top: 50px; }

.woocommerce-checkout #place_order {
  background-color: #276069;
  border: 2px solid #276069;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  .woocommerce-checkout #place_order:hover {
    background-color: transparent !important;
    color: #276069 !important; }

.woocommerce-checkout .woocommerce-checkout-payment {
  margin-bottom: 40px; }

.woocommerce-checkout .woocommerce-thankyou-order-details.order_details {
  padding-left: 0; }

.woocommerce-checkout .woocommerce-customer-details {
  margin-bottom: 50px; }

.woocommerce-checkout .woocommerce-input-wrapper {
  width: 100%; }

.woocommerce-account h1 {
  border-top: 2px solid #ccc;
  text-transform: uppercase;
  color: #276069;
  font-weight: 700;
  margin-bottom: 20px;
  padding-top: 20px; }

.woocommerce-account h3 {
  text-transform: uppercase; }

.woocommerce .col2-set .col-2, .woocommerce-page .col2-set .col-2, .woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-1 {
  max-width: 100%;
  padding: 0; }

.woocommerce-form__label-for-checkbox {
  width: 100%;
  margin-top: 20px; }

.woocommerce-password-strength {
  margin-top: 20px; }

.woocommerce-address-fields .input-text, .edit-account .input-text, #customer_login .input-text, .woocommerce-checkout .input-text {
  height: 48px;
  border: 1px solid #276069;
  padding: 10px; }

@media (max-width: 767.98px) {
  .woocommerce-address-fields .validate-phone, .woocommerce-address-fields .validate-email, .edit-account .validate-phone, .edit-account .validate-email, #customer_login .validate-phone, #customer_login .validate-email, .woocommerce-checkout .validate-phone, .woocommerce-checkout .validate-email {
    width: 100% !important;
    float: none !important; } }

.woocommerce-address-fields input.button, .edit-account input.button, #customer_login input.button, .woocommerce-checkout input.button {
  background: #276069;
  color: white;
  font-weight: 700;
  float: right;
  margin-top: 20px; }
  @media (max-width: 767.98px) {
    .woocommerce-address-fields input.button, .edit-account input.button, #customer_login input.button, .woocommerce-checkout input.button {
      float: none !important; } }

.woocommerce-info {
  border-top-color: #276069; }
  .woocommerce-info::before {
    color: #276069; }

.woocommerce-MyAccount-navigation {
  background: #276069;
  margin-bottom: 40px; }
  .woocommerce-MyAccount-navigation ul {
    padding-left: 0; }
    .woocommerce-MyAccount-navigation ul li {
      list-style-type: none;
      padding: 10px; }
      .woocommerce-MyAccount-navigation ul li a {
        color: white;
        padding: 10px; }
        .woocommerce-MyAccount-navigation ul li a:hover {
          text-decoration: underline; }

.woocommerce-MyAccount-content {
  border: 2px solid #276069;
  padding: 10px;
  margin-bottom: 40px; }
  .woocommerce-MyAccount-content p:last-child {
    margin-bottom: 0; }

.post-type-archive-product span.onsale, .archive.tax-product_cat span.onsale {
  padding: 0; }

@media (max-width: 767.98px) {
  .post-type-archive-product ul.products li.type-product, .archive.tax-product_cat ul.products li.type-product {
    width: 48.1%; }
    .post-type-archive-product ul.products li.type-product:nth-child(2n), .archive.tax-product_cat ul.products li.type-product:nth-child(2n) {
      margin-right: 0; } }

.post-type-archive-product ul.products li.type-product img, .archive.tax-product_cat ul.products li.type-product img {
  border: 2px solid #276069; }

@media (max-width: 767.98px) {
  .post-type-archive-product .woocommerce-result-count, .archive.tax-product_cat .woocommerce-result-count {
    width: 100%; } }

.post-type-archive-product .woocommerce-ordering, .archive.tax-product_cat .woocommerce-ordering {
  width: 100%;
  margin-bottom: 0; }
  .post-type-archive-product .woocommerce-ordering select, .archive.tax-product_cat .woocommerce-ordering select {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px 15px;
    border: 3px solid #276069; }
  @media (min-width: 576px) {
    .post-type-archive-product .woocommerce-ordering, .archive.tax-product_cat .woocommerce-ordering {
      width: auto; }
      .post-type-archive-product .woocommerce-ordering select, .archive.tax-product_cat .woocommerce-ordering select {
        margin-left: 1em;
        width: auto; } }

.post-type-archive-product .woocommerce-breadcrumb, .archive.tax-product_cat .woocommerce-breadcrumb {
  font-size: 1rem; }

.post-type-archive-product.templemans-type li.type-product img, .archive.tax-product_cat.templemans-type li.type-product img {
  border: 1px solid #6d88a3; }

.post-type-archive-product.templemans-type .woocommerce-ordering select, .archive.tax-product_cat.templemans-type .woocommerce-ordering select {
  border-color: #6d88a3; }

.post-type-archive-product.templemans-type .location-filter, .archive.tax-product_cat.templemans-type .location-filter {
  display: none; }

.out-of-stock-label {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #6d88a3;
  color: white;
  padding: 10px; }

h1.woocommerce-products-header__title {
  margin-bottom: 25px; }

.archive.tax-product_cat .archive-col {
  margin-bottom: 50px; }
  .archive.tax-product_cat .archive-col:last-of-type {
    margin-bottom: 75px; }

.woocommerce-info {
  margin-bottom: 60px; }

.single-product.woocommerce .variations select {
  width: 100%;
  padding: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #373f49; }

.single-product.woocommerce .dealer-link {
  color: grey;
  text-decoration: underline;
  font-size: 24px; }

.single-product.woocommerce h1 {
  font-weight: 700;
  margin-bottom: 30px; }

.single-product.woocommerce .summary {
  /*strong {
            font-size: 24px;
        }*/ }
  .single-product.woocommerce .summary .price {
    font-size: 26px;
    color: #000;
    font-weight: 700; }
  .single-product.woocommerce .summary .woocommerce-product-details, .single-product.woocommerce .summary .woocommerce-product-details__short-description {
    padding: 15px;
    border: 1px solid #000;
    margin-bottom: 25px; }
  .single-product.woocommerce .summary .single_add_to_cart_button {
    display: block;
    margin: 0 auto; }
  .single-product.woocommerce .summary a.wishlist {
    text-transform: uppercase;
    display: block;
    margin: 0 auto;
    font-weight: 300;
    color: #373f49;
    text-decoration: underline;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 25px; }

.single-product.woocommerce .up-sells {
  display: inline-block;
  text-align: center;
  width: 100%; }
  .single-product.woocommerce .up-sells .row li {
    position: relative;
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
    margin: 0;
    margin-bottom: 25px; }
    .single-product.woocommerce .up-sells .row li img {
      border: 4px solid #276069; }
    @media (min-width: 768px) {
      .single-product.woocommerce .up-sells .row li {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
                flex: 0 0 50%;
        max-width: 50%; } }
    @media (min-width: 992px) {
      .single-product.woocommerce .up-sells .row li {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 33.33333%;
                flex: 0 0 33.33333%;
        max-width: 33.33333%; } }
  .single-product.woocommerce .up-sells h2 {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 25px;
    background: white;
    display: inline-block;
    padding: 0 15px; }
  .single-product.woocommerce .up-sells hr {
    border-color: #ced4da;
    margin-bottom: -20px; }
    @media (min-width: 576px) {
      .single-product.woocommerce .up-sells hr {
        max-width: 90%; } }
    @media (min-width: 768px) {
      .single-product.woocommerce .up-sells hr {
        max-width: 65%; } }
    @media (min-width: 992px) {
      .single-product.woocommerce .up-sells hr {
        max-width: 430px; } }

.single-product.woocommerce.templemans-type .woocommerce-product-details, .single-product.woocommerce.templemans-type .woocommerce-product-details__short-description {
  border: none;
  background: #eeeeee; }

.single-product.woocommerce.templemans-type .up-sells h2 {
  font-family: Roboto;
  font-weight: 500; }

.single-product.woocommerce.templemans-type .up-sells hr {
  display: none; }

.single-product.woocommerce.templemans-type .up-sells .row li img {
  border-color: #6d88a3; }

.single-product.woocommerce.templemans-type .woocommerce-product-gallery {
  padding: 30px;
  background: #eeeeee; }

.single-product.woocommerce .out-of-stock {
  border: 1px solid red;
  padding: 10px;
  text-align: center; }

.single-product.woocommerce .low-stock {
  border: 1px solid orange;
  padding: 10px;
  text-align: center;
  margin-bottom: 25px; }
  .single-product.woocommerce .low-stock p {
    color: orange;
    margin-bottom: 0; }

.single-product.woocommerce .single-product-price {
  width: 50%;
  display: inline-block; }

.single-product.woocommerce .free-delivery {
  float: right;
  font-size: 23px; }

.contact-us-to-purchase {
  margin-bottom: 30px; }

#contactToPurchaseModal .modal-dialog-centered {
  max-width: 50%;
  margin: 0 auto; }
  @media (max-width: 1199.98px) {
    #contactToPurchaseModal .modal-dialog-centered {
      max-width: 75%; } }
  @media (max-width: 991.98px) {
    #contactToPurchaseModal .modal-dialog-centered {
      max-width: 90%; } }
  #contactToPurchaseModal .modal-dialog-centered form input {
    width: 100%;
    padding: 10px;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 1px solid #373f49;
    margin-bottom: 20px; }
  #contactToPurchaseModal .modal-dialog-centered form .wpcf7-dynamictext {
    background: #f1f1f1; }
  #contactToPurchaseModal .modal-dialog-centered form .wpcf7-submit {
    width: auto;
    border: 2px solid #276069;
    color: #276069;
    text-transform: uppercase;
    padding: 10px 30px;
    background: transparent;
    font-weight: 700;
    float: right;
    -webkit-transition: 0.3s;
    transition: 0.3s; }
    #contactToPurchaseModal .modal-dialog-centered form .wpcf7-submit:hover {
      background: #276069;
      color: white;
      cursor: pointer; }
  #contactToPurchaseModal .modal-dialog-centered .address {
    padding-left: 0; }
    #contactToPurchaseModal .modal-dialog-centered .address li {
      list-style-type: none; }
  #contactToPurchaseModal .modal-dialog-centered .btn, #contactToPurchaseModal .modal-dialog-centered .btn-cta {
    background: #f1f1f1;
    color: #373f49;
    border: 1px solid #373f49; }
    #contactToPurchaseModal .modal-dialog-centered .btn:hover, #contactToPurchaseModal .modal-dialog-centered .btn-cta:hover {
      background: white;
      color: #276069; }

.product-dealer-block {
  margin-bottom: 2em; }
  .product-dealer-block p {
    margin-bottom: 0; }

.product-buy-now {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #6d88a3;
  z-index: 999;
  padding: 20px; }
  @media only screen and (min-width: 769px) {
    .product-buy-now {
      display: none !important; } }
  .product-buy-now__image {
    float: left;
    width: 20%; }
  .product-buy-now__cta {
    float: right;
    width: 75%; }
    .product-buy-now__cta h3 {
      color: #fff;
      font-size: 19px;
      margin-bottom: 50px; }
    .product-buy-now__cta a.buy-now {
      background-color: #000;
      color: #fff;
      border-radius: 0;
      font-weight: 300;
      text-transform: uppercase;
      font-size: 16px;
      padding: 10px 45px;
      float: none !important;
      display: block;
      text-align: center;
      position: absolute;
      bottom: 20px; }
      .product-buy-now__cta a.buy-now.disabled, .product-buy-now__cta a.buy-now.disabled:hover {
        background-color: black; }
      .product-buy-now__cta a.buy-now:hover, .product-buy-now__cta a.buy-now:active {
        background-color: #353535; }

#additionalProductModal .modal-title {
  font-weight: 400; }
  #additionalProductModal .modal-title span {
    font-weight: 500; }

#additionalProductModal .button {
  border: 2px solid #6d88a3;
  color: #6d88a3;
  width: auto;
  padding: 13px 30px;
  background: transparent;
  font-weight: 700;
  float: right;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 0; }
  #additionalProductModal .button:hover {
    background: #6d88a3;
    color: #fff; }

#additionalProductModal .btn, #additionalProductModal .btn-cta {
  padding: 10px 30px;
  border-radius: 0px;
  background-color: #6d88a3; }
  #additionalProductModal .btn:hover, #additionalProductModal .btn-cta:hover {
    background: #fff;
    color: #6d88a3; }

.related.products {
  display: block;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  clear: both; }
  .related.products .products {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media (min-width: 768px) {
      .related.products .products {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }
    @media (max-width: 991.98px) {
      .related.products .products {
        margin: 10px; } }
    .related.products .products li.product {
      border: 2px solid #276069;
      text-align: center; }
      .related.products .products li.product:last-child {
        margin-right: 0; }
      .related.products .products li.product a img {
        margin-bottom: 0; }
      @media (max-width: 767.98px) {
        .related.products .products li.product {
          width: 48.1%; }
          .related.products .products li.product:nth-child(2n) {
            margin-right: 0; } }
  .related.products h2 {
    text-align: center;
    padding: 5px 20px;
    margin-top: 5px;
    font-family: "Crimson Text", sans-serif;
    margin-bottom: 40px; }
